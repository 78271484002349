import React from 'react';
import ReactDOM from 'react-dom'
import {BrowserRouter, Navigate, Route, Routes, useLocation} from 'react-router-dom';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import "./custom.css"
import AppContextProvider from "./pages/AppContextProvider";
import Login from "./pages/Login";
import WindowInitial from "./generated/interfaces/windowInitial";
import Farms from "./pages/dashboard/Farms";
import AuthController from "./generated/controllers/authController";
import Labours from "./pages/dashboard/Labours";
import App from "./App";
import Bins from "./pages/dashboard/Bins";
import Stations from "./pages/dashboard/Stations";
import Clients from "./pages/Clients";
import Users from "./pages/Users";
import Roles from "./pages/Roles";
import Scanners from "./pages/Scanners";
import Handhelds from "./pages/Handhelds";
import Scissors from "./pages/Scissors";
import ManageStations from "./pages/ManageStations";
import ManageFarms from "./pages/ManageFarms";
import Entries from "./pages/Entries";
import MicroManagement from "./pages/dashboard/MicroManagement";
import DashboardScissors from "./pages/dashboard/DashboardScissors";
import MicroStations from "./pages/dashboard/MicroStations";

function isLocalhost() {
    return window.location.host.includes('localhost');
}

const ErrorPage: React.FC = () => {
    return (
        <div className="h-screen w-full flex">
            <div className="m-auto">
                <img className="w-40 md:w-full" src="logo.png" alt=""/>

                <h1 className="my-2 text-gray-800 font-bold text-2xl">
                    Oops, something went wrong.
                </h1>
                <div className="my-2 text-gray-800">The exact error has been logged and our developers have been
                    notified.
                </div>
                <a href="/"
                   className="cursor-pointer poin inline-block border rounded md py-4 px-8 text-center bg-primary-600 text-white hover:bg-primary-70">
                    Start over
                </a>
            </div>
        </div>
    )
}

AuthController.initial().then(resp => {
    if (window.localStorage.getItem('version') != resp.version) {
        window.localStorage.setItem('version', resp.version)
        window.location.reload()
    }

    ReactDOM.render(
        <React.StrictMode>
            <AppContextProvider initial={resp}>
                <BrowserRouter>
                    <Routes>
                        <Route path="/login" element={<Login/>}/>
                        <Route path="/" element={<RequireAuth appState={resp}><App/></RequireAuth>}>
                            <Route path="/dashboard-farms" element={<Farms/>}/>
                            <Route path="/dashboard-labours" element={<Labours/>}/>
                            <Route path="/dashboard-bins" element={<Bins/>}/>
                            <Route path="/dashboard-stations" element={<Stations/>}/>
                            <Route path="/dashboard-scissors" element={<DashboardScissors/>}/>
                            <Route path="/dashboard-microManagement" element={<MicroManagement/>}/>
                            <Route path="/clients" element={<Clients/>}/>
                            <Route path="/farms" element={<ManageFarms/>}/>
                            <Route path="/stations" element={<ManageStations/>}/>
                            <Route path="/users" element={<Users/>}/>
                            <Route path="/roles" element={<Roles/>}/>
                            <Route path="/scissors" element={<Scissors/>}/>
                            <Route path="/scanners" element={<Scanners/>}/>
                            <Route path="/handhelds" element={<Handhelds/>}/>
                            <Route path="/entries" element={<Entries/>}/>
                            <Route path="/microStations" element={<MicroStations/>}/>
                        </Route>
                    </Routes>
                </BrowserRouter>
            </AppContextProvider>
        </React.StrictMode>,

        document.getElementById("root")
    )
}).catch(err => {
    console.log(err)
    document.getElementById('loader')!.innerHTML = 'Error loading app'
})

const RequireAuth: React.FC<{ appState: WindowInitial }> = (props) => {
    const location = useLocation()

    if (!props.appState.authenticated) {
        // Redirect them to the /login page, but save the current location they were
        // trying to go to when they were redirected. This allows us to send them
        // along to that page after they login, which is a nicer user experience
        // than dropping them off on the home page.
        return <Navigate to="/login" state={{from: location}} replace/>
    }

    return <>{props.children}</>
}


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
