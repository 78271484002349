import React, {Fragment} from "react";
import {Tab} from "@headlessui/react";
import UsersCurrent from "./UsersCurrent";
import UsersArchived from "./UsersArchived";

const Users: React.FC = () => {
    function classes(selected: boolean) {
        let d = 'w-full rounded-lg text-sm font-medium leading-5 text-primary-700 ring-white ring-opacity-60 ring-offset-2 ring-offset-primary-400 focus:outline-none focus:ring-2'
        return d + (selected ? ' bg-white shadow' : ' text-white hover:bg-white/[0.12] hover:text-white');
    }


    return (
        <div className="bg-white p-2 shadow">
            <Tab.Group defaultIndex={-1}>
                <Tab.List className="flex space-x-1 rounded-xl bg-primary-500 p-1">
                    <Tab as={Fragment}>
                        {({selected}) => (
                            <button className={classes(selected)}>
                                Current
                            </button>
                        )}
                    </Tab>
                    <Tab as={Fragment}>
                        {({selected}) => (
                            <button className={classes(selected)}>
                                Archived
                            </button>
                        )}
                    </Tab>
                </Tab.List>
                <Tab.Panels>
                    <Tab.Panel>
                        <UsersCurrent/>
                    </Tab.Panel>
                    <Tab.Panel>
                        <UsersArchived/>
                    </Tab.Panel>
                </Tab.Panels>
            </Tab.Group>
        </div>
    )
}


export default Users;

