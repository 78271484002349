import UserController from "../generated/controllers/userController";
import CheckBox from "../components/CheckBox";
import React, { useContext, useRef, useState } from "react";
import PagedSearchTable, { PagedTableFunctions } from "../components/PagedSearchTable";
import {useStateAjax} from "../wrapper";
import RoleController from "../generated/controllers/roleController";
import ClientController from "../generated/controllers/clientController";
import UserResponse from "../generated/interfaces/userResponse";
import Success from "../components/Success";
import Dialog from "../components/Dialog";
import Input from "../components/Input";
import { EditRow, EditTable } from "../components/Fields";
import UserRequest from "../generated/interfaces/userRequest";
import {  AutoCompleteNullable } from "../components/AutoComplete";
import AppContext from "../pages/appContext";
import {useValidation} from "../validation";
import WarningPopup, {useWarningState} from "../components/WarningPopup";
import Failed from "../components/Failed";
import ClientList from "../generated/interfaces/clientList";
import Originate from "../generated/interfaces/originate";

const emptyUserRequest = ():  UserRequest => {
    return {
        active: false,
        clientId: null,
        email: "",
        id: 0,
        myFarmWebUsername: "",
        name: "",
        phoneNumber: "",
        qualification: "",
        roleId: null,
        username: ""
    }

}

const UsersCurrent: React.FC = () => {
    const pagedTableRef = useRef<PagedTableFunctions<UserResponse>>()
    const context = useContext(AppContext)
    const [roles,] = useStateAjax(RoleController.index)
    const [clients,] = useStateAjax(ClientController.all)
    const [userModal, setUserModal] = useState(false)
    const [selected, setSelected]= useState<UserRequest>(emptyUserRequest)

    const warningArchive = useWarningState<number>(-1)

    const validation = useValidation({
        name: () => selected.name !== null && selected.name.length > 0,
        username: () => selected.username !== null && selected.username.length > 0,
        email: () => selected.email !== null && selected.email.length > 0,
        myFarmWebUsername: () => selected.myFarmWebUsername !== null && selected.myFarmWebUsername.length > 0,
        phoneNumber: () => selected.phoneNumber !== null && selected.phoneNumber.length > 0,
    })

    function updateUserProps(e: Partial<UserResponse> ,row: UserResponse) {
        const editedRow: UserResponse = {...row, ...e}

        // Update Active, isAdmin and Locked
        UserController.updateProps({
            id: editedRow.id,
            active: editedRow.active,
            locked: editedRow.locked,
            isSuper: editedRow.isSuper,
            receiveReports: editedRow.receiveReports
        }).then(r => {
            context.showSnack(<Success title='Success' text='success'/>)
            pagedTableRef.current?.refresh()
        })
    }

    function upsertUser() {
        if (!validation.validate()) return

        const hideLoader = context.showLoader()
        UserController.upsert(selected).then(r => {
            setUserModal (false);
            setSelected(emptyUserRequest())
            validation.clear()
            context.showSnack(<Success title='Success' text='success'/>)
            pagedTableRef.current?.refresh();}).finally(() => hideLoader())
    }


    function archive(id: number) {
        const hideLoader = context.showLoader()
        UserController.archive({id: id}).then(r => {
            pagedTableRef.current?.refresh();
            context.showSnack(<Success title='Success' text='success'/>)
        }).catch(() => context.showSnack(<Failed title='Failed' text='failed'/>)).finally(() => hideLoader())
    }
    function sendPassword(id: number) {
        UserController.sendPassword({id: id}).then(r => {
            pagedTableRef.current?.refresh();
            context.showSnack(<Success title='Success' text='success'/>)
        }).catch(() => context.showSnack(<Failed title='Failed' text='failed'/>))
    }

    function newUser() {
        setUserModal(true)
    }
    
    function userAddOriginate(client: ClientList) {
        return `${client.name} (${client.originate})`
    }

    return (

        <div>
            <button className="btn btn-primary my-2" onClick={() => newUser()} >Add User</button>
            <PagedSearchTable call={UserController.
                pagedCurrent} componentRef={pagedTableRef} columns={[{
                header: "Actions",
                row: (row) => {
                    return <div>
                        <button className="btn-sm btn-primary mr-1" onClick={() => {
                            setSelected(row)
                            setUserModal(true)
                        }}>edit
                        </button>
                        <button className="btn-sm btn-error mr-1" onClick={() => {
                            warningArchive.show(`Are you sure you want to archive ${row.name}`, row.id)
                        }}>archive
                        </button>
                        <button className="btn-sm btn-error mr-1" onClick={() => {
                            sendPassword(row.id)
                        }}>password
                        </button>
                    </div>
                }
            }, {
                header: "Names",
                key: 'names',
                row: (row) => row.name
            }, {
                header: "Username",
                key: 'username',
                row: (row) => row.username
            }, {
                header: "Active",
                key: 'active',
                row: (row) =>
                    <CheckBox checked={row.active} onChange={(e) => {
                        updateUserProps({active: e}, row)
                    }}/>
            }, {
                header: "Admin",
                key: 'admin',
                row: (row) => <CheckBox checked={row.isSuper} onChange={(e) => {
                    updateUserProps({isSuper: e},row)
                }}/>
            }, {
                header: "Email",
                key: 'email',
                row: (row) => row.email
            }, {
                header: "Role",
                key: 'role',
                row: (row) => row.role
            }, {
                header: "Locked",
                key: 'locked',
                row: (row) => <CheckBox checked={row.locked} onChange={(e) => {
                    updateUserProps({locked: e }, row)
                }}/>
            }, {
                header: "Receive Reports",
                key: 'receiveReports',
                row: (row) => <CheckBox checked={row.receiveReports} onChange={(e) => {
                    updateUserProps({receiveReports: e}, row)
                }}/>
            }]}/>

            <Dialog title="User" setShow={setUserModal} show={userModal} body={
                <EditTable discard={() => {
                    setUserModal(false)
                    setSelected(emptyUserRequest)}
                }
                           save={() => upsertUser()}
                           saveWord= {selected.id? 'update': 'add'}>

                    {EditRow('Name:', <Input value={selected.name ?? ''} change={v => setSelected({...selected, name: v})}/>, validation.rules.name, 'Name required')}
                    {EditRow('UserName:', <Input value={selected.username ?? ''} change={v => setSelected({...selected, username: v})}/>,validation.rules.username,"Username required")}
                    {EditRow('Active:', <CheckBox checked={selected.active} onChange={v => setSelected({...selected, active: v})}/>)}
                    {EditRow('Qualification:', <Input value={selected.qualification ?? ''} change={v => setSelected({...selected, qualification: v})}/>)}
                    {EditRow('Email:', <Input value={selected.email ?? ''} change={v => setSelected({...selected, email: v})}/>, validation.rules.email, 'Email required')}
                    {EditRow('Myfarmweb Username:', <Input value={selected.myFarmWebUsername ?? ''} change={v => setSelected({...selected, myFarmWebUsername: v})}/>, validation.rules.myFarmWebUsername, 'Myfarmweb Username required')}
                    {EditRow('PhoneNumber:', <Input value={selected.phoneNumber ?? ''} change={v => setSelected({...selected, phoneNumber: v})}/>, validation.rules.phoneNumber, 'PhoneNumber required')}
                    {EditRow('Role:', <AutoCompleteNullable options={roles} value={selected.roleId} valueFunc={c => c.id} textFunc={c => c.name ?? ""} onChange={v => setSelected({...selected, roleId: v})} ></AutoCompleteNullable>)}
                    {EditRow('Client:', <AutoCompleteNullable options={clients} value={selected.clientId} valueFunc={c => c.id} textFunc={c => userAddOriginate(c)} onChange={v => setSelected({...selected, clientId: v})} ></AutoCompleteNullable>)}

                </EditTable>
            }/>
            <WarningPopup state={warningArchive} onYes={() => archive(warningArchive.state.data)}/>
        </div>
    )
}

export default UsersCurrent;
