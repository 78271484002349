import {FeatureLike} from "ol/Feature";
import {Fill, Stroke, Style, Text} from "ol/style";
import {Feature} from "ol";
import GeoJSON from "ol/format/GeoJSON";
import {Geometry} from "ol/geom";
import {Vector as VectorLayer} from "ol/layer";
import {Vector as VectorSource} from "ol/source";

export const farmBoundaryStyle = (feature: FeatureLike): Style => {
    return new Style({
        text: new Text({
            text: feature.get("id_"),
            font: 'bold 14px sans-serif',
            fill: new Fill({
                color: 'white',
            }),
            stroke: new Stroke({
                color: 'black',
                width: 3,
            }),
            offsetY: -15,
        }),
        stroke: new Stroke({
            color: 'red',
            width: 4,
            lineDash: [1, 2, 3, 4, 5, 6],
        })
    });
};

export const landBoundaryStyle = (feature: FeatureLike): Style => {
    return new Style({
        text: new Text({
            text: feature.get("name"),
            font: 'bold 14px sans-serif',
            fill: new Fill({
                color: 'white',
            }),
            stroke: new Stroke({
                color: 'black',
                width: 3,
            }),
            offsetY: -15,
        }),
        stroke: new Stroke({
            color: 'blue',
            width: 4,
            lineDash: [1, 2, 3, 4, 5, 6],
        })
    });
};

export function readGeoJsonFeatureCollection(input: string): Feature[] {
    return new GeoJSON().readFeatures(input, {
        featureProjection: 'EPSG:3857',
        dataProjection: 'EPSG:4326',
    })
}

export function readGeoJsonFeature(input: string): Feature {
    return new GeoJSON().readFeature(input, {
        featureProjection: 'EPSG:3857',
        dataProjection: 'EPSG:4326',
    })
}

export function makeVectorLayer(features: Feature<Geometry>[], style: (feature: FeatureLike) => Style): VectorLayer<VectorSource<Geometry>> {
    const vectorSource = new VectorSource({
        features: features,
    });
    return new VectorLayer({
        source: vectorSource,
        style
    });
}
