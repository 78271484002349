/* eslint-disable */
/**
 * This file was generated by Itecho.TSGen tool
 * Version and info generated in .version
 * Any changes to this file will be lost if regenerated 
 */

import { webRequest, fileRequest } from "../request";
import type IdRequest from "../interfaces/idRequest";
import type PaginationRequestSearch from "../interfaces/paginationRequestSearch";
import type PaginationResponse from "../interfaces/paginationResponse";
import type UpdateTimeOffsetRequest from "../interfaces/updateTimeOffsetRequest";
import type UpdateuserPropRequest from "../interfaces/updateuserPropRequest";
import type UserRequest from "../interfaces/userRequest";
import type UserResponse from "../interfaces/userResponse";

export default {
    updateProps: (request: UpdateuserPropRequest) => webRequest<unknown>('/user/updateprops', {
        method: 'post',
        body: request
    }),
    updateTime: (request: UpdateTimeOffsetRequest) => webRequest<unknown>('/user/updatetime', {
        method: 'post',
        body: request
    }),
    archive: (request: IdRequest) => webRequest<unknown>('/user/archive', {
        method: 'post',
        body: request
    }),
    restore: (request: IdRequest) => webRequest<unknown>('/user/restore', {
        method: 'post',
        body: request
    }),
    sendPassword: (request: IdRequest) => webRequest<unknown>('/user/sendpassword', {
        method: 'post',
        body: request
    }),
    upsert: (request: UserRequest) => webRequest<unknown>('/user/upsert', {
        method: 'post',
        body: request
    }),
    pagedCurrent: (request: PaginationRequestSearch) => webRequest<PaginationResponse<UserResponse>>('/user/pagedcurrent', {
        method: 'post',
        body: request
    }),
    pagedArchived: (request: PaginationRequestSearch) => webRequest<PaginationResponse<UserResponse>>('/user/pagedarchived', {
        method: 'post',
        body: request
    })
}