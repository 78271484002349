import React, { useState } from 'react';

const Slider: React.FC<{ value: number, setValue: (value: number) => void }> = (props) => {
  
    const handleSliderChange = (event: string) => {
        props.setValue(parseFloat(event));
    };

    return (
        <div className="pt-1">
            <input
                type="range"
                min={1}
                step={0.5}
                max={10}
                value={props.value}
                onChange={e => handleSliderChange(e.target.value)}
            />
        </div>
    );
};

export default Slider;