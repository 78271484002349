/* eslint-disable */
/**
 * This file was generated by Itecho.TSGen tool
 * Version and info generated in .version
 * Any changes to this file will be lost if regenerated 
 */

import { webRequest, fileRequest } from "../request";
import type IdRequest from "../interfaces/idRequest";
import type MobileSubmitEntries from "../interfaces/mobileSubmitEntries";
import type PaginationRequestSearch from "../interfaces/paginationRequestSearch";
import type PaginationResponse from "../interfaces/paginationResponse";
import type ScannerScanRequest from "../interfaces/scannerScanRequest";
import type StationDisplay from "../interfaces/stationDisplay";
import type StationStatusRequest from "../interfaces/stationStatusRequest";
import type StationStatusResult from "../interfaces/stationStatusResult";
import type StationSubmitEntries from "../interfaces/stationSubmitEntries";
import type StationUpdateModel from "../interfaces/stationUpdateModel";

export default {
    paged: (request: PaginationRequestSearch) => webRequest<PaginationResponse<StationDisplay>>('/station/paged', {
        method: 'post',
        body: request
    }),
    update: (request: StationUpdateModel) => webRequest<unknown>('/station/update', {
        method: 'post',
        body: request
    }),
    statuses: () => webRequest<StationStatusResult[]>('/station/statuses'),
    rawEntries: (id: number) => fileRequest('/station/rawentries', {
        queryParams: {
            id
        }
    }),
    scanners: (request: ScannerScanRequest) => webRequest<unknown>('/station/scanners', {
        method: 'post',
        body: request
    }),
    status: (request: StationStatusRequest) => webRequest<unknown>('/station/status', {
        method: 'post',
        body: request
    }),
    statusData: (request: IdRequest) => webRequest<StationStatusResult>('/station/statusdata', {
        method: 'post',
        body: request
    }),
    mobile: (request: MobileSubmitEntries) => webRequest<unknown>('/station/mobile', {
        method: 'post',
        body: request
    }),
    entries: (request: StationSubmitEntries) => webRequest<unknown>('/station/entries', {
        method: 'post',
        body: request
    })
}