import React, {useContext, useEffect, useRef, useState} from "react";
import DashboardFarmDisplay from "../../generated/interfaces/dashboardFarmDisplay";
import PagedSearchTable, {PagedTableFunctions} from "../../components/PagedSearchTable";
import FarmController from "../../generated/controllers/farmController";
import Dialog from "../../components/Dialog";
import LocationDataGrouped from "../../generated/interfaces/locationDataGrouped";
import SeasonController from "../../generated/controllers/seasonController";
import SeasonDisplay from "../../generated/interfaces/seasonDisplay";
import DashboardLandDisplay from "../../generated/interfaces/dashboardLandDisplay";
import {dateFormat} from "../../date";
import LandController from "../../generated/controllers/landController";
import AppContext from "../appContext";
import Success from "../../components/Success";
import {wrapLoader} from "../../wrapper";
import DeckGLMap from "./DeckGLMap";
import FarmWeightsDialogs from "./FarmWeightsDialogs";
import PaginationRequestSearch from "../../generated/interfaces/paginationRequestSearch";
import Failed from "../../components/Failed";
import VectorLayer from "ol/layer/Vector";
import VectorSource from "ol/source/Vector";
import {Geometry} from "ol/geom";
import {
    farmBoundaryStyle,
    landBoundaryStyle,
    makeVectorLayer,
    readGeoJsonFeature,
    readGeoJsonFeatureCollection
} from "../../helpers/map";
import DialogOpenLayerMap from "../DialogOpenLayerMap";
import {PaginationRequestSearchEx} from "../../generated/request";

const emptyDashboardFarmDisplay: DashboardFarmDisplay = {
    id: 0,
    mfwId: 0,
    client: '',
    name: '',
    boundaryUID: '',
    pickCounts: 0,
    lastUpdated: null,
    start: new Date(),
    end: new Date(),
    shapeFileGenerated: null
}

const Farms: React.FC = () => {
    const pagedTableRef = useRef<PagedTableFunctions<DashboardFarmDisplay>>()
    const landsPagedRef = useRef<PagedTableFunctions<DashboardLandDisplay>>()

    const context = useContext(AppContext)

    const [landsArgs, setLandsArgs] = useState<{id: number, seasonId: number | null}>({
        id: 0,
        seasonId: null
    })
    const [weightsPaged, setWeightsPaged] = useState<{id: number | null, ha: number}>({
        id: null,
        ha: 0
    })
    const [landsDialog, setLandsDialog] = useState(false)
    const [selectedFarm, setSelectedFarm] = useState<DashboardFarmDisplay>(emptyDashboardFarmDisplay)
    const [mapDialog, setMapDialog] = useState(false)
    const [landWeightsDialog, setLandWeightsDialog] = useState(false)
    const [showFarmSeasons, setShowFarmSeasons] = useState(false)
    const [availableSeasons, setAvailableSeasons] = useState<SeasonDisplay[]>([])
    const [points, setPoints] = useState<LocationDataGrouped[]>([])
    const [displayData, setDisplayData] = useState<any>({})
    const [farmMapDialog, setFarmMapDialog] = useState(false)
    const [mapLayers, setMapLayers] = useState<VectorLayer<VectorSource<Geometry>>[]>([])

    function select(row: DashboardFarmDisplay) {
        setLandsDialog(true)
        setSelectedFarm(row)
        setLandsArgs({id: row.id, seasonId: null})
    }

    function viewMap(row: DashboardFarmDisplay) {
        setSelectedFarm(row)
        const hideLoader = context.showLoader()

        FarmController.geoJson(row.id).then((response) => {
            if (response.farm == "" && response.lands.length == 0) {
                context.showSnack(<Failed title='Failed' text='No farm boundary data exists. Please refresh from MFW.'/>)
                hideLoader()
                return
            }
            let farmBoundary = makeVectorLayer(readGeoJsonFeatureCollection(response.farm), farmBoundaryStyle)
            let landBoundaries = makeVectorLayer(response.lands.filter(l => l.length > 0).map(readGeoJsonFeature), landBoundaryStyle)

            setMapLayers([farmBoundary, landBoundaries])
            setFarmMapDialog(true)
            hideLoader()
        }).catch(() => hideLoader())
    }

    function getSeasons(farmId: number) {
        wrapLoader(context, SeasonController.farm({id: farmId}), resp => {
            setAvailableSeasons(resp)
            setShowFarmSeasons(true)
        })
    }

    function selectSeason(seasonIdVal: number) {
        setShowFarmSeasons(false)
        setLandsArgs({...landsArgs, seasonId: seasonIdVal})
    }

    function getPoints(row: DashboardLandDisplay) {
        const hideLoader = context.showLoader()
        FarmController.locationData({
            landId: row.landId,
            seasonId: landsArgs.seasonId
        }).then(resp => {
            setPoints(resp)
            setMapDialog(true)
            setDisplayData({
                farmName: selectedFarm.name,
                blockName: row.name
            })
        }).finally(() => hideLoader())
    }

    function showMap(row: DashboardLandDisplay) {
        getPoints(row)
    }

    function showWeightsPaged(row: DashboardLandDisplay) {
        const hideLoader = context.showLoader()
        LandController.boundary(row.landId).then(resp => {
            const coordinates = resp.coordinates?.map(e => [e.x, e.y])
            setWeightsPaged({ha: 10, id: row.landId})
            setLandWeightsDialog(true)
            // setWeightsPaged({...weightsPaged, ha: calcArea(coordinates)})
        }).finally(() => hideLoader())
    }

    function downloadLocationData(row: DashboardLandDisplay) {
        FarmController.downloadLocationData({
            landId: row.landId,
            seasonId: landsArgs.seasonId
        })
        context.showSnack(<Success title={"Starting download"}/>)
    }

    function downloadLocationDataDay(row: DashboardLandDisplay) {
        FarmController.downloadLocationDataGroupedDay({
            landId: row.landId,
            seasonId: landsArgs.seasonId
        })
        context.showSnack(<Success title={"Starting download"}/>)
    }

    function searchPagedEntries(request: PaginationRequestSearchEx<DashboardLandDisplay>) {
        return FarmController.landsPaged({...request, ...landsArgs})
    }

    useEffect(() => {
        landsPagedRef.current?.refresh()
    }, [landsArgs.seasonId])

    function renderActions(item: DashboardFarmDisplay) {
        return <>
            <div className="btn-sm btn-primary" onClick={() => select(item)}>view</div>
        </>
    }

    return (
        <div>
            <PagedSearchTable
                componentRef={pagedTableRef}
                call={FarmController.available}
                columns={[{
                header: 'Client',
                key: 'clientName',
                row: (item) => item.client
            }, {
                header: 'Name',
                key: 'name',
                row: (item) => item.name
            }, {
                header: 'Season',
                key: 'season',
                row: (item) => `${dateFormat(item.start)} to ${dateFormat(item.end)}`
            }, {
                header: 'Total Picks',
                key: 'totalPicks',
                row: (item) => item.pickCounts
            }, {
                header: 'Last Updated',
                key: 'lastUpdated',
                row: (item) => dateFormat(item.lastUpdated, '%d %M %Y %H:%i:%s')
            }, {
                header: 'Actions',
                key: 'actions',
                row: renderActions
            }]}/>

            {
                farmMapDialog
                    ? <>
                    <DialogOpenLayerMap layers={mapLayers} tiffUrl={`/api/protected_tiffs/new${selectedFarm.id}.1E-05.cog.tiff`} setShow={setFarmMapDialog} />
                    </>
                    : null
            }

            <Dialog title={'View'}
                    show={landsDialog}
                    setShow={setLandsDialog}
                    body={
                        <div>
                            <button className="btn m-2 btn-primary" onClick={() => getSeasons(selectedFarm.id)}>Change season</button>
                            {/* <button className="btn m-2 btn-primary" onClick={() => viewMap(selectedFarm)}>View whole farm</button> */}

                            <PagedSearchTable call={w => {
                                return searchPagedEntries({
                                    ...w, 
                                    sortBy: 'lastUpdated', 
                                    ascending: false
                                })
                            }} 
                                              componentRef={landsPagedRef}
                                              columns={[
                                                  {
                                                      header: 'Name',
                                                      key: 'name',
                                                      row: item => item.name
                                                  },
                                                  {
                                                      header: 'Total Picks',
                                                      key: 'totalPicks',
                                                      row: item => item.pickCounts
                                                  },
                                                  {
                                                      header: 'Last Updated',
                                                      key: 'lastUpdated',
                                                      row: item => dateFormat(item.lastUpdated, '%d %M %Y')
                                                  },
                                                  {
                                                      header: <div className="text-right">Actions</div>,
                                                      key: 'actions',
                                                      row: item => <div className='flex items-center justify-end'>
                                                          <div className="btn-sm mr-1 btn-primary" onClick={() => showWeightsPaged(item)}>Weights</div>
                                                          <div className="btn-sm mr-1 btn-primary" onClick = {() => showMap(item)}>View</div>
                                                          <div className="inline-block mr-1 cursor-pointer" onClick = {() => downloadLocationData(item)}><img className='w-[25px] h-[25px]' src='/images/csv.png' alt=""/></div>
                                                          <div className="inline-block cursor-pointer" onClick = {() => downloadLocationDataDay(item)}><img className='w-[25px] h-[25px]' src='/images/csv.png' alt=""/></div>
                                                  </div>
                                                  }

                                              ]}
                            />
                        </div>
                    }
            />

            <Dialog title={'Seasons'}
                    show={showFarmSeasons}
                    key="seasons"
                    setShow={setShowFarmSeasons}
                    body={
                        <div>
                            {
                                availableSeasons.map(season => <div key={season.id} className="p-2">
                                        <button onClick={() => selectSeason(season.id)} className="btn btn-primary">
                                            { dateFormat(season.start, '%d %M %Y') } - { dateFormat(season.end, '%d %M %Y') }
                                        </button>
                                </div>)
                            }
                        </div>
                    }/>


            {
                mapDialog && points.length > 0
                    ? <DeckGLMap setShow={setMapDialog} points={points} displayData={displayData} selectedFarm={selectedFarm} landsArgsSeasonId={landsArgs.seasonId} />
                    : null
            }

            <FarmWeightsDialogs landWeightsDialog={landWeightsDialog} setLandWeightsDialog={setLandWeightsDialog} weightsPaged={weightsPaged} setWeightsPaged={setWeightsPaged}/>
        </div>
    )
}

export default Farms;
