import {HexagonLayer} from "@deck.gl/aggregation-layers/typed";
import LocationDataGrouped from "../generated/interfaces/locationDataGrouped";
import { Color } from "@deck.gl/core/typed";

export const colorRange: Color[] = [
    [160, 0, 37],
    [216, 41, 42],
    [216, 41, 42],
    [249, 98, 66],
    [255, 165, 94],
    [255, 220, 135],
    [255, 220, 135],
    [210, 237, 135],
    [152, 213, 103],
    [82, 182, 93],
    [0, 142, 74],
    [0, 142, 74],
    [0, 93, 50],
];

function multiple(p: any): any {
    return Array(p.count).fill([Number(p.lon), Number(p.lat)])
}

export function renderLayers(data: any, {
    radius = 1,
    upperPercentile = 100,
    coverage = 1
}) {

    let flatData = data
        .map(multiple)
        .flatMap((f: any) => f)

    let result = new HexagonLayer({
        colorRange,
        coverage,
        data: flatData,
        colorScaleType: 'quantile',
        elevationRange: [0, 1000],
        extruded: true,
        getPosition: d => d,
        pickable: true,
        radius,
        upperPercentile,
        material: {
            ambient: 0.64,
            diffuse: 0.6,
            shininess: 32,
            specularColor: [51, 51, 51]
        },
        transitions: {
            elevationScale: 1000
        }
    })

    return [result]
}

export function getPosition(points: LocationDataGrouped[]) {
    return {
        lat: points.length > 0 ? points[0]!.lat : 0,
        lng: points.length > 0 ? points[0]!.lon : 0
    }
}
