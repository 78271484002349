import React, {useContext} from "react";
import {useStateLoadAjax} from "../../wrapper";
import StationController from "../../generated/controllers/stationController";
import StationStatus from "./StationStatus";
import AppContext from "../appContext";

const Stations: React.FC = () => {
    const context = useContext(AppContext)
    const [stations, ] = useStateLoadAjax(context, StationController.statuses);

    return (
        <div className="flex flex-row flex-wrap">
            {stations.map(station => (
                <StationStatus station={station} key={station.id}/>
            ))}
        </div>
    )
}

export default Stations;

//         <station-status class="m-1 bg-gray-200" v-for="station in stations" :station="station" :key="station.id"></station-status>
