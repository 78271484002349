export function mapMany<T, Q> (arr: T[], map: (data: T) => Q[]): Q[] {
    return arr.reduce<Q[]>((prev, curr) => [...prev, ...map(curr)], [])
}

export function distinct<T> (arr: T[]): T[] {
    return arr.filter((v, i) => !arr.includes(v, i + 1))
}

export function split<T>(arr: T[], predicate: (item: T) => boolean): {one: T[], two: T[]} {
    return {
        one: arr.filter(predicate),
        two: arr.filter(item => !predicate(item))
    };
}

export function insertAt<T>(arr: T[], index: number, ...items: T[] ): void {
    arr.splice( index, 0, ...items );
}

export function groupBy<T, TKey extends string | number | symbol>(arr: T[], keySelector: (item: T) => TKey): Record<TKey, T[]> {
    return arr.reduce((group, item) => {
        const key = keySelector(item);
        if (!group[key])
            group[key] = [item];
        else
            group[key].push(item);

        return group;
    },  {} as Record<TKey, T[]>)
}

export function groupBySingle<T, TKey extends string | number | symbol, TValue>(arr: T[], keySelector: (item: T) => TKey, valueSelector: (item: T) => TValue): Record<TKey, TValue> {
    return arr.reduce((group, item) => {
        const key = keySelector(item);
        group[key] = valueSelector(item);
        return group;
    },  {} as Record<TKey, TValue>)
}


export function recordToArray<TKey extends string | number | symbol, TValue, TRet>(rec: Record<TKey, TValue>, convert: (key: TKey, item: TValue) => TRet): TRet[] {
    const ret: TRet[] = [];
    for (const k in rec) {
        ret.push(convert(k, rec[k]));
    }
    return ret;
}

export function createArray<T> (size: number, factory: (i: number) => T): T[] {
    // first have to fill with null
    // otherwise it will contain the same references.
    return new Array(size)
        .fill(null)
        .map((_, i) => factory(i))
}

export function chunk<T>(arr: T[], size: number): T[][] {
    return createArray(Math.ceil(arr.length / size), i => arr.slice(i * size, (i * size) + size))
}

export function arrayMerge<T>(arrays: T[][]): T[] {
    return arrays.flat();
}

export function mapPrev<TRet, T> (arr: T[], callback: (current: T, prev: T | null) => TRet): TRet[] {
    return arr.map((current, index) => callback(current, index > 0 ? arr[index - 1]! : null));
}
    