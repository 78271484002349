import React, {useContext, useRef} from "react";
import UserController from "../generated/controllers/userController";
import PagedSearchTable, {PagedTableFunctions} from "../components/PagedSearchTable";
import AppContext from "./appContext";
import WarningPopup, {useWarningState} from "../components/WarningPopup";
import UserResponse from "../generated/interfaces/userResponse";
import Success from "../components/Success";
import Failed from "../components/Failed";

const UsersArchived: React.FC = () => {
    const context = useContext(AppContext)
    const pagedTableRef = useRef<PagedTableFunctions<UserResponse>>()
    const warningRestore = useWarningState<number>(-1)

    function restore() {
        const hideLoader = context.showLoader()
        UserController.restore({id: warningRestore.state.data}).then(r => {
            pagedTableRef.current?.refresh()
            context.showSnack(<Success title='Success' text='success'/>)
        }).catch(() => <Failed title='Failed' text='failed'/>).finally(() => hideLoader())
    }

    return (
        <div>
            <PagedSearchTable call={UserController.pagedArchived} componentRef={pagedTableRef} columns={[{
                header: "Actions",
                row: (row) => {
                    return <div>
                        <button className="btn-sm btn-primary mr-1" onClick={() => {
                            warningRestore.show(`Are you sure you want to restore ${row.name}`, row.id)
                        }}>restore
                        </button>
                    </div>
                }
            }, {
                header: "Names",
                key: 'names',
                row: (row) => row.name
            }, {
                header: "Username",
                key: 'username',
                row: (row) => row.username
            }, {
                header: "Active",
                key: 'active',
                row: (row) => row.active ? 'true' : 'false'
            }, {
                header: "Admin",
                key: 'admin',
                row: (row) => row.isSuper ? 'true' : 'false'
            }, {
                header: "Email",
                key: 'email',
                row: (row) => row.email
            }, {
                header: "Role",
                key: 'role',
                row: (row) => row.role
            }, {
                header: "Locked",
                key: 'locked',
                row: (row) => row.locked ? 'true' : 'false'
            }, {
                header: "Receive Reports",
                key: 'receiveReports',
                row: (row) => row.receiveReports ? 'true' : 'false'
            }]}/>

            <WarningPopup state={warningRestore} onYes={() => restore()}/>
        </div>
    )
}

export default UsersArchived
