/* eslint-disable */
/**
 * This file was generated by Itecho.TSGen tool
 * Version and info generated in .version
 * Any changes to this file will be lost if regenerated 
 */

import { webRequest, fileRequest } from "../request";
import type ChangePasswordRequest from "../interfaces/changePasswordRequest";
import type Login from "../interfaces/login";
import type LoginResponse from "../interfaces/loginResponse";
import type ResetPasswordRequest from "../interfaces/resetPasswordRequest";
import type WindowInitial from "../interfaces/windowInitial";

export default {
    login: (post: Login) => webRequest<LoginResponse>('/auth/login', {
        method: 'post',
        body: post
    }),
    logout: () => webRequest<unknown>('/auth/logout'),
    resetPassword: (request: ResetPasswordRequest) => webRequest<unknown>('/auth/resetpassword', {
        method: 'post',
        body: request
    }),
    initial: () => webRequest<WindowInitial>('/auth/initial', {
        method: 'post'
    }),
    changePassword: (request: ChangePasswordRequest) => webRequest<boolean>('/auth/changepassword', {
        method: 'post',
        body: request
    }),
    initializeUser: (password: string) => webRequest<string>('/auth/initializeuser', {
        queryParams: {
            password
        }
    })
}