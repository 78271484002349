/* eslint-disable */
/**
 * This file was generated by Itecho.TSGen tool
 * Version and info generated in .version
 * Any changes to this file will be lost if regenerated 
 */

import { webRequest, fileRequest } from "../request";
import type LandBoundaryCoordinates from "../interfaces/landBoundaryCoordinates";

export default {
    boundary: (id: number) => webRequest<LandBoundaryCoordinates>('/land/boundary', {
        queryParams: {
            id
        }
    })
}