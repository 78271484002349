import React, {useContext, useEffect, useState} from "react";
import StationStatusResult from "../../generated/interfaces/stationStatusResult";
import StationStatusPortResult from "../../generated/interfaces/stationStatusPortResult";
import {dateTimeOffset} from "../../date";
import Dialog from "../../components/Dialog";
import PortStatus from "../../generated/interfaces/portStatus";
import BatteryColor from "../../generated/interfaces/batteryColor";
import useMessageHub from "../../messageHub";
import {last} from "../../helper";
import {signal} from "../../App";
import AppContext from "../appContext";

const emptyStationStatusPortResult: StationStatusPortResult = {
    loaded: false,
    top: PortStatus.Busy,
    bottom: BatteryColor.Red,
    uid: '',
    name: '',
    fwVersion: '',
    dataSize: 0,
    battery: 0,
    progress: 0
}

const StationStatus: React.FC<{ station: StationStatusResult }> = (props) => {
    const station = props.station;
    const context = useContext(AppContext);
    const [portShow, setPortShow] = useState<boolean>(false)
    const [port, setPort] = useState<StationStatusPortResult>(emptyStationStatusPortResult)
    const [localStation, setLocalStation] = useState<StationStatusResult>(station)

    useMessageHub({
        StationStatus(status: StationStatusResult) {
            if (status.id == station.id) {
                setLocalStation(status)
            }
        }
    })

    useEffect(() => {
        signal.on('StationStatus', (data: StationStatusResult) => {
            if (data.received) data.received = new Date(data.received as unknown as string)
            if (data.id == station.id) {
                setLocalStation(data)
            }
        })
        listenStation()

        return () => {
            signal.off('StationStatus')
            signal.send('StopListenStation', station.id)
        }
    }, [])

    function listenStation() {
        try {
            signal.send('ListenStation', station.id)
        } catch (error) {
            setTimeout(listenStation, 2000)
        }
    }

    function getProgress(port: StationStatusPortResult) {
        return (port.progress / port.dataSize) * 100;
    }

    function showPort(port: StationStatusPortResult) {
        setPortShow(true)
        setPort(port)
    }


    return (
        <div className='p-1'>
            <div className="flex flex-col items-center w-40 bg-gray-200">
                <div className="bg-primary-200 text-primary-800 text-center p-1 w-full">
                    <p className="underline text-xl">
                        {localStation.ports.filter((s) => s.loaded).length} /
                        {localStation.ports.length}
                    </p>
                    <p className="text-sm truncate">{localStation.name}</p>
                    <p className="text-sm">({last(localStation.uid, 6)})</p>
                    <p className="text-sm">{dateTimeOffset(localStation.received, context.initial.timeOffset)}</p>
                </div>
                <div className="p-1 w-full">
                    <img className="w-16" src="/images/top.png" alt=""/>
                    {localStation.ports.map((port, i) => {
                        return <div key={port.name + '-' + i} className="relative select-none cursor-pointer">
                            {
                                !port.loaded
                                    ? <img
                                        className="w-16"
                                        src="/images/empty.png"
                                        alt=""
                                    />
                                    : <div className="flex" onClick={() => showPort(port)}>
                                        <img className="w-16" src="/images/loaded.png" alt=""/>
                                        <div
                                            className={`absolute w-4 h-4 left-[10px] top-[10px] ${
                                                {
                                                    "Complete": "bg-cover bg-[url('/public/images/blue.png')]",
                                                    "Busy": "bg-cover bg-[url('/public/images/lblue.png')] animate-ping",
                                                    "Waiting": "bg-cover bg-[url('/public/images/yellow.png')]",
                                                }[port.top]
                                            }`}
                                        ></div>
                                        <div
                                            className={`absolute w-4 h-4 left-[10px] top-[18px] ${
                                                {
                                                    "Red": "bg-cover bg-[url('/public/images/red.png')]",
                                                    "Yellow": "bg-cover bg-[url('/public/images/yellow.png')]",
                                                    "Green": "bg-cover bg-[url('/public/images/green.png')]",
                                                }[port.bottom]
                                            }`}
                                        ></div>
                                        <div
                                            className="border-t-2 border-primary-300 px-1 w-full"
                                            style={{fontSize: '0.5rem'}}
                                        >
                                            <div>
                                                ({i + 1}) {port.name}
                                            </div>
                                            <div style={{fontSize: "0.3rem"}}>
                                                FW: { port.fwVersion }
                                            </div>
                                        </div>
                                    </div>
                            }
                        </div>
                    })}
                    <img className="w-16" src="/images/bottom.png" alt=""/>
                </div>

            </div>

            <Dialog title='Port Data' show={portShow} setShow={setPortShow}>
                <div className='p-2'>
                    <table>
                        <tbody>
                            <tr>
                                <td>Port:</td>
                                <td>{ localStation.ports.indexOf(port) + 1 }</td>
                            </tr>
                            <tr>
                                <td>Name:</td>
                                <td>{ port.name }</td>
                            </tr>
                            <tr>
                                <td>FW Version:</td>
                                <td>{ port.fwVersion }</td>
                            </tr>
                            <tr>
                                <td>UID:</td>
                                <td>{ port.uid }</td>
                            </tr>
                            <tr>
                                <td>Battery:</td>
                                <td>{ port.battery }</td>
                            </tr>
                            <tr>
                                <td>Progress:</td>
                                <td>
                                    <div className="pt-1">
                                        <div className="flex mb-2 items-center justify-between">
                                            <div>
                                        <span
                                            className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-primary-700 bg-primary-200"
                                        >
                                            Upload picks
                                        </span>
                                            </div>
                                            <div className="text-right">
                                        <span
                                            className="text-xs font-semibold inline-block text-primary-700"
                                        >
                                            { Math.round(getProgress(port)) }%
                                        </span>
                                            </div>
                                        </div>
                                        <div
                                            className="overflow-hidden h-2 mb-4 text-xs flex rounded bg-primary-200"
                                        >
                                            <div style={{width: getProgress(port) + '%'}}
                                                 className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-primary-500"
                                            ></div>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </Dialog>
        </div>
    )
}

export default StationStatus;

// <div className="flex flex-row">
//     <div className="flex flex-col items-center w-40">
//         <div className="bg-primary-200 text-primary-800 text-center p-1 w-full">
//             <p className="underline text-xl">
//                 {station.ports.filter((s) => s.loaded).length} /{" "}
//                 {station.ports.length}
//             </p>
//             <p className="text-sm truncate">{station.name}</p>
//             <p className="text-sm">({station.uid.substr(-6)})</p>
//             <p className="text-sm">{station.received?.toString()}</p>
//         </div>
//         <div className="p-1 w-full">
//             <img className="w-16" src="/images/top.png" alt=""/>
//             {station.ports.map((port, i) => (
//                 <div
//                     className="relative select-none cursor-pointer"
//                     key={i}
//                 >
//                     {!port.loaded && (
//                         <img
//                             className="w-16"
//                             src="/images/empty.png"
//                             alt=""
//                         />
//                     )}
//                     {port.loaded && (
//                         <div>
//                             <div className="flex">
//                                 <img
//                                     className="w-16"
//                                     src="/images/loaded.png"
//                                     alt=""
//                                 />
//                                 <div
//                                     className="border-t-2 border-primary-300 px-1 w-full"
//                                     style={{fontSize: "0.5rem"}}
//                                 >
//                                     <div>
//                                         ({i + 1}) {port.name}
//                                     </div>
//                                     <div style={{fontSize: "0.3rem"}}>
//                                         FW: {port.fwVersion}
//                                     </div>
//                                 </div>
//                             </div>
//                             <div
//                                 className={`absolute w-4 h-4 left-[10px] top-[10px] ${
//                                     {
//                                         "Complete": "bg-cover bg-[url('/public/images/blue.png')]",
//                                         "Busy": "bg-cover bg-[url('/public/images/lblue.png')] animate-flicker",
//                                         "Waiting": "bg-cover bg-[url('/public/images/yellow.png')]",
//                                     }[port.top]
//                                 }`}
//                             ></div>
//                             <div
//                                 className={`absolute w-4 h-4 left-[10px] top-[18px] ${
//                                     {
//                                         "Red": "bg-cover bg-[url('/public/images/red.png')]",
//                                         "Yellow": "bg-cover bg-[url('/public/images/yellow.png')]",
//                                         "Green": "bg-cover bg-[url('/public/images/green.png')]",
//                                     }[port.bottom]
//                                 }`}
//                             ></div>
//                         </div>
//                     )}
//                 </div>
//             ))}
//             <img className="w-16" src="/images/bottom.png" alt=""/>
//         </div>
//     </div>
// </div>

// <template>
//     <div>
//         <div class="flex flex-col items-center w-40">
//             <div class="bg-primary-200 text-primary-800 text-center p-1 w-full">
//                 <p class="underline text-xl">
//                     {{ localStation.ports.filter((s) => s.loaded).length }} /
//                     {{ localStation.ports.length }}
//                 </p>
//                 <p class="text-sm truncate">{{ localStation.name }}</p>
//                 <p class="text-sm">({{ localStation.uid | last(6) }})</p>
//                 <p class="text-sm">{{ localStation.received | datetime }}</p>
//             </div>
//             <div class="p-1 w-full">
//                 <img class="w-16" src="@/images/top.png" alt="" />
//                 <div
//                     class="relative select-none cursor-pointer"
//                     v-for="(port, i) in localStation.ports"
//                 >
//                     <img
//                         class="w-16"
//                         v-if="!port.loaded"
//                         src="@/images/empty.png"
//                         alt=""
//                     />
//                     <div class="flex" @click="showPort(port)" v-else>
//                         <img class="w-16" src="@/images/loaded.png" alt="" />
//                         <div
//                             class="light light-top"
//                             :class="
//                                 [
//                                     '',
//                                     'light-blue',
//                                     'light-lblue animate-flicker',
//                                     'light-yellow',
//                                 ][port.top]
//                             "
//                         ></div>
//                         <div
//                             class="light light-bottom"
//                             :class="
//                                 ['light-red', 'light-yellow', 'light-green'][
//                                     port.bottom
//                                 ]
//                             "
//                         ></div>
//                         <div
//                             class="border-t-2 border-primary-300 px-1 w-full"
//                             style="font-size: 0.5rem"
//                         >
//                             <div>({{ i + 1 }}) {{ port.name }}</div>
//                             <div style="font-size: 0.3rem">
//                                 FW: {{ port.fwVersion }}
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//                 <img class="w-16" src="@/images/bottom.png" alt="" />
//             </div>
//         </div>
//         <i-dialog :show.sync="portShow" heading="Port Data">
//             <table>
//                 <tr>
//                     <td>Port:</td>
//                     <td>{{ localStation.ports.indexOf(port) + 1 }}</td>
//                 </tr>
//                 <tr>
//                     <td>Name:</td>
//                     <td>{{ port.name }}</td>
//                 </tr>
//                 <tr>
//                     <td>FW Version:</td>
//                     <td>{{ port.fwVersion }}</td>
//                 </tr>
//                 <tr>
//                     <td>UID:</td>
//                     <td>{{ port.uid }}</td>
//                 </tr>
//                 <tr>
//                     <td>Battery:</td>
//                     <td>{{ port.battery }}</td>
//                 </tr>
//                 <tr>
//                     <td>Progress:</td>
//                     <td>
//                         <div class="pt-1">
//                             <div class="flex mb-2 items-center justify-between">
//                                 <div>
//                                     <span
//                                         class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-primary-700 bg-primary-200"
//                                     >
//                                         Upload picks
//                                     </span>
//                                 </div>
//                                 <div class="text-right">
//                                     <span
//                                         class="text-xs font-semibold inline-block text-primary-700"
//                                     >
//                                         {{ getProgress(port) | round }}%
//                                     </span>
//                                 </div>
//                             </div>
//                             <div
//                                 class="overflow-hidden h-2 mb-4 text-xs flex rounded bg-primary-200"
//                             >
//                                 <div
//                                     :style="'width:' + getProgress(port) + '%'"
//                                     class="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-primary-500"
//                                 ></div>
//                             </div>
//                         </div>
//                     </td>
//                 </tr>
//             </table>
//         </i-dialog>
//     </div>
// </template>
//
// <script>
// export default {
//     name: "stationstatus.vue",
//     props: {
//         station: Object,
//     },
//     data() {
//         return {
//             portShow: false,
//             port: {},
//             localStation: {...this.station}
//         };
//     },
//     created() {
//         this.$messages.on("StationStatus", (data) => {
//             // might be registed to many stations on the dashboard.
//             if (data.id == this.station.id) {
//                 this.localStation = data
//             }
//         });
//
//         this.listenStation()
//     },
//     beforeDestroy() {
//         this.$messages.off("StationStatus");
//
//         // dont mind if this fails
//         this.$messages.invoke("StopListenStation", this.station.id);
//     },
//     methods: {
//         listenStation() {
//             // try again if not connected
//             if(this.$messages.connection.connectionState != 1) {
//                 setTimeout(this.listenStation, 2000)
//                 return
//             }
//
//             this.$messages.invoke("ListenStation", this.station.id)
//         },
//         getProgress(port) {
//             return (port.progress / port.dataSize) * 100;
//         },
//         showPort(port) {
//             this.portShow = true;
//             this.port = port;
//         },
//     },
// };
// </script>
//
//
// <style>
// .light {
//     position: absolute;
//     width: 1rem;
//     height: 1rem;
// }
//
// .light-top {
//     left: 10px;
//     top: 10px;
// }
//
// .light-bottom {
//     left: 10px;
//     top: 18px;
// }
//
// .light-red {
//     background: url(../images/red.png);
//     background-size: cover;
// }
//
// .light-yellow {
//     background: url(../images/yellow.png);
//     background-size: cover;
// }
//
// .light-green {
//     background: url(../images/green.png);
//     background-size: cover;
// }
//
// .light-blue {
//     background: url(../images/blue.png);
//     background-size: cover;
// }
//
// .light-lblue {
//     background: url(../images/lblue.png);
//     background-size: cover;
// }
//
// @keyframes flickerAnimation {
//     0% {
//         opacity: 1;
//     }
//     50% {
//         opacity: 0;
//     }
//     100% {
//         opacity: 1;
//     }
// }
//
// @-o-keyframes flickerAnimation {
//     0% {
//         opacity: 1;
//     }
//     50% {
//         opacity: 0;
//     }
//     100% {
//         opacity: 1;
//     }
// }
//
// @-moz-keyframes flickerAnimation {
//     0% {
//         opacity: 1;
//     }
//     50% {
//         opacity: 0;
//     }
//     100% {
//         opacity: 1;
//     }
// }
//
// @-webkit-keyframes flickerAnimation {
//     0% {
//         opacity: 1;
//     }
//     50% {
//         opacity: 0;
//     }
//     100% {
//         opacity: 1;
//     }
// }
//

// </style>
//
