import React, {Fragment, useContext, useState} from "react";
import PermissionEnum from "../../generated/interfaces/permissionEnum";
import AppContext from "../appContext";
import {classNames} from "../../wrapper";
import {Link} from "react-router-dom";
import Slider from "./Slider";


interface SubMenuItem {
    id: string;
    display: string;
    permission: PermissionEnum;
    path: string;
}

interface MenuItem {
    name: string;
    icon: string;
    subItems: SubMenuItem[]
}

const menuItems: MenuItem[] = [
    {
        name: 'Dashboard',
        icon: 'hammer',
        subItems: [
            {id: 'farms', display: 'Farms', permission: PermissionEnum.Dashboard_FarmsPermissions, path: '/dashboard-farms'},
            {id: 'labours', display: 'Labours', permission: PermissionEnum.Dashboard_LabourPermissions, path: '/dashboard-labours'},
            {id: 'bins', display: 'Bins', permission: PermissionEnum.Dashboard_BinsPermissions, path: '/dashboard-bins'},
            {id: 'stations', display: 'Stations', permission: PermissionEnum.Dashboard_Stations, path: '/dashboard-stations'},
            {id: 'dashboardScissors', display: 'Scissors', permission: PermissionEnum.Dashboard_Scissors, path: '/dashboard-scissors'},
            {id: 'microManagement', display: 'Micro Management', permission: PermissionEnum.Dashboard_MicroManagement, path: '/dashboard-microManagement'},
        ]
    },
    {
        name: 'Manage',
        icon: 'profit',
        subItems: [
            {id: 'clients', display: 'Clients', permission: PermissionEnum.Management_ClientsPermissions, path: '/clients'},
            {id: 'farms', display: 'Farms', permission: PermissionEnum.Management_FarmsPermissions, path: '/farms'},
            {id: 'stations', display: 'Stations', permission: PermissionEnum.Management_StationsPermissions, path: '/stations'},
            {id: 'users', display: 'Users', permission: PermissionEnum.Management_UsersPermissions, path: '/users'},
            {id: 'roles', display: 'Roles', permission: PermissionEnum.Management_RolesPermissions, path: '/roles'},
            {id: 'scissors', display: 'Scissors', permission: PermissionEnum.Management_ScissorsPermissions, path: '/scissors'},
            {id: 'scanners', display: 'Scanners', permission: PermissionEnum.Management_Scanners, path: '/scanners'},
            {id: 'handhelds', display: 'Handhelds', permission: PermissionEnum.Management_HandheldsPermissions, path: '/handhelds'},
            {id: 'entries', display: 'Entries', permission: PermissionEnum.Management_Entries, path: '/entries'},

        ]
    },
];

const Menu: React.FC<{
    render: (item: SubMenuItem, active: boolean) => React.ReactNode;
    className?: string;
}> = (props) => {
    const context = useContext(AppContext)
    const [selected, setSelected] = useState('')


    function checkSubPermissions(subItems: MenuItem["subItems"]): boolean {
        return subItems.some(sub => context.hasPermission(sub.permission));
    }

    return (
        <div className={classNames('space-y-1', props.className ?? '')}>
            <nav>
                {menuItems.map(item => {
                    return <Fragment key={item.name}>

                        {checkSubPermissions(item.subItems) ?

                            <div className='flex select-none justify-end items-center menu-heading p-2 hover:bg-[#006936] rounded-lg cursor-pointer'
                                 onClick={() => setSelected(selected === item.name ? '' : item.name)}>
                                <div className={classNames("p-2 mr-4 align-middle", `sprite-${item.icon}`)}></div>
                                <div className="text-white hover:text-gray-200 group flex-1 pr-2 py-2 text-left font-medium rounded-md focus:outline-none"
                                     aria-controls="sub-menu-1" aria-expanded="false">
                                    {item.name}
                                </div>
                            </div>
                            : null}

                        <Slider show={selected == item.name}>
                        {item.subItems.map(subItem => {
                            if (context.hasPermission(subItem.permission))
                                return <div key={subItem.id} className="select-none"><Link
                                    to={subItem.path}>{props.render(subItem, false)}</Link></div>
                            return null;
                        })}
                        </Slider>
                    </Fragment>
                })}
            </nav>
        </div>
    )
}

export default Menu;

// <template>
//     <div class="flex flex-col h-full overflow-scroll">
//         <div class="text-white">
//             <i-collapsable v-for="(item, i) in items" :expand="active === item" @open="openMenu(item)"
//                            @close="active = null" :key="i">
//                 <template v-if="checkParent(item)">
//                     <div class="menu-heading p-2" slot="heading" :class="{selected: active == item}">
//                         <div class="inline-block p-2 align-middle" :class="'sprite-' + item.icon"></div>
//                         <div class="inline-block pl-4 align-middle">{{ item.title }}</div>
//                     </div>
//                     <div class="sub-menu p-2 cursor-pointer" v-for="sub in item.items" @click="showView(sub.action)"
//                          :key="sub.action" v-if="checkPermission(sub.permission)">
//                         <div class="pl-4">{{ sub.title }}</div>
//                     </div>
//                 </template>
//             </i-collapsable>
//         </div>
//         <div class="py-4 text-center mt-auto">
//             <i-time></i-time>
//             <div class="mx-auto sprite-logo2"></div>
//             <button @click="logout()" class="mb-4 mx-auto btn btn-error">logout</button>
//         </div>
//     </div>
// </template>
// <script>
// import Ajax from "@/scripts/ajax";
// import time from "./main/time.vue"
// export default {
//     components: {
//         'i-time': time
//     },
//     data() {
//         return {
//             permissions: window.initial.permissions,
//             active: null,
//             toggle: false,
//             items: [
//                 {
//                     title: "Dashboard",
//                     icon: "hammer",
//                     items: [
//                         {permission: [8], action: "dashboard-farms", title: "Farms"},
//                         {permission: [10], action: "dashboard-labours", title: "Labours"},
//                         {permission: [12], action: "dashboard-bins", title: "Bins"},
//                         {permission: [13], action: "dashboard-stations", title: "Stations"},
//                     ]
//                 },
//                 {
//                     title: "Manage",
//                     icon: "profit",
//                     items: [
//                         {permission: [0, 2], action: "manage-clients", title: "Clients"},
//                         {permission: [0, 3], action: "manage-farms", title: "Farms"},
//                         {permission: [0, 4], action: "manage-stations", title: "Stations"},
//                         {permission: [0, 5], action: "manage-users", title: "Users"},
//                         {permission: [0, 1], action: "manage-roles", title: "Roles"},
//                         {permission: [0, 6], action: "manage-scissors", title: "Scissors"},
//                         {permission: [0, 11], action: "manage-scanners", title: "Scanners"},
//                         {permission: [0, 9], action: "manage-handhelds", title: "Handhelds"},
//                         {permission: [0, 7], action: "manage-providers", title: "Providers"},
//                     ]
//                 },
//             ]
//         };
//     },
//     methods: {
//         logout() {
//             Ajax.get("/auth/logout").then((resp) => {
//                 window.location = "/";
//             });
//         },
//         openMenu(item) {
//             this.active = item;
//         },
//         showView(action) {
//             this.$router.push({name: action});
//             this.$emit('selected')
//         },
//         checkParent(parent) {
//             var show = false
//             parent.items.forEach(child => {
//                 if (this.checkPermission(child.permission)) {
//                     show = true
//                 }
//             });
//             return show
//         },
//         checkPermission(permissions) {
//             for (let index = 0; index < permissions.length; index++) {
//                 const permission = permissions[index];
//                 if (this.permissions.indexOf(permission) >= 0) {
//                     return true
//                 }
//             }
//             return false
//         }
//     }
// };
// </script>
// <style scoped>
// @import "../spritesheets/icons.css";
//
// .sub-menu {
//     padding-left: 46px;
// }
//
// .menu-heading:hover, .sub-menu:hover {
//     background-color: #006936;
//     border-radius: 8px;
// }
//
// </style>
