import React, { ReactNode, useImperativeHandle, useState } from 'react'
import { arrayRemovePredicate } from './immutableState'
import DialogPortal from './components/DialogPortal'
import { XIcon } from '@heroicons/react/solid'
let snackId = 0

interface Snack {
    // each snack has a unique id
    id: number;
    node: ReactNode;
}
export interface SnackOperations {
    showSnack (node: React.ReactNode): () => void;
}

const Snacks: React.ForwardRefRenderFunction<SnackOperations> = (props, ref) => {
    const [snacks, setSnacks] = useState<Snack[]>([])

    function removeSnack (id: number) {
        setSnacks(prev => arrayRemovePredicate(prev, s => s.id !== id))
    }

    useImperativeHandle(ref, () => ({
        showSnack (node: React.ReactNode): () => void {
            const id = snackId++
            setSnacks([{ id, node }, ...snacks])

            const to = setTimeout(() => {
                removeSnack(id)
            }, 5000)

            return () => {
                removeSnack(id)
                clearTimeout(to)
            }
        }
    }))

    return <div>

        {snacks.length > 0
            ? <DialogPortal>
                <div className="fixed right-0 bottom-0 z-50">
                    {snacks.map(s => <div key={s.id} className="m-4 border bg-white p-4 rounded shadow-lg relative animate-fade z-50">

                        <div className="mr-4">
                            {s.node}
                        </div>

                        <div className="absolute top-0 right-0 m-2 cursor-pointer z-50" onClick={() => removeSnack(s.id)}>
                            <XIcon className="w-5 h-5 text-gray-400"/>
                        </div>

                    </div>)}
                </div>
            </DialogPortal>
            : null}
    </div>
}

export default React.forwardRef(Snacks)