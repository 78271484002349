import React, {useContext, useRef} from "react";
import ClientController from "../generated/controllers/clientController";
import ClientDisplay from "../generated/interfaces/clientDisplay";
import CheckBox from "../components/CheckBox";
import Success from "../components/Success";
import PagedSearchTable, {PagedTableFunctions} from "../components/PagedSearchTable";
import AppContext from "./appContext";
import Failed from "../components/Failed";

const Clients: React.FC = () => {
    const pagedTableRef = useRef<PagedTableFunctions<ClientDisplay>>()
    const context = useContext(AppContext)

    function runner() {
        const hideLoader = context.showLoader()
        ClientController.runner().then(() => {
            context.showSnack(<Success title={"Success"}/>)
        }).catch(() => {
            context.showSnack(<Failed title={"Operation has failed"}/>)
        }).finally(() =>  hideLoader()
        )
    }
    function clientUpdateBoundary(row: ClientDisplay) {
        const hideLoader = context.showLoader()
        ClientController.boundary({
            id: row.id,
            checkBoundary: !row.checkBoundary
        }).then(() => {
            pagedTableRef.current?.refresh()
            context.showSnack(<Success title={"Success"}/>)
        }).catch(() => {
            context.showSnack(<Failed title={"Operation has failed"}/>)
        }).finally(() => {
            if (hideLoader) { hideLoader() }
        })
    }


    return (
        <div>
            <div className="btn btn-error my-2" onClick={runner}>MFW</div>
            <PagedSearchTable
                componentRef={pagedTableRef}
                call={ClientController.paged}
                columns={[{
                    header: 'Name',
                    row: (row) => row.name
                },
                {
                    header: 'Updating',
                    row: (row) => row.updating ? 'true' : 'false'
                },
                {
                    header: 'Originate',
                    row: (row) => row.originate
                },
                {
                    header: 'Check Boundary',
                    row: (row) => <CheckBox checked={row.checkBoundary}
                                            onChange={() => clientUpdateBoundary(row)}/>
                }]}
            />
        </div>
    )
}

export default Clients;
