import React, {useContext, useRef} from "react";
import PagedSearchTable, {PagedTableFunctions} from "../components/PagedSearchTable";
import {dateTimeOffset} from "../date";
import AppContext from "./appContext";
import {Point} from "chart.js/auto";
import EntryController from "../generated/controllers/entryController";
import RawEntry from "../generated/interfaces/rawEntry";

const Entries:React.FC = () => {
    const context = useContext(AppContext);
    const pagedTableRef = useRef<PagedTableFunctions<RawEntry>>()


    //
    function showLocation(item: Point) {
        let url = `https://www.google.com/maps/search/?api=1&query=${item.x},${item.y}`;
        window.open(url);
    }

    return (
        <PagedSearchTable call={EntryController.getEntries} componentRef={pagedTableRef} columns={[{
            header: 'Id',
            row: (row) => row.id
        },{
            header: "Station",
            row: (row) => row.stationId
        }, {
            header: "Timestamp",
            row: (row) => dateTimeOffset(row.timeStamp, context.initial.timeOffset)
        } , {
            header: "Battery",
            row: (row) => row.battery
        }, {
            header: "Gps",
            row: (row) => row.gpsStatus
        }, {
            header: "Point",
            row: (row) => {
                return row.point
                    ? <div className="icon w-8">
                        <svg
                            viewBox="0 0 24 24"
                             onClick={() => row.point? showLocation(row.point): null}
                        >
                            <path
                                className="svg-primary"
                                d="M5.64 16.36a9 9 0 1 1 12.72 0l-5.65 5.66a1 1 0 0 1-1.42 0l-5.65-5.66zM12 13a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"
                            ></path>
                            <path
                                className="svg-secondary"
                                d="M12 1a9 9 0 0 1 6.36 15.36l-5.65 5.66a1 1 0 0 1-.71.3V13a3 3 0 0 0 0-6V1z"
                            ></path>
                        </svg>
                    </div>
                    : null
            }
        }

        ]} />
    )
}

export default Entries;
