/* eslint-disable */
/**
 * This file was generated by Itecho.TSGen tool
 * Version and info generated in .version
 * Any changes to this file will be lost if regenerated 
 */

import { webRequest, fileRequest } from "../request";
import type IdRequest from "../interfaces/idRequest";
import type PaginationRequestSearch from "../interfaces/paginationRequestSearch";
import type PaginationResponse from "../interfaces/paginationResponse";
import type Role from "../interfaces/role";
import type RoleDisplay from "../interfaces/roleDisplay";
import type RolesRequest from "../interfaces/rolesRequest";

export default {
    index: () => webRequest<Role[]>('/role/index'),
    upsert: (request: RolesRequest) => webRequest<unknown>('/role/upsert', {
        method: 'post',
        body: request
    }),
    delete: (request: IdRequest) => webRequest<boolean>('/role/delete', {
        method: 'post',
        body: request
    }),
    paged: (request: PaginationRequestSearch) => webRequest<PaginationResponse<RoleDisplay>>('/role/paged', {
        method: 'post',
        body: request
    })
}