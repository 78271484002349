import React, { InputHTMLAttributes, useEffect, useState } from 'react'
import { classNames } from '../wrapper'

function toString (value: number, fractionDigits?: number): string {
    if (fractionDigits === undefined) { return value.toString() }

    return value.toFixed(fractionDigits)
}

const Number: React.FC<{
    value: number;
    change: (number: number) => void;
    children?: (attrs: InputHTMLAttributes<any>) => React.ReactNode
    fractionDigits?: number;
    error?: boolean;
}> = (props) => {
    const [last, setLast] = useState('')

    useEffect(() => {
        setLast(toString(props.value, props.fractionDigits))
    }, [props.value])

    function onChange (input: string) {
        setLast(input)
        const v = parseFloat(input)
        if (!isNaN(v) && isFinite(v)) {
            props.change(v)
        }
    }

    function onBlur () {
        // revert to last valid value
        setLast(toString(props.value, props.fractionDigits))
    }

    if (props.children) {
        return <>
            {props.children({
                value: last,
                onBlur: () => onBlur(),
                onInput: (e) => onChange((e.target as HTMLInputElement).value)
            })}
        </>
    }

    return <input type='number' className={classNames('input border', props.error ? 'border-error-300' : 'border-gray-300')} value={last} onBlur={() => onBlur()} onInput={(e) => onChange((e.target as HTMLInputElement).value)} />
}

export default Number
