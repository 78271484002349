import React, {useContext, useRef, useState} from "react";
import ScissorController from "../../generated/controllers/scissorController";
import PagedSearchTable, {PagedTableFunctions} from "../../components/PagedSearchTable";
import ScissorDisplay from "../../generated/interfaces/scissorDisplay";
import Dialog from "../../components/Dialog";
import {EditRow, EditTable} from "../../components/Fields";
import Input from "../../components/Input";
import Success from "../../components/Success";
import AppContext from "../appContext";
import EmployeeUpdateRequest from "../../generated/interfaces/employeeUpdateRequest";



const emptyEmployeeUpdateRequest = (): EmployeeUpdateRequest   => {
    return {
        id: 0,
        employeeNumber: "",
        employeeName: "",
        employeeSurname: "",

    }

}
const DashboardScissors: React.FC = () => {
    const pagedTableRef = useRef<PagedTableFunctions<ScissorDisplay>>()
    const [selected, setSelected]= useState<EmployeeUpdateRequest>(emptyEmployeeUpdateRequest)
    const [showEdit, setShowEdit] = useState(false)
    const context = useContext(AppContext)

    function Edit(row: ScissorDisplay   ) {
        setSelected(row)
        setShowEdit(true)
    }

    function editEmployee() {
        const hideLoader = context.showLoader()
        ScissorController.updateEmployee(selected).then(r => {
            setShowEdit (false);
            setSelected(emptyEmployeeUpdateRequest())
            context.showSnack(<Success title='Success' text='success'/>)
            pagedTableRef.current?.refresh();}).finally(() => hideLoader())
    }

    function discardEdit() {
        setShowEdit(false)
        setSelected(emptyEmployeeUpdateRequest())
    }

    return (
        <div>
            <PagedSearchTable call={ScissorController.pagedCurrent} componentRef={pagedTableRef} columns={[{
                header: 'Employee Number',
                row: (row) => row.employeeNumber
            },  {
            header: 'Employee Name',
                row: (row, i) => row.employeeName
            }, {
                header: 'Employee Surname',
                row: (row, i) => row.employeeSurname
            },{
                header: 'Scissor Name',
                row: (row) => row.name
            },{
                header: <div className="text-right">Actions</div>,
                row: (row) => <div className="flex justify-end btn-sm btn-primary mr-1" onClick={() => Edit(row)} >Edit </div>
            }
            ]}/>


            <Dialog title="Raw Entries" setShow={setShowEdit} show={showEdit} body={
                <div>
                    <EditTable
                        discard={discardEdit}
                        save={editEmployee}
                        saveWord={'Edit'}>
                        {EditRow('Employee Number:', <Input value={selected.employeeNumber ?? ''} change={v => setSelected({...selected, employeeNumber: v})}/>)}
                        {EditRow('Employee Name:', <Input value={selected.employeeName ?? ''} change={v => setSelected({...selected, employeeName: v})}/>)}
                        {EditRow('Employee Surname:', <Input value={selected.employeeSurname ?? ''} change={v => setSelected({...selected, employeeSurname: v})}/>)}

                    </EditTable>

                </div>
            }/>
        </div>
    );
};

export default DashboardScissors;
