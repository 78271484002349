import React, { createContext, ReactNode } from 'react'
import PermissionEnum from "../generated/interfaces/permissionEnum";
import WindowInitial from "../generated/interfaces/windowInitial";

export interface DialogData {
    title: string,
    body: React.ReactNode,
    onClose?: () => void,
    mounted?: (el: HTMLDivElement) => void;
}

export interface AppContextData {
    readonly isLoading: boolean,
    // return callback to hide the loader again
    showLoader: () => () => void,

    showSnack (node: ReactNode): () => void;

    hasPermission(permission: PermissionEnum): boolean;
    readonly initial: Readonly<WindowInitial>;

}

// This is the default context required by react.
export default createContext<AppContextData>({
    isLoading: false,
    showLoader () {
        return () => {
        }
    },
    showSnack () {
        return () => {
        }
    },
    hasPermission (permission: PermissionEnum): boolean {
        return false
    },
    initial: {
        authenticated: false,
        availablePermissions: {},
        name: '',
        version: '',
        permissions: [],
        timeOffset: 0,
        isDev: false
    },
})
