import React, {DependencyList, SetStateAction, useEffect, useRef, useState} from 'react'
import {init} from "@sentry/react";

export function create2dArray<T> (cols: number, rows: number, factory: (c: number, r: number) => T): T[][] {
    return createArray(cols, c =>
        createArray(rows, (r) => factory(c, r)))
}

export function arrayFilter<T> (arr: T[], filter: (item: T) => boolean, limit: number): T[] {
    const ret: T[] = []
    for (const r of arr) {
        if (filter(r)) {
            if (ret.push(r) >= limit) { return ret }
        }
    }
    return ret
}

export type Writeable<T> = { -readonly [P in keyof T]: T[P] };

export function mutate<T extends object> (object: T, callback: ((data: Writeable<T>) => void)): T {
    const clone = { ...object }
    callback(clone)
    return clone
}

// array setter returns a function on given property with index
// so it can be used: (row, index> => <Number value={row.prop} change={setter.prop(index) />
export function buildSetterArray<T> (data: T[], setData: React.Dispatch<SetStateAction<T[]>>): (index: number, data: Partial<T>) => void {
    return function set (index: number, partial: Partial<T>) {
        setData(arrayUpdatePartial(data, index, partial))
    }
}

export type ArrayUpdateType<T> = Partial<T> | ((old:T) => T);

export function useStateSetterArray<T>(initial: T[]): [T[], React.Dispatch<T[]>, (index: number, update: ArrayUpdateType<T>) => void] {
    const [data, setData] = useState(initial);
    function arraySetter(index: number, update: Partial<T> | T | ((old:T) => T)) {
        setData(arrayUpdatePartial(data, index, update));
    }

    return [data, setData, arraySetter];
}

export function useStateSetter<T extends {}>(initial: T): [T, React.Dispatch<T>, (partial: Partial<T>) => void] {
    function setter(partial: Partial<T>): void {
        setData({...data, ...partial})
    }
    const [data, setData] = useState(initial);
    return [data, setData, setter]
}

export function buildSetter<T extends {}> (data: T, setData: React.Dispatch<T>): [(data: Partial<T>) => void, T] {
    return [function set (partial: Partial<T>) {
        setData({ ...data, ...partial })
    }, data];
}

export function createArray<T> (size: number, factory: (i: number) => T): T[] {
    // first have to fill with null
    // otherwise it will contain the same references.
    return new Array(size)
        .fill(null)
        .map((_, i) => factory(i))
}

export function arrayUpdate<T> (data: T[], index: number | ((data: T, index: number) => boolean), update: T | ((old: T) => T | void)): T[] {
    return data.map((old, i) => {
        const match = typeof index === 'number'
            ? index === i
            : index(old, i)

        if (match) {
            if (typeof update === 'function') {
                const ret = (update as (old: T) => T | void)(old);
                return ret ?? old;
            }
            return update
        }
        return old
    })
}

export function arrayUpdatePartial<T> (data: T[], index: number, update: ArrayUpdateType<T>): T[] {
    return data.map((old, i) => {
        if (index != i)
            return old;

        if (typeof update === 'function')
            return update(old);

        return { ...old, ...update }
    })
}

export function arrayPush<T> (data: T[], item: T): T[] {
    return [...data, item]
    // data.concat(item);
}

export function arrayRemoveItem<T> (arr: T[], item: T): T[] {
    return arr.filter(e => item !== e)
}

export function arrayRemovePredicate<T> (arr: T[], predicate: (item: T) => boolean): T[] {
    return arr.filter(predicate)
}

export function arrayRemoveIndex<T> (arr: T[], index: number): T[] {
    return arr.filter((_, i) => i !== index)
    // const clone = [...arr];
    // clone.splice(index, 1);
    // return clone;
}

// copy item from source into target
export function arrayCopy<T> (target: T[], source?: T[]): T[] {
    if (!source) { return target }

    if (target.length > source.length) { return source.concat(target.slice(source.length)) }
    if (target.length < source.length) { return source.slice(0, target.length) }

    // length is the same
    return source
}

// shallow check if two objects are the same
export function shallowEquality<T extends object> (prev: T, curr: T): boolean {
    if (prev === curr) { return true }

    for (const k in prev) {
        if (prev[k] !== curr[k]) { return false }
    }

    return true
}

export function arrayCompare<T> (prev: T[], next: T[], equality: (prev:T, next: T) => boolean): boolean {
    if (prev.length !== next.length) { return false }

    for (let i = 0; i < prev.length; i++) {
        if (!equality(prev[i]!, next[i]!)) { return false }
    }
    return true
}