import React, { PropsWithChildren, useContext } from 'react'

const SelectNumberNullable = <TOptions, >(props: PropsWithChildren<{
    options: TOptions[];
    textFunc: (item: TOptions) => string;
    valueFunc: (item: TOptions) => number;

    value: number | null;
    onChange: (value: number | null) => void;
    nullableText?: string | null;
}>) => {
    return <select className="input" placeholder="Click to select"
        onChange={e => props.onChange(e.target.value === '' ? null : parseInt(e.target.value))}
        value={props.value ?? ''}
    >
        <option value="">{props.nullableText || 'Choose an option'}</option>
        {props.options.map((l) => <option key={props.valueFunc(l)}
            value={props.valueFunc(l)}>{props.textFunc(l)}</option>)}
    </select>
}

export default SelectNumberNullable
