import React, {useContext, useEffect, useRef, useState} from "react";
import ScannerController from "../generated/controllers/scannerController";
import PagedSearchTable, { PagedTableFunctions } from "../components/PagedSearchTable";
import {useStateAjax, wrapLoader} from "../wrapper";
import ClientController from "../generated/controllers/clientController";
import ScannersDisplay from "../generated/interfaces/scannersDisplay";
import ClientList from "../generated/interfaces/clientList";
import SelectNumberNullable from "../components/SelectNumberNullable";
import Dialog from "../components/Dialog";
import Scanned from "./Scanned";
import AppContext from "../pages/appContext";


const Scanners:React.FC = () => {
    const context = useContext(AppContext)
    const pagedTableRef = useRef<PagedTableFunctions<ScannersDisplay>>()

    const [clients, setClients] = useStateAjax<ClientList>(ClientController.all)
    const [entryId, setEntryId] = useState<number | null>(null)
    const [showEntries, setShowEntries] = useState(false)

    useEffect(() => {
        wrapLoader(context, ClientController.all(), resp => {
            setClients(resp)
        })
    }, [])


    function viewEntries(id: number){
        setEntryId(id)
        setShowEntries(true)
    }

    function updateClient(event: number | null, row: ScannersDisplay) {
        row.clientId = event
        ScannerController.update({
            id: row.id,
            clientId: event ?? 0
        }).then(resp => {
            pagedTableRef.current?.refresh()
        })
    }

    return (
        <div>
            <PagedSearchTable
                componentRef={pagedTableRef}
                downloadExcelCall={ScannerController.downloadScanners}
                call={ScannerController.scanners}
                columns={[{
                    header: 'Uid',
                    row: (row) => row.uid
                }, {
                    header: 'Balena',
                    row: (row) => row.balena
                }, {
                    header: 'Client',
                    row: (row, index) => <SelectNumberNullable<ClientList> value={row.clientId} onChange={(v) => updateClient(v, row)} textFunc={t => t.name} valueFunc={v => v.id} options={clients}/>
                }, {
                    header: <div className="text-right">Actions</div>,
                    row: (row) => <div className="flex justify-end btn-sm bg-primary-500" onClick={() => viewEntries(row.id)}>View</div>
                }]}/>

            <Dialog title='Entries' show={showEntries} setShow={setShowEntries} body={
                <div>
                    {
                        entryId ? <Scanned scannerId={entryId}/> : <div>No data found.</div>
                    }
                </div>
            }/>
        </div>
    )
}

export default Scanners;

