import React, {useRef, useState} from "react";
import PagedSearchTable, {PagedTableFunctions} from "../../components/PagedSearchTable";
import FarmController from "../../generated/controllers/farmController";
import {dateFormat} from "../../date";
import Dialog from "../../components/Dialog";
import WeightsPaged from "../../generated/interfaces/weightsPaged";
import LandWeightRequest from "../../generated/interfaces/landWeightRequest";
import {EditRow, EditTable} from "../../components/Fields";
import {DatePicker, DatePickerType} from "../../components/DatePicker";
import NumberNullable from "../../components/NumberNullable";
import Number from "../../components/Number";
import WarningPopup, {useWarningState} from "../../components/WarningPopup";

const FarmWeightsDialogs: React.FC<{
    landWeightsDialog:  boolean,
    setLandWeightsDialog(data: boolean): void,
    weightsPaged: {id: number | null, ha: number},
    setWeightsPaged(data: {id: number | null, ha: number}): void
}> = (props) => {
    const landsWeightRef = useRef<PagedTableFunctions<WeightsPaged>>()
    const warningState = useWarningState<number>(-1)

    const [weightDialog, setWeightDialog] = useState(false)
    const [weight, setWeight] = useState<LandWeightRequest>({
        landId: 0,
        day: new Date(),
        weight: 0,
        totalBins: null,
        kgPBin: null
    })


    function deleteWeight(id: number) {
        FarmController.deleteWeight({id}).then(resp => {
            landsWeightRef.current?.refresh()
        })
    }

    function saveWeight() {
        FarmController.addLandWeight(weight).then(() => {
            setWeightDialog(false)
            landsWeightRef.current?.refresh()
        })
    }

    function showWeightDialog(id: number | null) {
        if (id == null) return
        setWeightDialog(true)
        setWeight({...weight, landId: id, weight: 0, totalBins: null, kgPBin: null})
    }


    function totalBinsChanged(val: number | null) {
        setWeight({...weight, weight: (val ?? 0) * (weight.kgPBin ?? 0), totalBins: val})
    }

    function totalKgPBinChanged(val: number | null) {
        setWeight({...weight, weight: (weight.totalBins ?? 0) * (val ?? 0), kgPBin: val})
    }

    function weightChanged(val:  number) {
        setWeight({...weight, kgPBin: weight.totalBins ? val / weight.totalBins : null, weight: val})
    }

    return (
        <div>
            <Dialog title='Weights'
                    key="landsweight"
                    show={props.landWeightsDialog}
                    setShow={() => {
                        props.setLandWeightsDialog(false)
                        props.setWeightsPaged({ha: 0, id: null})
                    }}
                    body={
                        <div className="m-2">
                            <div className="btn-sm mb-2 btn-primary" onClick={() => showWeightDialog(props.weightsPaged.id)} >Add Weight</div>
                            <PagedSearchTable componentRef={landsWeightRef}
                                              call={resp => FarmController.weightsPaged({...resp, id: props.weightsPaged.id ?? 0})}
                                              columns={[
                                                  {
                                                      header: "Day",
                                                      key: 'day',
                                                      row: item => dateFormat(item.day),
                                                  },
                                                  {
                                                      header: "Weight KG",
                                                      key: 'weightKg',
                                                      row: item => item.weight,
                                                  },
                                                  {
                                                      header: "Total Bins",
                                                      key: 'totalBins',
                                                      row: item => item.totalBins,
                                                  },
                                                  {
                                                      header: "Average",
                                                      key: 'average',
                                                      row: item => item.averageWeightPBin,
                                                  },
                                                  {
                                                      header: "Actions",
                                                      key: 'actions',
                                                      row: (item) => <div className="btn-sm bg-error-500 p-1 text-white rounded" onClick={() => warningState.show('Are you sure you want to delete this weight?', item.id)}>delete</div>
                                                  },
                                              ]}
                                              summary={rows => {
                                                  const totalWeight = rows.reduce((a, b) => a + b.weight , 0)
                                                  const totalBins = rows.reduce((a, b) => a + (b.totalBins ?? 0), 0)

                                                  return <div>
                                                      <div className="bg-gray-200 p-1">
                                                          <span className="text-xl">Summary</span>
                                                          <div className='p-2'>
                                                              <table>
                                                                  <tbody>
                                                                  <tr className='m-2'>
                                                                      <td className='pr-12 p-2'>Total Weight: </td>
                                                                      <td>{totalWeight} KG</td>
                                                                  </tr>
                                                                  <tr className='m-2'>
                                                                      <td className='pr-12 p-2'>Total Bins: </td>
                                                                      <td>{totalBins}</td>
                                                                  </tr>
                                                                  <tr className='m-2'>
                                                                      <td className='pr-12 p-2'>Size: </td>
                                                                      <td>{ props.weightsPaged.ha } ha</td>
                                                                  </tr>
                                                                  <tr className='m-2'>
                                                                      <td className='pr-12 p-2'>Total Weight p/ha: </td>
                                                                      <td>{ totalWeight / props.weightsPaged.ha } KG</td>
                                                                  </tr>
                                                                  </tbody>
                                                              </table>
                                                          </div>
                                                      </div>
                                                  </div>
                                              }}
                            />

                            <Dialog  show={weightDialog}
                                     setShow={setWeightDialog}
                                     title={'New Weight'}
                                     key="weightDialog"
                                     body={
                                         <EditTable discard={() => setWeightDialog(false)}
                                                    save={() => saveWeight()}
                                                    saveWord='Add'
                                         >
                                             {EditRow('Date', <DatePicker
                                                 value={weight.day}
                                                 setValue={day => setWeight({...weight, day})}
                                                 type={DatePickerType.Date}
                                             />)}
                                             {EditRow('Total Bins', <NumberNullable value={weight.totalBins} change={(e) => totalBinsChanged(e)}/> )}
                                             {EditRow('KG per Bin', <NumberNullable value={weight.kgPBin} change={(e) => totalKgPBinChanged(e)}/> )}
                                             {EditRow('Total Weight in KG', <Number value={weight.weight} change={(e) => weightChanged(e)}/>)}
                                         </EditTable>
                                     }
                            />

                            <WarningPopup state={warningState} onYes={() => deleteWeight(warningState.state.data)}/>

                        </div>
                    }
            />

        </div>
    )
}

export default FarmWeightsDialogs
