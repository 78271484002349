/* eslint-disable */
/**
 * This file was generated by Itecho.TSGen tool
 * Version and info generated in .version
 * Any changes to this file will be lost if regenerated 
 */

import { webRequest, fileRequest } from "../request";
import type BinMetricsResponse from "../interfaces/binMetricsResponse";
import type PaginationRequestDate from "../interfaces/paginationRequestDate";
import type PaginationResponse from "../interfaces/paginationResponse";

export default {
    binEntries: (request: PaginationRequestDate) => webRequest<PaginationResponse<BinMetricsResponse>>('/bin/binentries', {
        method: 'post',
        body: request
    }),
    downloadBinEntries: (request: PaginationRequestDate) => fileRequest('/bin/downloadbinentries', {
        queryParams: {
            ...request
        }
    })
}