export function last(str: string, length: number) {

    if (str.length > length) {
        return `...${str.slice(-length)}`
    }
    return str;
}

export function first(str: string, length: number) {

    if (str.length > length) {
        return `${str.slice(0, length)}...`
    }
    return str;
}

export function trimArray<T>(arr: T[], predicate: (v: T) => boolean): T[] {
    let i = 0;
    let j = arr.length - 1;
    while (i < arr.length && predicate(arr[i]!)) i++;
    while (j >= 0 && predicate(arr[j]!)) j--;
    return arr.slice(i, j + 1);
}
