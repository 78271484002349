import React, {useContext, useEffect, useRef, useState} from "react";
import PagedSearchTable, {PagedTableFunctions} from "../components/PagedSearchTable";
import ScissorController from "../generated/controllers/scissorController";
import ScissorDisplay from "../generated/interfaces/scissorDisplay";
import ClientController from "../generated/controllers/clientController";
import ClientList from "../generated/interfaces/clientList";
import WarningPopup, {useWarningState} from "../components/WarningPopup";
import AppContext from "./appContext";
import Success from "../components/Success";
import {last} from "../helper";

const ScissorsArchived: React.FC = () => {
    const pagedTableRef = useRef<PagedTableFunctions<ScissorDisplay>>()
    const [clients, setClient] = useState<ClientList[]>([])
    const warningState = useWarningState<number>(-1)

    const context = useContext(AppContext)

    useEffect(() => {
        ClientController.all().then(resp => {
            setClient(resp)
        })
    }, [])

    function restoreScissors() {
        const hideLoader = context.showLoader()
        ScissorController.restore({id: warningState.state.data}).then(() => {
            pagedTableRef.current?.refresh()
            context.showSnack(<Success title='Success' text="Scissor restored"/>)
        }).finally(() => hideLoader())
    }


    return  (
        <div>
            <PagedSearchTable<ScissorDisplay>
                call={ScissorController.pagedArchived}
                componentRef={pagedTableRef}
                columns={[
                    {
                        header: 'Name',
                        key: 'name',
                        row: (row) => row.name
                    }, {
                        header: 'Tag',
                        key: 'tag',
                        row: (row) => last(row.uid, 8)
                    }, {
                        header: 'RFID',
                        key: 'rfid',
                        row: (row) => row.rfiduid
                    }, {
                        header: 'Raw Count',
                        key: 'rawCount',
                        row: (row) => row.rawCount
                    }, {
                        header: 'Location Count',
                        key: 'locationCount',
                        row: (row) => row.locationCount
                    }, {
                        header: 'Data Count',
                        key: 'dataCount',
                        row: (row) => row.dataCount
                    }, {
                        header: 'Performance %',
                        key: 'performance',
                        row: (row) => (((row.locationCount / row.rawCount) * 100).toFixed(2) )
                    }, {
                        header: 'Last Farm',
                        key: 'lastFarm',
                        row: (row) => row.farmName
                    }, {
                        header: 'Versions',
                        key: 'versions',
                        row: (row) => <div className="text-xs">
                            {row.fwVersion !== null ? <div>FW: {row.fwVersion}</div>: null}
                            {row.hwVersion !== null ? <div>HW: {row.hwVersion}</div>: null}
                        </div>
                    }
                    ,  {
                        header: 'Client',
                        key: 'client',
                        row: (row) => clients.find(s => s.id == row.clientId)?.name ?? ''
                    }, {
                        header: <div className="text-right">Actions</div>,
                        key: 'actions',
                        row: row => <button className="flex justify-end btn-sm btn-primary btn-sm" onClick={() => warningState.show("Are you sure you want to restore this scissor?", row.id)}>restore</button>
                    }
                ]}/>
            <WarningPopup state={warningState} onYes={() => restoreScissors()}/>
        </div>
    )
}

export default ScissorsArchived;
