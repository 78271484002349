/* eslint-disable */
/**
 * This file was generated by Itecho.TSGen tool
 * Version and info generated in .version
 * Any changes to this file will be lost if regenerated 
 */

import { webRequest, fileRequest } from "../request";
import type FieldEntryDisplay from "../interfaces/fieldEntryDisplay";
import type HandheldDisplay from "../interfaces/handheldDisplay";
import type HandheldSubmitModel from "../interfaces/handheldSubmitModel";
import type HandheldUpdateRequest from "../interfaces/handheldUpdateRequest";
import type PaginationRequestIdSearch from "../interfaces/paginationRequestIdSearch";
import type PaginationRequestSearch from "../interfaces/paginationRequestSearch";
import type PaginationResponse from "../interfaces/paginationResponse";
import type ResponseBase from "../interfaces/responseBase";

export default {
    paged: (request: PaginationRequestSearch) => webRequest<PaginationResponse<HandheldDisplay>>('/handheld/paged', {
        method: 'post',
        body: request
    }),
    update: (request: HandheldUpdateRequest) => webRequest<unknown>('/handheld/update', {
        method: 'post',
        body: request
    }),
    fieldEntries: (request: PaginationRequestIdSearch) => webRequest<PaginationResponse<FieldEntryDisplay>>('/handheld/fieldentries', {
        method: 'post',
        body: request
    }),
    entries: (request: HandheldSubmitModel) => webRequest<ResponseBase>('/handheld/entries', {
        method: 'post',
        body: request
    })
}