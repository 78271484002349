import React, {useRef, useEffect} from 'react';
import {Vector as VectorLayer} from 'ol/layer';
import {Vector as VectorSource} from 'ol/source';
import 'ol/ol.css';

import {Geometry} from "ol/geom";
import DialogPortal from "../components/DialogPortal";
import {onEscape} from "../helpers/keys";
import OpenLayerMapComponent from "./OpenLayerMapComponent";

const DialogOpenLayerMap: React.FC<{
    layers?: VectorLayer<VectorSource<Geometry>>[];
    tiffUrl?: string;
    setShow?: (show: boolean) => void;
}> = (props) => {
    const mapRef = useRef(null);

    function closeMap() {
        if (props.setShow) {
            props.setShow(false);
        }
    }

    useEffect(() => {
        window.addEventListener('keydown', onEscape(closeMap));
        return () => {
            window.removeEventListener('keydown', onEscape(closeMap))
        }
    }, [])

    return (
        <DialogPortal>
            <div className="fixed inset-0 z-10 bg-white">
                <OpenLayerMapComponent layers={props.layers} tiffUrl={props.tiffUrl}/>

                <div className="btn btn-error absolute top-2 right-2 p-2 z-10" onClick={closeMap}>X
                </div>
            </div>
        </DialogPortal>
    );
};

export default DialogOpenLayerMap;
