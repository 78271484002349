import React, {useContext, useRef, useState} from "react";
import FarmController from "../generated/controllers/farmController";
import SeasonController from "../generated/controllers/seasonController";
import FarmDisplay from "../generated/interfaces/farmDisplay";
import Season from "../generated/interfaces/season";
import Originate from "../generated/interfaces/originate";
import PaginationResponse from "../generated/interfaces/paginationResponse";
import PagedSearchTable, {PagedTableFunctions} from "../components/PagedSearchTable";
import Dialog from "../components/Dialog";
import {dateFormat} from "../date";
import PaginationRequestSearch from "../generated/interfaces/paginationRequestSearch";
import {EditRow, EditTable} from "../components/Fields";
import {DatePicker, DatePickerType} from "../components/DatePicker";
import WarningPopup, {useWarningState} from "../components/WarningPopup";
import AppContext from "./appContext";
import Success from "../components/Success";
import Failed from "../components/Failed";
import VectorLayer from "ol/layer/Vector";
import VectorSource from "ol/source/Vector";
import {Geometry} from "ol/geom";
import {
    farmBoundaryStyle,
    landBoundaryStyle,
    makeVectorLayer,
    readGeoJsonFeature,
    readGeoJsonFeatureCollection
} from "../helpers/map";
import DialogOpenLayerMap from "./DialogOpenLayerMap";
import OpenLayerMapComponent from "./OpenLayerMapComponent";
import Number from "../components/Number";

const emptySeason = (): Season => {
    return {
        id: 0,
        start: new Date(),
        end: new Date(),
        farmId: 0
    }
}

const emptyFarmDisplay = (): FarmDisplay => {
    return {
        id: 0,
        clientId: 0,
        name: '',
        boundaryUID: 'string',
        clientName: 'string',
        originate: Originate.International,
        tolerance: 0,
    }
}

const ManageFarms: React.FC = () => {
    const pagedTableRef = useRef<PagedTableFunctions<FarmDisplay>>()
    const context = useContext(AppContext)
    const seasonTable = useRef<PagedTableFunctions<Season>>()
    const [selectedFarm, setSelectedFarm] = useState<FarmDisplay>(emptyFarmDisplay())
    const [farmDetails, setFarmDetails] = useState<boolean>(false)
    const [seasonArgs, setSeasonArgs] = useState({})
    const [seasonDialog, setSeasonDialog] = useState<boolean>(false)
    const [season, setSeason] = useState<Season>(emptySeason())
    // const [boundryCenter, setBoundryCenter] = useState<google.maps.LatLng>({lat: 35, lng: 25})

    const warningStateSeason = useWarningState<number>(-1)
    const warningStatePoints = useWarningState<number>(-1)
    const warningStateGrid = useWarningState<number>(-1)
    const warningStateTiff = useWarningState<number>(-1)

    const [showComparison, setComparison] = useState<boolean>(false)

    const [mapShow, setMapShow] = useState<boolean>(false)
    const [mapLayers, setMapLayers] = useState<VectorLayer<VectorSource<Geometry>>[]>([])
    const [mapLayersNew, setMapLayersNew] = useState<VectorLayer<VectorSource<Geometry>>[]>([])

    function showSuccessFailed<T>(prom: Promise<T>) {
        prom.then(_ => context.showSnack(<Success title='Success' text='success'/>))
            .catch(() => context.showSnack(<Failed title='Failed' text='failed'/>))
    }
    
    function removePoints(farmId: number) {
        showSuccessFailed(FarmController.removePoints({id: farmId}).then(resp => {
            pagedTableRef.current?.refresh()
        }));
    }

    function view(farm: FarmDisplay) {
        FarmController.geoJson(farm.id).then((response) => {
            if (response.farm == "" && response.lands.length == 0) {
                context.showSnack(<Failed title='Failed'
                                          text='No farm boundary data exists. Please refresh from MFW.'/>)
                return
            }
            let farmBoundary = makeVectorLayer(readGeoJsonFeatureCollection(response.farm), farmBoundaryStyle)
            let landBoundaries = makeVectorLayer(response.lands.filter(l => l.length > 0).map(readGeoJsonFeature), landBoundaryStyle)

            setMapLayers([farmBoundary, landBoundaries])
            setMapShow(true)
        })
    }

    function edit(farm: FarmDisplay) {
        setSelectedFarm(farm)
        setSeasonArgs({id: farm.id})
        setFarmDetails(true)
    }

    function upsertSeason() {
        SeasonController.upsert({
            farmId: selectedFarm.id,
            start: season.start,
            end: season.end,
            id: 0 // insert only
        }).then(resp => {
            setSeason(emptySeason())
            setSeasonDialog(false)
            seasonTable.current?.refresh()
            // this.$refs.seasonTable.refresh()
        })
    }
    
    function compareTiffs(farm: FarmDisplay) {
        setSelectedFarm(farm)

        FarmController.geoJson(farm.id).then((response) => {
            if (response.farm == "" && response.lands.length == 0) {
                context.showSnack(<Failed title='Failed'
                                          text='No farm boundary data exists. Please refresh from MFW.'/>)
                return
            }
            let farmBoundary = makeVectorLayer(readGeoJsonFeatureCollection(response.farm), farmBoundaryStyle)
            let landBoundaries = makeVectorLayer(response.lands.filter(l => l.length > 0).map(readGeoJsonFeature), landBoundaryStyle)

            setMapLayers([farmBoundary, landBoundaries])
            setMapLayersNew([farmBoundary, landBoundaries])

            setComparison(true)
        })

    }

    function deleteSeason(id: number) {
        showSuccessFailed(SeasonController.delete({id}).then(resp => {
            seasonTable.current?.refresh()
        }));
    }

    function saveFarm() {
        const hideLoader = context.showLoader()
        FarmController.update(selectedFarm).then(resp => {
            setFarmDetails(false)
            setSelectedFarm(emptyFarmDisplay())
            pagedTableRef.current?.refresh()
        }).finally(() => hideLoader())
    }

    function showSeason() {
        setSeason(emptySeason())
        setSeasonDialog(true)
    }
    
    function hideSeason() {
        setSeason(emptySeason())
        setSeasonDialog(false)
    }

    function searchPagedEntries(request: PaginationRequestSearch): Promise<PaginationResponse<Season>> {
        return SeasonController.paged({
            ...request,
            id: selectedFarm.id
        })
    }

    const options: Record<string, (row: FarmDisplay) => void> = {
        "map": view,
        "compare": compareTiffs,
        "sync": row => showSuccessFailed(FarmController.sync({id: row.clientId})),
        "process": row => showSuccessFailed(FarmController.processFarm({id: row.id}))
    };

    function getActions(row: FarmDisplay) {
        return <div className="flex justify-end">
            <div className="mr-1 btn-sm btn-primary" onClick={() => edit(row)}>edit</div>
            <select value={'empty'}
                    className="bg-center bg-no-repeat outline-none btn-primary btn-sm p-2 w-9 "
                    style={{
                        appearance: "none",
                        backgroundImage: 'url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\' fill=\'none\' viewBox=\'0 0 24 24\' stroke-width=\'2\' stroke=\'white\' class=\'w-6 h-6\'><path stroke-linecap=\'round\' stroke-linejoin=\'round\' d=\'M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5\'/></svg>")'
                    }}
                    onChange={e => options[e.target.value]?.(row)}
            >
                <option value="empty" className="hidden" disabled={true}></option>
                {Object.keys(options).map(text => <option key={text} value={text}>{text}</option>)}
            </select>
        </div>
    }

    return (
        <div>
            <PagedSearchTable
                componentRef={pagedTableRef}
                call={FarmController.paged}
                columns={[
                    {
                        header: 'Client',
                        key: 'client',
                        row: row => row.clientName
                    },
                    {
                        header: 'Name',
                        key: 'name',
                        row: row => row.name
                    },
                    {
                        header: 'Originate',
                        key: 'originate',
                        row: row => row.originate
                    },
                    {
                        header: <div className="text-right">Actions</div>,
                        key: 'actions',
                        row: row => getActions(row)
                    },
                ]}/>

            {mapShow ? <DialogOpenLayerMap layers={mapLayers} setShow={setMapShow}/> : null}

            <Dialog title={'Farm details - ' + selectedFarm.name}
                    show={farmDetails}
                    setShow={setFarmDetails}
                    body={<div className='p-1'>
                        <div className="mt-2 mb-4">
                            {
                                EditRow('Tolerance: ',
                                    <Number
                                        value={selectedFarm.tolerance}
                                        change={t => setSelectedFarm({...selectedFarm, tolerance: t})}/>
                                )
                            }
                        </div>
                        <div className="btn btn-primary mb-1" onClick={() => showSeason()}>New Season</div>

                        <PagedSearchTable componentRef={seasonTable}
                                          call={searchPagedEntries}
                                          columns={[
                                              {
                                                  header: 'Start',
                                                  key: 'start',
                                                  row: row => dateFormat(row.start, '%d %M %Y')
                                              },
                                              {
                                                  header: 'End',
                                                  key: 'end',
                                                  row: row => dateFormat(row.end, '%d %M %Y')
                                              },
                                              {
                                                  header: 'Actions',
                                                  key: 'actions',
                                                  row: row => <div className="btn-sm btn-error"
                                                                   onClick={() => warningStateSeason.show('Are you sure you want to delete this season?', row.id)}>x</div>
                                              }
                                          ]}/>
                    </div>}
                    footer={<div className='btn btn-primary' onClick={() => saveFarm()}>
                        Save
                    </div>}
            />

            <Dialog title="Comparing Tiffs" show={showComparison} setShow={setComparison} body={
                <div className="relative flex w-screen h-screen">
                    <div className="w-1/2 p-4 text-center">
                        <span className="text-3xl">All data.</span>
                        <OpenLayerMapComponent layers={mapLayers}
                            tiffUrl={`/api/protected_tiffs/all_points_${selectedFarm.id}.1E-05.cog.tiff`}/>
                    </div>
                    <div className="w-1/2 p-4 text-center">
                        <span className="text-3xl">Filtered</span>
                        <OpenLayerMapComponent layers={mapLayersNew}
                            tiffUrl={`/api/protected_tiffs/${selectedFarm.id}.1E-05.cog.tiff`}/>
                    </div>
                </div>
            }/>

            <Dialog title={'New Season'}
                    show={seasonDialog}
                    setShow={setSeasonDialog}
                    body={
                        <div>
                            <EditTable discard={hideSeason}
                                       save={upsertSeason}
                                       saveWord={'Add'}>
                                {EditRow('Start Date: ', <DatePicker value={season.start} setValue={date => setSeason({
                                    ...season,
                                    start: date
                                })} type={DatePickerType.Date}/>)}
                                {EditRow('End Date: ', <DatePicker value={season.end}
                                                                   setValue={date => setSeason({
                                                                       ...season,
                                                                       end: date
                                                                   })}
                                                                   type={DatePickerType.Date}/>)}
                            </EditTable>
                        </div>
                    }/>
            <WarningPopup state={warningStateSeason} onYes={() => deleteSeason(warningStateSeason.state.data)}/>
            <WarningPopup state={warningStatePoints} onYes={() => removePoints(warningStatePoints.state.data)}/>
        </div>
    )
}

export default ManageFarms;
