import React, {useContext, useRef} from "react";
import PagedSearchTable, { PagedTableFunctions } from "../../components/PagedSearchTable";
import LabourController from "../../generated/controllers/labourController";
import LabourMetricsResponse from "../../generated/interfaces/labourMetricsResponse";
import { DatePicker, DatePickerType } from "../../components/DatePicker";
import ScissorController from "../../generated/controllers/scissorController";
import PaginationResponse from "../../generated/interfaces/paginationResponse";
import ScissorLandEntries from "../../generated/interfaces/scissorLandEntries";
import ScissorHourlyCount from "../../generated/interfaces/scissorHourlyCount";
import BatteryLevels from "../../generated/interfaces/batteryLevels";
import PaginationRequestSearch from "../../generated/interfaces/paginationRequestSearch";

import { Chart as ChartJS } from 'chart.js/auto';


import { trimArray } from "../../helper";
import {dateTimeOffset} from "../../date";
import Dialog from "../../components/Dialog";
import { ChartArea } from "chart.js";
import AppContext from "../appContext";
import Failed from "../../components/Failed";

let width: any, height: any, gradient: any;

function getGradient(context: CanvasRenderingContext2D, chartArea: ChartArea) {
    const chartWidth = chartArea.right - chartArea.left;
    const chartHeight = chartArea.bottom - chartArea.top;
    if (gradient === null || width !== chartWidth || height !== chartHeight) {
        // Create the gradient because this is either the first render
        // or the size of the chart has changed
        width = chartWidth;
        height = chartHeight;
        gradient = context.createLinearGradient(0, chartArea.bottom, 0, chartArea.top);
        gradient.addColorStop(1, 'green');
        gradient.addColorStop(0.5, 'yellow');
        gradient.addColorStop(0, 'red');
    }

    return gradient;
}

const Labours: React.FC = () => {

    const pagedTableRef = useRef<PagedTableFunctions<LabourMetricsResponse>>()
    const context = useContext(AppContext)
    const [labourDate, setLabourDate] = React.useState<Date>(new Date()); // labourArgs
    const [showDetailsModal, setShowDetailsModal] = React.useState(false);
    const [totalPicks, setTotalPicks] = React.useState(0);


    const hourlyChart = useRef<HTMLCanvasElement>(null)
    const batteryChart = useRef<HTMLCanvasElement>(null)
    const picksLandChart = useRef<HTMLCanvasElement>(null)



    function showPicksLand(data: ScissorLandEntries[]) {

        data= trimArray<ScissorLandEntries>(data, e => e.entries == null)

        let colors = data.map( e => `#${Math.floor(Math.random() * 16777215).toString(16)}`)
        let result = data.map((e, index) => ({
            label: e.landName,
            data: [e.entries],
            backgroundColor: colors[index],
            borderColor: colors[index],
            borderWidth: 1
        }))

        new ChartJS(picksLandChart.current!, {
            type: "bar",
            data: {
                labels: ["Lands"],
                datasets: result
            },
            options: {
                scales: {
                    "y":{
                            beginAtZero: true
                        }
                }
            }
        })

    }




    function showHourlyPick(data: ScissorHourlyCount[]) {
        data = trimArray<ScissorHourlyCount>(data, e => e.total == null)

        let labels = data.map(e =>
            new Date(e.date).toLocaleTimeString([], {
                hour: "2-digit",
                minute: "2-digit"
            }))
        new ChartJS(hourlyChart.current!, {
            type: 'line',
            data: {
                labels: labels,
                datasets: [{
                    label: "Picks per Hour",
                    data: data.map(e => e.total == null ? 0 : e.total),
                    backgroundColor: ["#666666"],
                    fill: true,
                    pointRadius: 0,
                    tension: 0.4
                }]
            },
            options: {
                scales: {
                    "y": {
                        beginAtZero: true,
                    }
                }
            }
        })
    }

    function showBatteries(data: BatteryLevels[]) {
        // trim head and tail with null
        data = trimArray<BatteryLevels>(data, e => e.battery == null)

        let levels = data.map(e => e.battery)
        let labels = data.map(e =>
            new Date(e.date).toLocaleTimeString([], {
                hour: "2-digit",
                minute: "2-digit"
            }))

        new ChartJS(batteryChart.current!, {
            type: 'line',
            data: {
                labels: labels,
                datasets: [{
                    label: "Battery on the hour",
                    data: levels,
                    pointRadius: 0,
                    borderColor: function (context) {
                        if (!context.chart.chartArea) {
                            // This case happen on initial chart load
                            return null;
                        }
                        return getGradient(context.chart.ctx, context.chart.chartArea);
                    }
                }]
            },
            options: {
                scales: {
                    "y": {
                        suggestedMin: 3200
                    }
                }
            }
        })
    }

    function showDetails(row: LabourMetricsResponse) {
        const hideLoader = context.showLoader()
        ScissorController.details({
            id: row.id,
            day: labourDate
        }).then(resp => {
            setShowDetailsModal(true)
            setTotalPicks(resp.landEntries.reduce((total, { entries }) => total + entries, 0))
            return resp
        }).then(resp => {
            showPicksLand(resp.landEntries);
            showHourlyPick(resp.hourly);
            showBatteries(resp.battery.entries)
        }).catch(() => {
            context.showSnack(<Failed title='Failed' text='Failed to open details'/>)
        }).finally(hideLoader)
    }

    function requestData(request: PaginationRequestSearch): Promise<PaginationResponse<LabourMetricsResponse>> {
        return LabourController.metrics({ ...request, day: labourDate })
    }

    function downloadRequestData(request: PaginationRequestSearch): void {
        LabourController.downloadMetrics({ ...request, all: true, day: labourDate })
    }

    function setNewLabourDate(date: Date) {
        pagedTableRef.current?.refresh();
    }

    return (
        <div>
            <div className="flex w-full bg-white py-1 items-center">
                <div className='max-w-[25%] border-primary-500 border-2 rounded-lg m-1 '>
                    <DatePicker setValue={setLabourDate}  value={labourDate}  type={DatePickerType.Date}/>
                </div>
                <div>
                    <button className="btn m-2 btn-primary" onClick={() => setNewLabourDate(labourDate)}>Ok</button>
                </div>
            </div>


            <PagedSearchTable
                componentRef={pagedTableRef}
                call={requestData}
                downloadExcelCall={downloadRequestData}
                columns={[
                    {
                        header: 'Actions',
                        key: 'actions',
                        row: item => <button className='btn-sm btn-primary'
                            onClick={() => showDetails(item)}>details</button>
                    },
                    {
                        header: 'Name',
                        key: 'name',
                        row: item => item.name
                    },
                    {
                        header: 'Picks',
                        key: 'count',
                        row: item => item.count
                    },
                    {
                        header: "Picks p/h",
                        key: 'picksPH',
                        row: (row) => (row.count / row.hoursWorked).toFixed(2)
                    },
                    {
                        header: 'Farm',
                        key: 'farm',
                        row: item => item.farmName
                    },
                    {
                        header: 'Start',
                        key: 'start',
                        row: item => dateTimeOffset(item.minTime, context.initial.timeOffset)
                    },
                    {
                        header: 'End',
                        key: 'end',
                        row: item => dateTimeOffset(item.maxTime, context.initial.timeOffset)
                    },
                    {
                        header: 'Min Battery',
                        key: 'minBattery',
                        row: item => item.minBattery
                    },
                    {
                        header: 'Max Battery',
                        key: 'maxBattery',
                        row: item => item.maxBattery
                    },
                ]}
            />
            <Dialog title="Labourer Details"
                show={showDetailsModal}
                setShow={setShowDetailsModal}
                body={
                    <div className='p-2'>
                            <div className="text-xl p-2 rounded bg-gray-200 inline-block">
                                    Total Picks: {totalPicks}
                            </div>
                                <div className="flex flex-wrap justify-evenly items-center">
                                    <div className="text-l p-2 text-gray-800 text-center">
                                        Total picks per land
                                        <canvas ref={picksLandChart} />
                                    </div>
                                    <div className="text-l p-2 text-gray-800 text-center">
                                        Total picks over hours
                                        <canvas ref={hourlyChart} />
                                    </div>
                                </div>
                                <div className="text-l text-gray-800 text-center">
                                    <div className='text-lg'>Battery level</div>
                                    <canvas ref={batteryChart} />
                                </div>
                    </div>
                }
            />
        </div>
    )
}

export default Labours
