import React, {useEffect, useRef} from "react";

type mapSettable = {
    setMap(map: google.maps.Map | null): void;
}

const GoogleMaps: React.FC<{
    layers?: mapSettable[];
    initialize?: (maps: google.maps.Map) => void;
    fullscreen?: boolean;
    mapOptions?: google.maps.MapOptions;
}> = (props) => {

    const mapRef = useRef<HTMLDivElement>(null);
    const mapObj = useRef<google.maps.Map>();
    
    async function initialize() {
        //@ts-ignore
        const { Map } = await google.maps.importLibrary("maps");
        const map = new Map(mapRef.current!, {
            ...props.mapOptions
        });
        mapObj.current = map;
        (props.layers ?? []).forEach(layer => layer.setMap(map));
        props.initialize?.(map);
    }
    
    useEffect(() => {
        if (!mapObj.current || !props.mapOptions) {
            return;
        }
        mapObj.current?.setOptions(props.mapOptions);
    }, [props.mapOptions])

    useEffect(() => {
        initialize().then();
    }, []);

    return <div style={props.fullscreen ? {
        width: '100%',
        height: '100%'
    } : {
        width: '800px',
        height: '800px'
    }} ref={mapRef}/>
}


export default GoogleMaps;