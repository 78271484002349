import React, {useContext, useEffect, useState} from "react";
import AppContext from "./appContext";
import AuthController from "../generated/controllers/authController";
import {useValidation} from "../validation";
import Failed from "../components/Failed";
import Reset from "../components/Reset";

function extractQueryParams(): Record<string, string> {
    const pl = /\+/g; // Regex for replacing addition symbol with a space
    const search = /([^&=]+)=?([^&]*)/g;
    const decode = (s: string) => decodeURIComponent(s.replace(pl, " "));
    const query = window.location.search.substring(1);

    const urlParams: Record<string, string> = {};
    let match: RegExpExecArray | null;
    while ((match = search.exec(query))) {
        urlParams[decode(match[1] ?? '')] = decode(match[2] ?? '');
    }

    return urlParams;
}

const Login: React.FC = () => {
    let context = useContext(AppContext);
    var queryParams = extractQueryParams()

    const [user, setUser] = useState('')
    const [password, setPassword] = useState('')
    const [showReset, setShowReset] = useState(false)
    const [token, setToken] = useState<string>('')
    const [errorMessage, setErrorMessage] = useState('')

    const validation = useValidation({
        username: () => user.length > 0,
        password: () => password.length > 0
    })

    useEffect(() => {
         if (context.initial.authenticated) {
             window.location.href = '/'
         }
         const token = queryParams['token']
         if (token && token.length > 0) {
            setToken(token)
            setShowReset(true)
        }
    }, [])

    function login() {
        if (!validation.validate()) return

        const hideLoader = context.showLoader()

        AuthController.login({
            user: user,
            pass: password,
        }).then(resp => {
            if (resp.success) {
                // Not just a Vue router change, but whole new home page
                window.location.href = '/'
            }else {
                setErrorMessage(resp.message)
                hideLoader()
            }
        })
        .catch(e => {
            context.showSnack(<Failed title='Failed' text='Login failed'/>)
            hideLoader()
        })
    }

    return (
        <div>
            <div className="flex h-screen items-center justify-center loginBackground" onKeyDown={e => e.key == "Enter" ? login() : null}>
                <div className="mx-auto p-8 bg-white rounded-lg border-gray-600 border-t-4 text-center shadow-xl z-10">
                    <img className="inline bg-black rounded-full border-4 border-black" src="/images/pick.png"/>
                    <div className="text-lg uppercase">PickLogger</div>
                    <div className='flex'>
                        <div className="text-xs text-gray-800 p-1 text-left">Username</div>
                        {
                            !validation.rules.username ? <div className="text-xs text-red-500 py-1 text-left">(Username required)</div> : null
                        }
                    </div>
                    <input className="p-1 text-left bg-gray-200 border rounded" type="text" value={user} onChange={v => setUser(v.target.value)} />
                    <div className='flex'>
                        <div className="text-xs text-gray-800 p-1 text-left">Password</div>
                        {
                            !validation.rules.password ? <div className="text-xs text-red-500 py-1 text-left">(Password required)</div> : null
                        }
                    </div>
                    <input className="p-1 text-left bg-gray-200 border rounded" type="password" value={password} onChange={v => setPassword(v.target.value)} />
                    <div className="bg-orange-500 my-2 rounded py-1 text-white cursor-pointer shadow-lg" onClick={login} >Login</div>
                    <div className="w-48 text-sm text-red-500">{errorMessage}</div>

                    <div className="flex items-center flex-row-reverse justify-center">
                        <Reset show={showReset} setShow={setShowReset} token={token}/>
                    </div>
                </div>
            </div>
            <div className='fixed bottom-0 z-50 w-full bg-gray-800 px-10 text-white text-center text-xs'>
                Agritechnovation PickLogger v.{context.initial.version}
            </div>
        </div>
    )
}

export default Login;
