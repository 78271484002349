import React, { useState } from 'react'
import Dialog from "./Dialog";

interface WarningInternalState<T> {
    show: boolean;
    title: string;
    data: T;
}

interface WarningState<T> {
    show: (title: string, data: T) => void;
    hide: () => void;
    state: WarningInternalState<T>;
}

export function useWarningState<T> (empty: T): WarningState<T> {
    const [state, setState] = useState<WarningInternalState<T>>({
        show: false,
        title: '',
        data: empty
    })

    function show (title: string, data: T) {
        setState({
            show: true,
            title,
            data
        })
    }

    function hide () {
        setState({
            show: false,
            title: '',
            data: empty
        })
    }

    return {
        show,
        hide,
        state
    }
}

interface WarningProps<T> {
    state: WarningState<T>;
    onYes: (data: T) => void;
    onNo?: (data: T) => void;
}

const WarningPopup = <T, >(props: WarningProps<T>) => {
    const state = props.state.state
    return <Dialog title={state.title} show={state.show} setShow={() => props.state.hide()} body={<div className="flex w-full m-2">
        <div className="m-2">
            {state.title}
        </div>
        <div className="text-white text-right">
            <button className="bg-primary py-1 px-3 m-1 text-white rounded" onClick={() => {
                props.onYes(state.data)
                props.state.hide()
            }}>
                Yes
            </button>
            <button className="bg-error-500 py-1 px-3 m-1 text-white rounded" onClick={() => {
                props.onNo?.(state.data)
                props.state.hide()
            }}>
                No
            </button>
        </div>
    </div>}/>
}

export default WarningPopup
