import React, { PropsWithChildren, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { classNames } from '../wrapper'
import AppContext from "../pages/appContext";

const AutoComplete = <TOptions, > (props: PropsWithChildren<{
    options: TOptions[];
    textFunc: (item: TOptions) => string;
    valueFunc: (item: TOptions) => number;
    value: number;
    onChange: (value: number, option: TOptions) => void;
    error?: boolean;
}>) => {
    return <AutoCompleteNullable
        value={props.value}
        onChange={(value, option) => {
            if (value && option) {
                props.onChange(value, option)
                return
            }

            // take the first valid option if any
            const first = props.options[0]
            if (first) {
                props.onChange(props.valueFunc(first), first)
            }
        }}
        fallbackPrev={true}
        options={props.options}
        valueFunc={props.valueFunc}
        textFunc={props.textFunc}
    />
}

interface OptionItem<TOption> {
    text: string;
    value: number;
    original: TOption;
}

function handleMove (event: React.KeyboardEvent<HTMLInputElement>, index: number, min: number, max: number): number {
    switch (event.key) {
        case 'ArrowUp':
            event.preventDefault()
            if (index <= 0) {
                return max
            }
            return index - 1

        case 'ArrowDown':
            event.preventDefault()
            if (index < max) {
                return index + 1
            }
            return 0
    }
    return index
}

// unique count for each autocomplete component
let fileId = 0

function findAndSet<TOptions>(options: OptionItem<TOptions>[], props: { options: TOptions[]; textFunc: (item: TOptions) => string; valueFunc: (item: TOptions) => number; value: number | null; onChange: (value: (number | null), option: (TOptions | null)) => void; error?: boolean; placeHolderKey?: string } & { children?: React.ReactNode | undefined }, setText: (value: (((prevState: string) => string) | string)) => void) {
    const found = options.find(o => o.value === props.value)
    if (found) {
        setText(found.text)
        return
    }
}

const AutoCompleteNullable = <TOptions, > (props: PropsWithChildren<{
    options: TOptions[];
    textFunc: (item: TOptions) => string;
    valueFunc: (item: TOptions) => number;

    value: number | null;
    onChange: (value: number | null, option: TOptions | null) => void;
    error?: boolean;
    fallbackPrev?: boolean;
    placeHolderKey?: string;
}>) => {
    const context = useContext(AppContext)
    const [index, setIndex] = useState<number>(0)
    // current text as displayed in the input field
    const [text, setText] = useState<string>('')
    // filter on search
    const [search, setSearch] = useState<string>('')
    const [show, setShow] = useState(false)
    const ulRef = useRef<HTMLUListElement>(null)
    const inputRef = useRef<HTMLInputElement>(null)
    const id = useRef('AutoComplete' + fileId++)

    const options = useMemo(() => props.options.map<OptionItem<TOptions>>(o => ({
        text: props.textFunc(o),
        value: props.valueFunc(o),
        original: o
    })), [props.options])

    const filtered = useMemo(() => options.filter(f => f.text.toLowerCase().includes(search.toLowerCase())), [search, options])

    useEffect(() => {
        // make sure index is still in bounds
        if (index >= filtered.length) {
            setIndex(filtered.length - 1)
        }
    }, [filtered])

    useEffect(() => {
        findAndSet(options, props, setText);
    }, [props.value, props.options])

    function setValue (option: OptionItem<TOptions> | null) {
        if (option === null) {
            setText('')
            props.onChange(null, null)
            return
        }
        setText(option.text)
        props.onChange(option.value, option.original)
    }

    function onBlur() {
        setShow(false)
        // update text to previous value
        if (search || props.fallbackPrev) {
            findAndSet(options, props, setText);
        } else {
            setValue(null)
        }
    }

    function inputKeyDown (event: React.KeyboardEvent<HTMLInputElement>) {
        const newIndex = handleMove(event, index, 0, filtered.length - 1)
        setIndex(newIndex)

        const el = ulRef.current?.children[newIndex]
        if (el) {
            el.scrollIntoView(false)
        }

        switch (event.key) {
            case 'Enter':
                // Select current highlighted item
                if (filtered[index]) {
                    setValue(filtered[index]!)
                    setShow(false)
                }
                break
            case 'Escape':
                onBlur()
                break
            default:
                setShow(true)
        }
    }

    return (
        <div className="relative">
            <input
                ref={inputRef}
                list={id.current}
                className={classNames('peer input', props.error ? 'border-error-300' : '')}
                placeholder={'Click select option'}
                value={text}
                onKeyDown={inputKeyDown}
                onClick={() => {
                    setShow(true)
                    inputRef.current?.select()
                }}
                onFocus={(e) => {
                    const val = (e.target as HTMLInputElement).value
                    setShow(true)
                    setSearch(val)
                    setText(val)
                    inputRef.current?.select()
                }}
                onInput={e => {
                    const val = (e.target as HTMLInputElement).value
                    setSearch(val)
                    setText(val)

                    // option tag does not respect whitespaces before and after
                    const value = options.find(l => l.text.trim() === e.currentTarget.value)
                    if (value) {
                        setValue(value)
                    }
                }}
                onBlur={() => onBlur()}
            />


            {show
                ? <div className="absolute min-w-[200px] max-w-[300px] hidden peer-focus:block text-left text-sm bg-white mt-1 border rounded max-h-[350px] overflow-y-scroll z-20">
                    <datalist id={id.current}>
                        {filtered.map((l, i) => <option value={l.text} className="z-50" key={i}/>)}
                    </datalist>
                </div>
                : null}

        </div>
    )
}

export { AutoComplete, AutoCompleteNullable }
