/**
 * This file was generated by Itecho.TSGen tool
 * Version and info generated in .version
 * Any changes to this file will be lost if regenerated 
 */

enum PortStatus {
    Complete = "Complete",
    Busy = "Busy",
    Waiting = "Waiting"
}
export default PortStatus