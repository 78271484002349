/**
 * This file was generated by Itecho.TSGen tool
 * Version and info generated in .version
 * Any changes to this file will be lost if regenerated 
 */

enum PermissionEnum {
    Management = "Management",
    Management_RolesPermissions = "Management_RolesPermissions",
    Management_ClientsPermissions = "Management_ClientsPermissions",
    Management_FarmsPermissions = "Management_FarmsPermissions",
    Management_StationsPermissions = "Management_StationsPermissions",
    Management_UsersPermissions = "Management_UsersPermissions",
    Management_ScissorsPermissions = "Management_ScissorsPermissions",
    Management_ProviderPermissions = "Management_ProviderPermissions",
    Dashboard_FarmsPermissions = "Dashboard_FarmsPermissions",
    Management_HandheldsPermissions = "Management_HandheldsPermissions",
    Dashboard_LabourPermissions = "Dashboard_LabourPermissions",
    Management_Scanners = "Management_Scanners",
    Dashboard_BinsPermissions = "Dashboard_BinsPermissions",
    Dashboard_Stations = "Dashboard_Stations",
    Management_Seasons = "Management_Seasons",
    Management_Entries = "Management_Entries",
    Dashboard_Scissors = "Dashboard_Scissors",
    Dashboard_MicroManagement = "Dashboard_MicroManagement"
}
export default PermissionEnum