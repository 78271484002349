import RoleController from "../generated/controllers/roleController";
import PagedSearchTable, { PagedTableFunctions } from "../components/PagedSearchTable";
import React, { Fragment, useContext, useMemo, useRef, useState } from "react";
import RoleDisplay from "../generated/interfaces/roleDisplay";
import Success from "../components/Success";
import PermissionEnum from "../generated/interfaces/permissionEnum";
import Failed from "../components/Failed";
import Dialog from "../components/Dialog";
import { EditRow, EditTable } from "../components/Fields";
import Input from "../components/Input";
import CheckBox from "../components/CheckBox";
import AppContext from "../pages/appContext";
import { recordToArray } from "../array";
import { useValidation } from "../validation";
import WarningPopup, { useWarningState } from "../components/WarningPopup";

const emptyRoleDisplay = (): RoleDisplay => {
    return {
        name: '',
        permissions: [],
        count: 0,
        id: null
    }
}

function permissionName(p: PermissionEnum): string {
    switch (p)
    {
        case PermissionEnum.Management:
            return 'All Manage';
        case PermissionEnum.Management_RolesPermissions:
            return 'Roles';
        case PermissionEnum.Management_ClientsPermissions:
            return 'Clients';
        case PermissionEnum.Management_FarmsPermissions:
            return 'Farms';
        case PermissionEnum.Management_StationsPermissions:
            return 'Stations';
        case PermissionEnum.Management_UsersPermissions:
            return 'Users';
        case PermissionEnum.Management_ScissorsPermissions:
            return 'Scissors';
        case PermissionEnum.Management_ProviderPermissions:
            return 'Provider';
        case PermissionEnum.Dashboard_FarmsPermissions:
            return 'Farms';
        case PermissionEnum.Management_HandheldsPermissions:
            return 'Handhelds';
        case PermissionEnum.Dashboard_LabourPermissions:
            return 'Labour';
        case PermissionEnum.Management_Scanners:
            return 'Scanners';
        case PermissionEnum.Dashboard_BinsPermissions:
            return 'Bins';
        case PermissionEnum.Dashboard_Stations:
            return 'Stations';
        case PermissionEnum.Dashboard_Scissors:
            return 'Scissors';
        case PermissionEnum.Dashboard_MicroManagement:
            return 'Micro Management';
        case PermissionEnum.Management_Seasons:
            return 'Seasons';
        case PermissionEnum.Management_Entries:
            return 'Entries';
    }
}

interface PermissionGroup {
    group: string;
    items: PermissionEnum[]
}


const Roles: React.FC = () => {
    const context = useContext(AppContext)
    const pagedTableRef = useRef<PagedTableFunctions<RoleDisplay>>()
    const warningState = useWarningState<number>(-1)
    const [roleModal, setRoleModal] = useState(false)
    const [selected, setSelected] = useState<RoleDisplay>(emptyRoleDisplay)

    const groups = useMemo(() => {
        return recordToArray(context.initial.availablePermissions, (key, items) => ({
            group: key,
            items: items
        }) as PermissionGroup);
    }, [])

    const validation = useValidation({
        name:() => selected.name.length > 0
    })

    function addPermission(permission: PermissionEnum) {
        setSelected({ ...selected, permissions: [...selected.permissions, permission] })
    }

    function removePermission(permission: PermissionEnum) {
        setSelected({...selected,permissions: selected.permissions.filter(p => p !== permission)})
    }

    function newRole() {
        setSelected(emptyRoleDisplay)
        setRoleModal(true)
    }
    function upsertKeyText() {
        if (!validation.validate()) return

        const hideLoader = context.showLoader()
        RoleController.upsert({
            id: selected.id,
            name: selected.name,
            permissions: selected.permissions
        }).then(resp => {
            context.showSnack(<Success title='Success' text='success'/>)
            setRoleModal(false)
            pagedTableRef.current?.refresh()
        }).catch(() => {

        }).then(() => hideLoader())
    }
    function delRole(id: number | null) {
        if (id == null) return
        RoleController.delete({ id: id }).then(resp => {
            if (resp) {
                context.showSnack(<Success title='Success' text='success' />)
                pagedTableRef.current?.refresh()
            }
            else {
                context.showSnack(<Failed title='Could not delete role. Make sure the role is removed from all users.' text='Error' />)
            }
        })

    }

    return (
        <div>
            <button className="btn btn-primary" onClick={() =>  newRole() }>Add Role</button>
            <PagedSearchTable call={RoleController.paged}
                              componentRef={pagedTableRef}
                              columns={[{
                                  header: "Actions",
                                    row: (row) => {
                                        return <div>
                                            <button className="btn mr-1 btn-sm btn-primary" onClick={() => { setSelected(row), setRoleModal(true) }}>Edit</button>
                                            {
                                                row.id !== null && row.count == 0 ?
                                                    <button className="btn btn-sm btn-error" onClick={() =>
                                                        warningState.show("Are you sure you want to delete this Role?", row.id!)
                                                    }>Delete</button> : null
                                            }
                                        </div>
                                    }
                                }, {
                                    header: "Name",
                                    row: (row) => row.name
                                }, {
                                    header: "Users",
                                    row: (row) => row.count
                                }]}/>
            <Dialog title="Role" setShow={setRoleModal} show={roleModal} body={
                <EditTable
                    discard={() => { setRoleModal(false), setSelected(emptyRoleDisplay) }}
                    save={() => upsertKeyText()}
                    saveWord={selected.id ?'update' :'add'}>

                    {EditRow("Role Name",<Input value={selected.name} change={v => setSelected({ ...selected, name: v })} />,validation.rules.name, "Name is required")}

                    {groups.map(p =>
                    {return <Fragment key={p.group} >
                        <tr>
                            <td colSpan={2}>
                                <div className="border-0 rounded-t-lg my-2 border-primary text-m">
                                    <div className="bg-primary-600 rounded-md text-white p-2">{p.group}</div>
                                </div>
                            </td>
                        </tr>

                        {p.items.map(p =>
                            <tr className='w-full' key={p}>
                                <td>{permissionName(p)}:</td>

                                <td className='text-center'>
                                    <CheckBox checked={selected.permissions.includes(p)} onChange={v => v ? addPermission(PermissionEnum[p]) : removePermission(PermissionEnum[p])}></CheckBox>
                                </td>
                            </tr>
                         )}
                    </Fragment>
                    })}

                </EditTable>

            }
            />
            <WarningPopup state={warningState} onYes={() =>  delRole(warningState.state.data)}/>
        </div>
    )
}

export default Roles;


