/* eslint-disable */
/**
 * This file was generated by Itecho.TSGen tool
 * Version and info generated in .version
 * Any changes to this file will be lost if regenerated 
 */

import { webRequest, fileRequest } from "../request";
import type LabourMetricsResponse from "../interfaces/labourMetricsResponse";
import type PaginationRequestDate from "../interfaces/paginationRequestDate";
import type PaginationResponse from "../interfaces/paginationResponse";

export default {
    metrics: (request: PaginationRequestDate) => webRequest<PaginationResponse<LabourMetricsResponse>>('/labour/metrics', {
        method: 'post',
        body: request
    }),
    downloadMetrics: (request: PaginationRequestDate) => fileRequest('/labour/downloadmetrics', {
        queryParams: {
            ...request
        }
    })
}