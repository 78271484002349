import React from "react";

const PickloggerLoader: React.FC = () => {

    return (
        <div className="flex z-50 lightoverlay h-full justify-center items-center">
            <div className="mx-auto p-4 rounded-full">
                <div className="breeding-rhombus-spinner">
                    <div className="rhombus child-1 bg-primary-500"></div>
                    <div className="rhombus child-2 bg-primary-500"></div>
                    <div className="rhombus child-3 bg-primary-500"></div>
                    <div className="rhombus child-4 bg-primary-500"></div>
                    <div className="rhombus child-5 bg-primary-500"></div>
                    <div className="rhombus child-6 bg-primary-500"></div>
                    <div className="rhombus child-7 bg-primary-500"></div>
                    <div className="rhombus child-8 bg-primary-500"></div>
                    <div className="rhombus big bg-primary-500"></div>
                </div>
            </div>
        </div>
    )
}

export default PickloggerLoader;
