import React, {useRef, useEffect} from 'react';
import {Map, View} from 'ol';
import {Vector as VectorLayer} from 'ol/layer';
import TileLayer from 'ol/layer/WebGLTile';
import {BingMaps, GeoTIFF, Vector as VectorSource} from 'ol/source';
import 'ol/ol.css';

import {Geometry} from "ol/geom";

const bingMapsApiKey = 'AiOMV-hxMqI-4_52F5358Zbw4wkutOfkKMRcpH6BDG-kRKRM58KS_P84CgQ_KsUU';

const OpenLayerMapComponent: React.FC<{
    layers?: VectorLayer<VectorSource<Geometry>>[];
    tiffUrl?: string;
}> = (props) => {
    const mapRef = useRef(null);

    function loadMap(sources: VectorLayer<VectorSource<Geometry>>[]) {
        if (!mapRef.current) return;

        const tiffLayer = new TileLayer({
            source: new GeoTIFF({
                normalize: false,
                sources: [{
                    url: props.tiffUrl,
                    nodata: 0,
                    bands: [1]
                }],
            })
        })

        tiffLayer.setStyle({
            color: ['interpolate', ['linear'], ['band', 1],
                0, [0, 0, 0, 0],
                9, [184, 17, 2],
                14, [233, 92, 59],
                18, [250, 160, 90],
                21, [254, 196, 70],
                25, [255, 225, 36],
                25, [255, 253, 2],
                32, [216, 250, 0],
                36, [174, 243, 0],
                41, [131, 237, 0],
                46, [88, 230, 0],
                51, [46, 224, 0],
                57, [3, 217, 0],
                66, [11, 163, 64],
                76, [19, 109, 128],
                93, [30, 65, 217],
            ]
        })

        // Create a new OpenLayers map
        const map = new Map({
            target: mapRef.current,
            layers: [
                new TileLayer({
                    source: new BingMaps({
                        key: bingMapsApiKey,
                        imagerySet: 'Aerial',
                        placeholderTiles: false
                    }),
                }),
                tiffLayer,
                ...sources,
            ],

            view: new View(),
        });

        const extent = sources[0]?.getSource()?.getExtent();
        if (extent) {
            map.getView().fit(extent);
            map.getView().setZoom((map.getView()?.getZoom() ?? 5) - 1);
        }
    }

    useEffect(() => {
        loadMap(props.layers ?? [])
    }, [])

    return (
        <div ref={mapRef} className="w-full h-full"></div>
    );
};

export default OpenLayerMapComponent;
