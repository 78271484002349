/* eslint-disable */
/**
 * This file was generated by Itecho.TSGen tool
 * Version and info generated in .version
 * Any changes to this file will be lost if regenerated 
 */

import { webRequest, fileRequest } from "../request";
import type DashboardFarmDisplay from "../interfaces/dashboardFarmDisplay";
import type DashboardLandDisplay from "../interfaces/dashboardLandDisplay";
import type FarmDisplay from "../interfaces/farmDisplay";
import type FarmLandGeojson from "../interfaces/farmLandGeojson";
import type IdRequest from "../interfaces/idRequest";
import type LandBoundaryCoordinates from "../interfaces/landBoundaryCoordinates";
import type LandWeightRequest from "../interfaces/landWeightRequest";
import type LocationDataGrouped from "../interfaces/locationDataGrouped";
import type LocationSeasonRequest from "../interfaces/locationSeasonRequest";
import type PaginationRequestCustomFarm from "../interfaces/paginationRequestCustomFarm";
import type PaginationRequestIdSearch from "../interfaces/paginationRequestIdSearch";
import type PaginationRequestSearch from "../interfaces/paginationRequestSearch";
import type PaginationResponse from "../interfaces/paginationResponse";
import type WeightsPaged from "../interfaces/weightsPaged";

export default {
    paged: (request: PaginationRequestSearch) => webRequest<PaginationResponse<FarmDisplay>>('/farm/paged', {
        method: 'post',
        body: request
    }),
    update: (farm: FarmDisplay) => webRequest<unknown>('/farm/update', {
        method: 'post',
        body: farm
    }),
    landsPaged: (request: PaginationRequestCustomFarm) => webRequest<PaginationResponse<DashboardLandDisplay>>('/farm/landspaged', {
        method: 'post',
        body: request
    }),
    processFarm: (request: IdRequest) => webRequest<unknown>('/farm/processfarm', {
        method: 'post',
        body: request
    }),
    sync: (request: IdRequest) => webRequest<unknown>('/farm/sync', {
        method: 'post',
        body: request
    }),
    removePoints: (request: IdRequest) => webRequest<unknown>('/farm/removepoints', {
        method: 'post',
        body: request
    }),
    weightsPaged: (request: PaginationRequestIdSearch) => webRequest<PaginationResponse<WeightsPaged>>('/farm/weightspaged', {
        method: 'post',
        body: request
    }),
    deleteWeight: (request: IdRequest) => webRequest<unknown>('/farm/deleteweight', {
        method: 'post',
        body: request
    }),
    locationData: (request: LocationSeasonRequest) => webRequest<LocationDataGrouped[]>('/farm/locationdata', {
        method: 'post',
        body: request
    }),
    addLandWeight: (request: LandWeightRequest) => webRequest<unknown>('/farm/addlandweight', {
        method: 'post',
        body: request
    }),
    downloadLocationDataGroupedDay: (request: LocationSeasonRequest) => fileRequest('/farm/downloadlocationdatagroupedday', {
        queryParams: {
            ...request
        }
    }),
    downloadLocationData: (request: LocationSeasonRequest) => fileRequest('/farm/downloadlocationdata', {
        queryParams: {
            ...request
        }
    }),
    available: (request: PaginationRequestSearch) => webRequest<PaginationResponse<DashboardFarmDisplay>>('/farm/available', {
        method: 'post',
        body: request
    }),
    landBoundary: (id: number) => webRequest<LandBoundaryCoordinates[]>('/farm/landboundary', {
        queryParams: {
            id
        }
    }),
    geoJson: (id: number) => webRequest<FarmLandGeojson>('/farm/geojson', {
        queryParams: {
            id
        }
    })
}