import React, {useState} from "react";
import FarmController from "../../generated/controllers/farmController";
import PagedSearchTable, {PagedTableFunctions} from "../../components/PagedSearchTable";
import DashboardFarmDisplay from "../../generated/interfaces/dashboardFarmDisplay";
import {dateFormat} from "../../date";
import Dialog from "../../components/Dialog";
import {DatePicker, DatePickerType} from "../../components/DatePicker";
import ScissorController from "../../generated/controllers/scissorController";
import {createSearchParams, useNavigate} from "react-router-dom";
import {EditTable} from "../../components/Fields";
import ScissorAudit from "../../generated/interfaces/scissorAudit";
import StationController from "../../generated/controllers/stationController";
import {last} from "../../helper";
import StationDisplay from "../../generated/interfaces/stationDisplay";

const emptyDashboardFarmDisplay: DashboardFarmDisplay = {
    id: 0,
    mfwId: 0,
    client: '',
    name: '',
    boundaryUID: '',
    pickCounts: 0,
    lastUpdated: null,
    start: new Date(),
    end: new Date(),
    shapeFileGenerated: null
}

function imageByHour(hour: number): string {
    if (hour < 24)
        return '/images/green.png'
    if (hour < 72)
        return '/images/yellow.png';

    return '/images/yellow.png';
}

function diffInHours(date1: Date, date2: Date | undefined | null) {
    if (!date2) {
        return ''
    }
    const diffInMs = Math.abs(date1.getTime() - date2.getTime());
    const hours = Math.round(diffInMs / (1000 * 60 * 60));

    return <div className='flex items-center'>
        <img className='h-4 w-4' src={imageByHour(hours)} alt=''/>
        <span className='inline-block lowercase'>({hours} hour(s) ago)</span>
    </div>
}

const MicroManagement: React.FC = () => {
    const pagedTableRef = React.useRef<PagedTableFunctions<DashboardFarmDisplay>>()
    const pagedTableRef2 = React.useRef<PagedTableFunctions<StationDisplay>>()
    const pagedTableRef3 = React.useRef<PagedTableFunctions<ScissorAudit>>()
    const [show, setShow] = useState(false)
    const [farmId, setFarmId] = useState<number>(0)
    const [farm, setFarm] = useState<DashboardFarmDisplay>(emptyDashboardFarmDisplay)
    const [fromDate, setFromDate] = React.useState<Date>(new Date());
    const [toDate, setToDate] = React.useState<Date>(new Date());

    const navigate = useNavigate();

    function overview(item: DashboardFarmDisplay) {
        setFarmId(item.id)
        setShow(true)
    }

    function setNewDate(startDate: Date, endDate: Date) {

        navigate({
            pathname: '/microStations',
            search: createSearchParams({
                farmId: farmId.toString(),
                from: dateFormat(startDate),
                to: dateFormat(endDate)
            }).toString()
        });
    }


    return (
        <div>
            <PagedSearchTable
                componentRef={pagedTableRef}
                call={FarmController.available}
                columns={[{
                    header: 'Farm Name',
                    key: 'farmName',
                    row: (item) => item.name
                }, {
                    header: 'Client',
                    key: 'client',
                    row: (item) => item.client
                }, {
                    header: <div className="text-right">Actions</div>,
                    key: 'actions',
                    row: (item) =>
                        <div className="flex justify-end">
                            <div className="btn-sm btn-primary mx-2" onClick={() => overview(item)}>overview</div>
                        </div>
                }
                ]}/>

            <PagedSearchTable
                componentRef={pagedTableRef2}
                call={StationController.paged}
                columns={[
                    {
                        header: 'UID',
                        key: 'uid',
                        row: row => last(row.uid, 10)
                    },
                    {
                        header: 'Last Point Date',
                        key: 'lastCommunicated',
                        row: row => <div className='flex items-center'>
                            <div className='pr-3 min-w-[100px]'>{dateFormat(row.lastCommunication)}</div>
                            <div className=''>{diffInHours(new Date(), row.lastCommunication)}</div>
                        </div>
                    }
                ]}/>

            <PagedSearchTable
                componentRef={pagedTableRef3}
                call={ScissorController.audit}
                columns={[
                    {
                        header: 'Scissor Name',
                        key: 'scissorName',
                        row: row => row.name
                    },
                    {
                        header: 'Audit Trail',
                        key: 'auditTrial',
                        row: row =>
                            <div className="flex flex-col">
                                {row.clients.map((ret, index) =>
                                    <div className="flex justify-center items-center flex-col" key={index}>
                                        {ret}
                                        {index === row.clients.length - 1
                                            ? null
                                            : <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                                   strokeWidth="1.5" stroke="currentColor" className="w-4 h-4">
                                                <path strokeLinecap="round" strokeLinejoin="round"
                                                      d="M12 4.5v15m0 0l6.75-6.75M12 19.5l-6.75-6.75"/>
                                            </svg>}
                                    </div>
                                )}
                            </div>
                    }
                ]}/>

            <Dialog title="Overview" setShow={setShow} show={show} body={
                <EditTable
                    discard={() => {
                        setShow(false);
                        setFromDate(new Date());
                        setToDate(new Date());
                    }}
                    save={() => setNewDate(fromDate, toDate)}
                    saveWord={'set'}>

                    <tr>
                        <td>
                                Start Date:
                        </td>
                        <td>
                            <div>
                                <DatePicker setValue={setFromDate} value={fromDate} type={DatePickerType.Date}/>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            End Date:
                        </td>
                        <td>
                            <DatePicker setValue={setToDate} value={toDate} type={DatePickerType.Date}/>
                        </td>
                    </tr>
                </EditTable>}
            />

        </div>)
}
export default MicroManagement;
