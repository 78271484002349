import React, {useEffect, useRef, useState} from "react";
import PagedSearchTable, { PagedTableFunctions } from "../../components/PagedSearchTable";
import {DatePicker, DatePickerType} from "../../components/DatePicker";
import BinController from "../../generated/controllers/binController";
import BinMetricsResponse from "../../generated/interfaces/binMetricsResponse";
import PaginationRequestSearch from "../../generated/interfaces/paginationRequestSearch";
import PaginationResponse from "../../generated/interfaces/paginationResponse";

const Bins: React.FC = () => {
    const [binDate, setBinDate] = useState(new Date())
    const pagedTableRef = useRef<PagedTableFunctions<BinMetricsResponse>>()

    function requestData(request: PaginationRequestSearch): Promise<PaginationResponse<BinMetricsResponse>> {
        return BinController.binEntries({ ...request, day: binDate })
    }

    function setNewBinDate(date: Date) {
        pagedTableRef.current?.refresh();
    }

    return (
        <div>
            <div className="flex w-full bg-white py-1 items-center">
                <div className='max-w-[15%] border-primary-500 border-2 rounded-lg m-1'>
                    <DatePicker  setValue={setBinDate} value={binDate} key="date-pick-bin" type={DatePickerType.Date}/>
                </div>
                <div>
                    <button className="btn m-2 btn-primary" onClick={() => setNewBinDate(binDate)}>Ok</button>
                </div>
            </div>

            <PagedSearchTable
                call={requestData}
                downloadExcelCall={(resp) => BinController.downloadBinEntries({...resp, day: binDate})}
                componentRef={pagedTableRef}
                columns={[{
                    header: 'UHF Tag',
                    key: "uhf",
                    row: (item) => item.binUHFTag
                }, {
                    header: 'Timestamp',
                    key: 'timeStamp',
                    row: (item) => item.timeStamp
                }, {
                    header: 'Weight',
                    key: 'weight',
                    row: (item) => item.weight
                }
                ]}></PagedSearchTable>
        </div>
    )
}

export default Bins
