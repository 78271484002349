import React, {useRef, useState} from "react";
import PagedSearchTable, { PagedTableFunctions } from "../components/PagedSearchTable";
import HandheldController from "../generated/controllers/handheldController";
import HandheldDisplay from "../generated/interfaces/handheldDisplay";
import Dialog from "../components/Dialog";
import FieldEntries from "./FieldEntries";


const Handhelds:React.FC = () => {
    const pagedTableRef = useRef<PagedTableFunctions<HandheldDisplay>>()
    const [showEntries, setShowEntries] = useState(false)
    const [entryId, setEntryId] = useState<number>()
    const [editName, setEditName] = useState<{id: number, original: string}[]>([])



    function viewEntries(id: number) {
        setEntryId(id)
        setShowEntries(true)
    }

    function cancelEdit(row: HandheldDisplay) {
        const originalName = editName.find(r => r.id == row.id)?.original ?? ''
        pagedTableRef.current?.updateRow(pred => pred.id == row.id, (r) => r.name = originalName)
        setEditName(editName.filter(p => p.id !== row.id))
    }

    function updateHandheld(row: HandheldDisplay) {
        HandheldController.update({
            id: row.id,
            name: row.name
        }).then(resp => {
            setEditName(editName.filter(p => p.id !== row.id));
        })
    }

    function updateRowName(event: React.ChangeEvent<HTMLInputElement>, row: HandheldDisplay): void {
        pagedTableRef.current?.updateRow(pred => pred.id == row.id, (r) => r.name = event.target.value)
    }


    return (
        <div>
            <PagedSearchTable call={HandheldController.paged} componentRef={pagedTableRef} columns={[{
                header: "Name",
                row: (row) =>
                <div key={row.id}>
                    {editName.find(h => h.id ==row.id)
                    ?<div>
                        <input onChange={e => updateRowName(e, row)} value={row.name} onKeyDown={e => e.key == "Enter" ? updateHandheld(row) : null} />
                        <div className="block"/>
                        <div className="btn-sm btn-error inline-block" onClick={() => cancelEdit(row)}>cancel</div>
                        <div className="btn-sm btn-primary inline-block" onClick={() => updateHandheld(row)}>update</div>
                    </div>
                    :<div onClick={() => setEditName([...editName, {id: row.id, original: row.name}])}>{row.name}</div>
                    }

                </div>


            }, {
                header: "UUID",
                row: (row) => row.uuid
            }, {
                header: "Field Entries",
                row: (row) => row.fieldEntries
            }, {
                header: "Weight Entries",
                row: (row) => row.weightEntries
            }, {
                header: <div className="text-right">Actions</div>,
                row: (row) => {
                    return <div>
                        <button className="flex justify-end btn btn-sm btn-primary" onClick={() => {
                            viewEntries(row.id)
                        }}>entries</button>
                    </div>
                }
            }]}/>

            <Dialog title="Entries" setShow={setShowEntries} show={showEntries} body={
                <div>
                    {
                        entryId ? <FieldEntries id={entryId}/> : <div>No data found</div>
                    }
                </div>

            }
            />
        </div>
    )
}

export default Handhelds;
//
// <template lang='pug'>
// div
//     i-paged-table(url="/handheld/paged", :args="args", ref="table", :filter="filter")
//         template(slot="header")
//             th Name
//             th UUID
//             th Field Entries
//             th Weight Entries
//             th Actions
//
//
//         template(v-slot="{row, index}")
//             td(@click="row.edit = true;")
//                 div(:key="row.id" v-if="row.edit")
//                     i-text(v-model="row.name", @enter="updateHandheld(row)")
//                     .block
//                     .btn-sm.btn-error.inline-block(@click.stop="cancelEdit(row)") cancel
//                     .btn-sm.btn-primary.inline-block(@click.stop="updateHandheld(row)") update
//                 div(v-else )
//                     .text-base {{ row.name }}
//             td {{ row.uuid | last(6) }}
//             td {{ row.fieldEntries }}
//             td {{ row.weightEntries }}
//             td
//                 .btn-sm.bg-primary-500(@click="viewEntries(row.id)") entries
//
//     i-dialog(v-if="showEntries", heading="Entries", show="showEntries", @close="showEntries = false")
//         field-entries(:scannerId="entryId")
//
// </template>
//
// <script>
// import Ajax from '@/scripts/ajax'
// import fieldEntries from './fieldentries.vue'
//
// export default {
//     components: {
//         "field-entries": fieldEntries
//     },
//     data () {
//         return {
//             args: {
//             },
//             showEntries: false,
//             entryId: null
//         }
//     },
//     methods: {
//         viewEntries(id) {
//             this.entryId = id
//             this.showEntries = true
//         },
//         filter(data) {
//             return data.map(d => ({
//                 ...d,
//                 edit: false,
//                 original: d.name,
//             }))
//         },
//         cancelEdit(row) {
//             row.edit = false
//             row.name = row.original
//         },
//         updateHandheld(row) {
//             Ajax.post('/handheld/update', {
//                 id: row.id,
//                 name: row.name
//             }).then(resp => {
//                 row.edit = false
//
//             })
//         },
//     },
// }
//
// </script>
