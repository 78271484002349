import React, {useContext, useRef} from "react";
import HandheldController from "../generated/controllers/handheldController";
import PagedSearchTable, {PagedTableFunctions} from "../components/PagedSearchTable";
import PaginationRequestIdSearch from "../generated/interfaces/paginationRequestIdSearch";
import FieldEntryDisplay from "../generated/interfaces/fieldEntryDisplay";
import { dateTimeOffset } from "../date";
import AppContext from "../pages/appContext";

const FieldEntries: React.FC<{ id: number }> = (props) => {
    const context = useContext(AppContext)
    const pagedTableRef = useRef<PagedTableFunctions<FieldEntryDisplay>>()

    return (
        <div>
            <PagedSearchTable<FieldEntryDisplay> call={(args) => HandheldController.fieldEntries({...args, id: props.id} as PaginationRequestIdSearch)}
            componentRef={pagedTableRef}
            columns={[
                {
                    header: 'Bin',
                    row: (row) => row.binRFID
                }, {
                    header: 'Scissor',
                    row: (row) => row.scissorRFID
                }, {
                    header: 'TimeStamp',
                    row: (row) => dateTimeOffset(row.timestamp, context.initial.timeOffset)
                }
            ]}/>
        </div>
    )
}

export default FieldEntries;

// <template lang='pug'>
// i-paged-table(url="/handheld/fieldentries", :args="args", ref="entries")
//     template(slot="header")
//         th Bin
//         th Scissor
//         th TimeStamp
//
//     template(v-slot="{row, index}")
//         td {{ row.binRFID }}
//         td {{ row.scissorRFID }}
//         td {{ row.timestamp | datetime}}
// </template>
//
// <script>
// export default {
//     props:['scannerId'],
//     data () {
//         return {
//             args: {
//                 id: this.scannerId
//             },
//         }
//     },
// }
//
// </script>
