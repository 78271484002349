import React, {Dispatch, SetStateAction, useContext, useState} from "react";
import AuthController from "../generated/controllers/authController";
import AppContext from "../pages/appContext";
import Success from "./Success";
import Failed from "./Failed";
import {useValidation} from "../validation";
import Dialog from "./Dialog";
import {EditRow, EditTable} from "./Fields";
import Password from "./Password";

const Reset: React.FC<{
    show: boolean,
    setShow: Dispatch<SetStateAction<boolean>>,
    token: string,
}> = (props) => {
    const context = useContext(AppContext)
    const [password, setPassword] = useState('') // required
    const [confirm, setConfirm] = useState('') // required has to be same as password

    const validation = useValidation({
        password: () => password.length > 0,
        confirm: () => confirm.length > 0 && confirm == password
    })

    function submit() {
        if (!validation.validate()) return

        AuthController.resetPassword({
            token: props.token,
            password: password
        }).then(() => {
            props.setShow(false)
            context.showSnack(<Success title='Success' text='Your password has been changed successfully.'/>)
        }).catch(() => {
            context.showSnack(<Failed title='Failed' text='We could not change your password at this time. Please try again'/>)
        })
    }

    return (
        <Dialog title='Reset your password?'
                show={props.show}
                setShow={props.setShow}
                body={<div >
                    <EditTable discard={() => props.setShow(false)} save={() => submit()} saveWord='Reset'>
                        {EditRow('Password: ', <Password value={password} change={setPassword}/>, validation.rules.password, 'Password is required')}
                        {EditRow('Confirm Password: ', <Password value={confirm} change={setConfirm}/>, validation.rules.confirm, "Confirm password must match password")}
                    </EditTable>
                </div>}
        />
    )
}

export default Reset;
