import React, { useContext } from 'react'
import { XCircleIcon } from '@heroicons/react/outline'

const Failed: React.FC<{
    title?: string;
    text?: string;
    onClick?: () => void;
}> = (props) => {

    return (
        <div className="flex w-full items-center z-50">
            <XCircleIcon className="h-12 w-12 text-red-400 m-2" aria-hidden="true"/>
            <div className="px-1">
                <p className="font-medium text-gray-900 m-3">{props.title ?? 'failed'}</p>
                <p className="mt-1 text-gray-500 m-3">{props.text}</p>
            </div>
        </div>
    )
}

export default Failed
