import React, {useContext, useEffect, useState} from 'react';
import AppContext from "./pages/appContext";
import {classNames, wrapLoader} from "./wrapper";
import {Outlet} from "react-router-dom";
import './spritesheets/icons.css'
import Menu from "./pages/main/Menu";
import Time from "./pages/main/Time";
import AuthController from "./generated/controllers/authController";
const signalR = require('@microsoft/signalr')

export const signal = new signalR.HubConnectionBuilder()
    .withUrl('/messages')
    .withAutomaticReconnect()
    .build()


const App: React.FC = () => {
    const context = useContext(AppContext);
    const [showMenuMobile, setShowMenuMobile] = useState(false);
    const [showMenuNormal, setShowMenuNormal] = useState(true);
    const [showUpdate, setShowUpdate] = useState<boolean>(false);


    function logout() {
        wrapLoader(context, AuthController.logout(), () => {
            window.location.reload()
        })
    }

    useEffect(() => {
        signal
            .start()
            .then(() => {
                signal.on('Version', (version: string) => {
                    if (version !== context.initial.version) setShowUpdate(true)
                })
                // signal.send('ClientVersion', {version: context.initial.version})
            })
    }, [])

    function hideMenu() {
        setShowMenuNormal(false)
    }

    return (
        <div className="flex flex-col h-screen">
            {showUpdate ? <div className='sticky top-0 z-50 w-full h-[5rem] border-8 bg-primary-600 px-10'>
                <div className='flex w-full h-[4rem] items-center justify-between'>
                    <div className=' text-xl text-white'>
                        New version is available. Please save your work and update.
                    </div>
                    <div className='btn bg-primary' onClick={() => {
                        setShowUpdate(false)
                        window.location.reload()
                    }}>
                        Update
                    </div>
                </div>
            </div> : null}
            {
                context.initial.isDev
                    ? <div className='sticky top-0 z-50 w-full border-2 bg-red-500 px-10 py-2'>
                        <div className='text-xl text-white'>
                            This is a testing environment.
                        </div>
                    </div>
                    : null
            }
            <div className="h-screen flex overflow-hidden bg-gray-100">
                {!showMenuMobile ? null : <div v-show="showMenuMobile" className="md:hidden">
                    <div className="fixed inset-0 flex z-40">
                        <div className="fixed inset-0">
                            <div className="absolute inset-0 bg-gray-600 opacity-75"/>
                        </div>
                        <div className="relative flex-1 flex flex-col max-w-xs w-full bg-gray-800">
                            {/* Mobile menu */}
                            <div className="flex-1 h-0 pb-4 overflow-y-auto">
                                <div className='flex justify-between items-center'>
                                    {/*Heroicon arrow left*/}
                                    <div className='pt-3 pr-3 ml-auto' onClick={() => setShowMenuMobile(false)} >
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                             strokeWidth="2" stroke="white" className="w-6 h-6">
                                            <path strokeLinecap="round" strokeLinejoin="round"
                                                  d="M10.5 19.5L3 12m0 0l7.5-7.5M3 12h18"/>
                                        </svg>
                                    </div>
                                </div>
                                <Menu className="mt-5 flex-1 px-2 bg-gray-800 space-y-1" render={(item, active) => {
                                    return <span
                                        onClick={() => showUpdate ? window.location.href = item.path : null}
                                        className={classNames('text-gray-300 hover:text-white group flex items-center px-3 text-base font-medium rounded-md pl-8 py-1 hover:bg-[#006936] rounded-lg', active ? 'bg-gray-900 text-white' : 'text-gray-300 hover:text-white')}>
                                        {item.display}
                                    </span>
                                }}/>

                            </div>
                            <div className="flex-shrink-0 flex p-4">
                                <div className="flex-shrink-0 block w-[100%] text-center">
                                    <div className="">
                                        <Time/>
                                        <div className="mx-auto sprite-logo2"></div>
                                        <button onClick={() => logout()} className="mb-4 mx-auto btn btn-error">logout</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="flex-shrink-0 w-14">
                            {/* Force sidebar to shrink to fit close icon */}
                        </div>
                    </div>
                </div>
                }

                {/* Static sidebar for desktop */}
                {
                    showMenuNormal ? <div className="hidden md:flex md:flex-shrink-0">
                        <div className="flex flex-col w-48">
                            {/* Sidebar component, swap this element with another sidebar if you like */}
                            <div className="flex flex-col h-0 flex-1 bg-gray-800">
                                <div className="text-center">
                                    <span className="align-middle px-2 text-xl uppercase text-secondary-500 p-1">Pick</span>
                                    <span
                                        className="align-middle bg-gray-800 text-white text-xl uppercase p-1">logger</span>
                                </div>

                                <div className="flex-1 flex flex-col pt-5 pb-4 overflow-y-auto">

                                    <Menu className="flex-1 px-2 bg-gray-800 space-y-1" render={(item, active) => {
                                        return <span
                                            onClick={() => showUpdate ? window.location.href = item.path : null}
                                            className={classNames('pl-14 group flex text-left items-center py-3 font-medium rounded-md hover:bg-[#006936] rounded-lg', active ? 'bg-gray-800 text-white' : 'text-gray-300 hover:text-white')}>
                                        {item.display}
                                    </span>
                                    }}/>

                                </div>
                                <div className="flex-shrink-0 flex pl-2 pr-3 py-5">
                                    <div className="flex-shrink-0 flex justify-center w-full block">
                                        <div className="">
                                            <Time/>
                                            <div className="mx-auto sprite-logo2"></div>
                                            <button onClick={() => logout()} className="mb-4 mx-auto btn btn-error">logout</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> : null
                }

                <div className="flex flex-col w-0 flex-1 overflow-hidden">
                    <div className={classNames("pl-1 pt-1 sm:pl-3 sm:pt-3", showMenuNormal ? 'md:hidden' : '')}>
                        <button
                            onClick={() => {
                                setShowMenuMobile(true)
                                setShowMenuNormal(true)
                            }} className="-ml-0.5 -mt-0.5 h-12 w-12 inline-flex items-center justify-center
            rounded-md text-gray-500 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-inset
            focus:ring-primary-500">
                            <span className="sr-only">Open sidebar</span>
                            {/* Heroicon name: menu */}
                            <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                 stroke="currentColor" aria-hidden="true">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}
                                      d="M4 6h16M4 12h16M4 18h16"/>
                            </svg>
                        </button>
                    </div>
                    <main className="flex-1 relative z-0 overflow-y-auto focus:outline-none" tabIndex={0}>
                        <div className="p-2">
                            <Outlet/>
                        </div>
                    </main>
                </div>
            </div>

            <div className='fixed bottom-0 z-50 w-full bg-gray-800 px-10 text-white text-center text-xs'>
                Agritechnovation PickLogger v.{context.initial.version}
            </div>

            {/*            <teleport to="#portal">*/}
            {/*        <transition name="fade">*/}
            {/*            <div v-if="showUpgrade" className="fixed right-0 bottom-0 bg-red-400 p-2 text-white m-2">Brace yourself for*/}
            {/*                the next upgrade.*/}
            {/*            </div>*/}
            {/*        </transition>*/}
            {/*    </teleport>*/}
            {/*    <loader-comp v-model:show="loader"/>*/}
            {/*    <transition name="slidedown">*/}
            {/*        <div className="snackbar shadow-lg" v-if="snack.key"*/}
            {/*        :key="snack.key" :class="snack.type">*/}
            {/*        <div className="snackbar-content">*/}
            {/*            <div className="cell left">{'{'}{'{'} snack.text {'}'}{'}'}</div>*/}
            {/*            <div className="cell right action font-bold"*/}
            {/*            @click="dismiss">Dismiss</div>*/}
            {/*    </div>*/}
            {/*</div>*/}
            {/*</transition>*/}
        </div>
    );
}

export default App;
