/* eslint-disable */
/**
 * This file was generated by Itecho.TSGen tool
 * Version and info generated in .version
 * Any changes to this file will be lost if regenerated 
 */

import { webRequest, fileRequest } from "../request";
import type AssignRfidRequest from "../interfaces/assignRfidRequest";
import type BatteryGraph from "../interfaces/batteryGraph";
import type BatteryScissorRequest from "../interfaces/batteryScissorRequest";
import type ClientIdRequest from "../interfaces/clientIdRequest";
import type EmployeeUpdateRequest from "../interfaces/employeeUpdateRequest";
import type IdDayRequest from "../interfaces/idDayRequest";
import type IdRequest from "../interfaces/idRequest";
import type MicroPickRequest from "../interfaces/microPickRequest";
import type MicroScissorPicksTranspose from "../interfaces/microScissorPicksTranspose";
import type PagedScissorRawEntries from "../interfaces/pagedScissorRawEntries";
import type PaginationRequestIdSearch from "../interfaces/paginationRequestIdSearch";
import type PaginationRequestSearch from "../interfaces/paginationRequestSearch";
import type PaginationResponse from "../interfaces/paginationResponse";
import type ScissorAudit from "../interfaces/scissorAudit";
import type ScissorDetailsResponse from "../interfaces/scissorDetailsResponse";
import type ScissorDisplay from "../interfaces/scissorDisplay";
import type ScissorReportingResult from "../interfaces/scissorReportingResult";
import type ScissorUpdateRequest from "../interfaces/scissorUpdateRequest";

export default {
    microPicks: (pick: MicroPickRequest) => webRequest<MicroScissorPicksTranspose>('/scissor/micropicks', {
        method: 'post',
        body: pick
    }),
    statusReport: () => webRequest<ScissorReportingResult>('/scissor/statusreport'),
    pagedCurrent: (request: PaginationRequestSearch) => webRequest<PaginationResponse<ScissorDisplay>>('/scissor/pagedcurrent', {
        method: 'post',
        body: request
    }),
    pagedArchived: (request: PaginationRequestSearch) => webRequest<PaginationResponse<ScissorDisplay>>('/scissor/pagedarchived', {
        method: 'post',
        body: request
    }),
    pagedEntries: (request: PaginationRequestIdSearch) => webRequest<PaginationResponse<PagedScissorRawEntries>>('/scissor/pagedentries', {
        method: 'post',
        body: request
    }),
    archive: (request: IdRequest) => webRequest<unknown>('/scissor/archive', {
        method: 'post',
        body: request
    }),
    restore: (request: IdRequest) => webRequest<unknown>('/scissor/restore', {
        method: 'post',
        body: request
    }),
    updateScissor: (request: ScissorUpdateRequest) => webRequest<unknown>('/scissor/updatescissor', {
        method: 'post',
        body: request
    }),
    audit: (requestSearch: PaginationRequestSearch) => webRequest<PaginationResponse<ScissorAudit>>('/scissor/audit', {
        method: 'post',
        body: requestSearch
    }),
    updateEmployee: (request: EmployeeUpdateRequest) => webRequest<unknown>('/scissor/updateemployee', {
        method: 'post',
        body: request
    }),
    assignRfid: (request: AssignRfidRequest) => webRequest<unknown>('/scissor/assignrfid', {
        method: 'post',
        body: request
    }),
    updateClient: (request: ClientIdRequest) => webRequest<unknown>('/scissor/updateclient', {
        method: 'post',
        body: request
    }),
    removeRfid: (request: IdRequest) => webRequest<unknown>('/scissor/removerfid', {
        method: 'post',
        body: request
    }),
    battery: (request: BatteryScissorRequest) => webRequest<BatteryGraph[]>('/scissor/battery', {
        method: 'post',
        body: request
    }),
    rawData: (request: number[]) => fileRequest('/scissor/rawdata', {
        queryParams: {
            request
        }
    }),
    report: () => webRequest<unknown>('/scissor/report'),
    process: () => webRequest<unknown>('/scissor/process'),
    details: (request: IdDayRequest) => webRequest<ScissorDetailsResponse>('/scissor/details', {
        method: 'post',
        body: request
    })
}