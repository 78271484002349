/* eslint-disable */
/**
 * This file was generated by Itecho.TSGen tool
 * Version and info generated in .version
 * Any changes to this file will be lost if regenerated 
 */

import { webRequest, fileRequest } from "../request";
import type PaginationRequest from "../interfaces/paginationRequest";
import type PaginationResponse from "../interfaces/paginationResponse";
import type RawEntry from "../interfaces/rawEntry";

export default {
    getEntries: (request: PaginationRequest) => webRequest<PaginationResponse<RawEntry>>('/entry/getentries', {
        method: 'post',
        body: request
    })
}