
import React, {useContext, useEffect, useRef, useState} from "react";
import { AutoCompleteNullable } from "../components/AutoComplete";
import CheckBox from "../components/CheckBox";
import Dialog from "../components/Dialog";
import PagedSearchTable, { PagedTableFunctions } from "../components/PagedSearchTable";
import WarningPopup, { useWarningState } from "../components/WarningPopup";
import {dateTimeOffset } from "../date";
import ClientController from "../generated/controllers/clientController";
import ClientList from "../generated/interfaces/clientList";
import PagedScissorRawEntries from "../generated/interfaces/pagedScissorRawEntries";
import PaginationResponse from "../generated/interfaces/paginationResponse";
import ScissorController from "../generated/controllers/scissorController";
import ScissorDisplay from "../generated/interfaces/scissorDisplay";
import PaginationRequestSearch from "../generated/interfaces/paginationRequestSearch";
import AppContext from "./appContext";
import ScissorReportingResult from "../generated/interfaces/scissorReportingResult";
import { classNames } from "../wrapper";
import { DatePicker, DatePickerType } from "../components/DatePicker";
import {  Chart as ChartJS, Point } from 'chart.js/auto';
import Success from "../components/Success";
import {last} from "../helper";


const ScissorsCurrent: React.FC = () => {
    const pagedTableRef = useRef<PagedTableFunctions<ScissorDisplay>>()
    const warningState = useWarningState<number>(-1)
    const warningStateArchive = useWarningState<number>(-1)
    const context = useContext(AppContext)
    const [clients, setClient] = useState<ClientList[]>([])

    const [scissorSeleted, setScissorSelected] = useState<number>(0)

    const [editName, setEditName] = useState<{id: number, original: string}[]>([])
    const [scissorsSelected ,setScissorsSelected] = useState<number[]>([])
    const canvas  = useRef<HTMLCanvasElement>(null)
    const [chart, setChart]  = useState<ChartJS<"line", (number | Point | null)[], string>>()
    const [showScissor ,setShowScissor] = useState(false)
    const [fromDate ,setFromDate] = useState<Date>(new Date())
    const [toDate ,setToDate] = useState<Date>(new Date())
    const [showBatteries ,setShowBatteries] = useState<boolean>(false)
    const [statusReport ,setStatusReport] = useState<ScissorReportingResult>()
    const [showStatusReport ,setShowStatusReport] = useState<boolean>(false)

    useEffect(() => {
        ClientController.all().then(resp => {
            setClient(resp)
        })
    }, [])


    function cancelEdit(row: ScissorDisplay) {
        const originalName = editName.find(r => r.id == row.id)?.original ?? ''
        pagedTableRef.current?.updateRow(pred => pred.id == row.id, (r) => r.name = originalName)
        setEditName(editName.filter(p => p.id !== row.id))
    }
    function updateClient(event: number | null, row: ScissorDisplay) {
        ScissorController.updateClient( {id:row.id, clientId: event})
            .then(resp => {
                pagedTableRef.current?.refresh();
            })
    }
    function updateScissor({id, name}: ScissorDisplay) {
        ScissorController.updateScissor({
            id: id,
            name: name
        }).then((resp) => {
            setEditName(editName.filter(p => p.id !== id));
        });
    }

    function init(){
        new ChartJS(canvas.current!, {
            type: "line",
            data: {
                labels: [],
                datasets: [],
            },
            options: {
                scales: {
                    "y":
                        {
                            beginAtZero: true
                        }
                },
            },
        });
    }
    function downloadRawData() {
        ScissorController.rawData(scissorsSelected)
        context.showSnack(<Success title='Starting download'/>)
    }

    function currentStatus() {
        const hideLoader = context.showLoader()
        ScissorController.statusReport().then(resp => {
            setStatusReport(resp)
            setShowStatusReport(true)
        }).finally(() => {
            hideLoader()
        })
    }

    function assignRfid(id: number) {
        var rfid = prompt("New RFID");
        if (rfid) {
            ScissorController.assignRfid( {
                id: id,
                rfid: rfid,
            }).then(() => {
                pagedTableRef.current?.refresh();
            });
        }
    }
    function loadScissors() {
        ScissorController.battery({
            ids: scissorsSelected,
            from: fromDate,
            to: toDate,
        }).then((resp) => {
            var data: any[] = [];
            resp.forEach((scissor) => {
                data.push({
                    label: scissor.scissorId,
                    data: scissor.entries.map((e) => e.battery),
                    backgroundColor: ["rgb(0, 0, 0, 0)"],
                    borderColor: [
                        `#${Math.floor(Math.random() * 16777215).toString(
                            16
                        )}`,
                    ],
                    borderWidth: 1,
                });
            });

            setShowBatteries(true);

            if (chart !== null) {
                chart?.destroy()
            }


            let temp = new ChartJS(canvas.current!, {
                type: "line",
                data: {
                    labels: resp[0]?.entries.map((e) => e.date.toUTCString()),
                    datasets: data,
                },
                options: {
                    scales: {
                        "y":
                            {
                                suggestedMin: 3200
                            }
                    },
                },
            });

            setChart(temp)
        });
    }
    function process() {
        ScissorController.process().then(() => {
            pagedTableRef.current?.refresh()
        });
    }
    function select(id: number) {
        setScissorSelected(id);
        setShowScissor(true);
    }
    function unassign(id: number) {
        const hideLoader = context.showLoader()
        ScissorController.removeRfid( { id }).then((resp) => {
            pagedTableRef.current?.refresh();
        }).finally(() => hideLoader())
    }

    function showLocation(item: Point) {
        let url = `https://www.google.com/maps/search/?api=1&query=${item.x},${item.y}`;
        window.open(url);
    }

    function archive() {
        const hideLoader = context.showLoader()
        ScissorController.archive( {id: warningStateArchive.state.data}).then(resp => {
            context.showSnack(<Success title='Success' text="Scissor archived"/>)
            pagedTableRef.current?.refresh()
        }).finally(() => hideLoader())
    }

    function searchPagedEntries(request: PaginationRequestSearch): Promise<PaginationResponse<PagedScissorRawEntries>> {
        return ScissorController.pagedEntries({...request, id: scissorSeleted})
    }


    function updateRowName(event: React.ChangeEvent<HTMLInputElement>, row: ScissorDisplay): void {
        pagedTableRef.current?.updateRow(pred => pred.id == row.id, (r) => r.name = event.target.value)
    }

    return (
        <div>
            <button className="btn mr-1 btn-error my-2" onClick={() => process()} >Process</button>
            <button className="btn mr-1 btn-primary my-2" onClick={() => loadScissors()} >Batteries</button>
            <button className="btn mr-1 btn-primary my-2" onClick={() => downloadRawData()} >Raw Data</button>
            <button className="btn btn-primary my-2" onClick={() => currentStatus()} >Current Status</button>

            <PagedSearchTable call={ScissorController.pagedCurrent} componentRef={pagedTableRef} columns={[{
                header: 'Check',
                row: (row) => <CheckBox checked={scissorsSelected.includes(row.id)} onChange={v => v? setScissorsSelected([...scissorsSelected, row.id]):setScissorsSelected(scissorsSelected.filter(s => s !== row.id))}/>
            }, {
                header: 'Name',
                row: (row, i) =>
                    <div className="" key={row.id}>
                        { editName.find(r => r.id == row.id)
                            ? <div>
                                <input className='text-gray-500' onChange={e => updateRowName(e, row)} value={row.name} onKeyDown={e => e.key == "Enter" ? updateScissor(row) : null} />
                                <div className="block"/>
                                <div className="btn-sm btn-error inline-block" onClick={() => cancelEdit(row)}>cancel</div>
                                <div className="btn-sm btn-primary inline-block" onClick={() => updateScissor(row)}>update</div>
                            </div>
                            :
                            <div onClick={() => setEditName([...editName, {id: row.id, original: row.name}])}> {row.name || 'n/a'}</div>
                        }
                    </div>

            }, {
                header: 'Tag',
                row: (row) => last(row.uid, 8)
            }, {
                header: 'RFID',
                row: (row) => row.rfiduid
            }, {
                header: 'Raw Count',
                row: (row) => row.rawCount
            }, {
                header: 'Location Count',
                row: (row) => row.locationCount
            }, {
                header: 'Data Count',
                row: (row) => row.dataCount
            }, {
                header: 'Performance %',
                row: (row) => (((row.locationCount / row.rawCount) * 100).toFixed(2) )
            }, {
                header: 'Last Farm',
                row: (row) => row.farmName
            }, {
                header: 'Versions',
                row: (row) => <div className="text-xs">
                    {row.fwVersion !== null ? <div>FW: {row.fwVersion}</div>: null}
                    {row.hwVersion !== null ? <div>HW: {row.hwVersion}</div>: null}
                </div>
            }
                ,  {
                    header: 'Client',
                    row: (row) => <AutoCompleteNullable value={row.clientId} options={clients} valueFunc={c => c.id} textFunc={c => c.name} onChange={v => updateClient(v, row)}></AutoCompleteNullable>
                }
                ,  {
                    header: <div className="text-right">Actions</div>,
                    row: (row) =>
                        <div className='flex flex-wrap justify-end'>
                            <div className="btn-sm btn-primary mr-1" onClick={() => assignRfid(row.id)} >rfid</div>
                            <div className="btn-sm btn-primary mr-1" onClick={() => select(row.id)} >view </div>
                            <div className="btn-sm btn-error mr-1" onClick={() => warningStateArchive.show("Are you sure you want to archive this scissor?", row.id)} >archive</div>
                            {row.rfiduid !== null && row.rfiduid !== "" ? <div className="btn-sm btn-error" onClick={() => warningState.show("You will remove the current RFID tag from the scissor. Continue?", row.id)} > x </div>: null }
                        </div>
                }

            ]}/>
            <Dialog title="Raw Entries" setShow={setShowScissor} show={showScissor} body={
                <div>
                    <PagedSearchTable call={searchPagedEntries}
                                      columns={[{
                                          header: "Station",
                                          row: (row) => row.station
                                      }, {
                                          header: "Timestamp",
                                          row: (row) => dateTimeOffset(row.timeStamp, context.initial.timeOffset)
                                      } , {
                                          header: "Battery",
                                          row: (row) => row.battery
                                      }, {
                                          header: "Gps",
                                          row: (row) => row.gpsStatus
                                      }, {
                                          header: "Point",
                                          row: (row) => {
                                              return row.point.x !== 0 && row.point.y !== 0
                                                  ? <div className="icon w-8">
                                                      <svg
                                                          viewBox="0 0 24 24"
                                                          onClick={() => showLocation(row.point)}
                                                      >
                                                          <path
                                                              className="svg-primary"
                                                              d="M5.64 16.36a9 9 0 1 1 12.72 0l-5.65 5.66a1 1 0 0 1-1.42 0l-5.65-5.66zM12 13a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"
                                                          ></path>
                                                          <path
                                                              className="svg-secondary"
                                                              d="M12 1a9 9 0 0 1 6.36 15.36l-5.65 5.66a1 1 0 0 1-.71.3V13a3 3 0 0 0 0-6V1z"
                                                          ></path>
                                                      </svg>
                                                  </div>
                                                  : null
                                          }
                                      }, {
                                          header: "Farm",
                                          row: (row) =>row.farm
                                      }]} />
                </div>

            }/>

            <Dialog title={'Missing Scissors'}
                    show={showStatusReport}
                    setShow={setShowStatusReport}
                    body={
                        <div className="w-full overflow-x-scroll">
                            <table className="m-2 min-w-full divide-y divide-gray-300 ">
                                <thead>
                                <tr>
                                    <th className="uppercase px-2 py-2 text-gray-500 text-left text-sm font-semibold ">Name</th>
                                    <th className="uppercase px-2 py-2 text-gray-500 text-left text-sm font-semibold ">UID</th>
                                </tr>
                                </thead>
                                <tbody>
                                {statusReport?.missingScissors !== undefined && statusReport?.missingScissors.length > 0 ? statusReport?.missingScissors.map((s, i) => <tr className={classNames(i % 2 === 0 ? '' : 'bg-gray-50', 'group hover:bg-gray-200 cursor-pointer')} key={i}>
                                    <td className={classNames('whitespace-nowrap px-1 py-2 text-sm text-gray-500')}>{s.name}</td>
                                    <td className={classNames('whitespace-nowrap px-1 py-2 text-sm text-gray-500')}>{s.uid}</td>
                                </tr>) : null}
                                </tbody>
                            </table>
                        </div>
                    }
            />

            <Dialog title="Battery Readings" setShow={setShowBatteries} show={showBatteries} body={
                <div>
                    <table>
                        <tbody >
                        <tr>
                            <td className="w-12 m-3 p-5">
                                <label>From: </label>
                            </td>
                            <td className="p-5">
                                <DatePicker value={fromDate} setValue={ v => {
                                    setFromDate(v)
                                    loadScissors()}
                                }  type={DatePickerType.Date}></DatePicker>
                            </td>
                            <td className="w-12 m-3 p-5">
                                <label>To: </label>
                            </td>
                            <td className="p-5">
                                <DatePicker value={toDate} setValue={ v => {
                                    setToDate(v)
                                    loadScissors()}
                                }  type={DatePickerType.Date}></DatePicker>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                    <div>
                        <canvas ref={canvas}/>
                    </div>
                </div>
            }
            />

            <WarningPopup state={warningState} onYes={() => unassign(warningState.state.data)}/>
            <WarningPopup state={warningStateArchive} onYes={() => archive()}/>
        </div>
    )
}

export default ScissorsCurrent;
