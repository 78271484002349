import React, { InputHTMLAttributes, useEffect, useState } from 'react'
import { classNames } from '../wrapper'

function toString (value: number | null, fractionDigits?: number): string {
    if (value === null) { return '' }

    if (fractionDigits === undefined) { return value.toString() }

    return value.toFixed(fractionDigits)
}

const NumberNullable: React.FC<{
    value: number | null;
    change: (number: number | null) => void;
    children?: (attrs: InputHTMLAttributes<any>) => React.ReactNode
    fractionDigits?: number;
    error?: boolean;
    disabled?: boolean;
    className?: string;
}> = (props) => {
    const [last, setLast] = useState('')

    useEffect(() => {
        setLast(toString(props.value, props.fractionDigits))
    }, [props.value])

    function onChange (input: string) {
        setLast(input)
        if (input === '') {
            props.change(null)
            return
        }
        const v = parseFloat(input)
        if (!isNaN(v) && isFinite(v)) {
            props.change(v)
        }
    }

    function onBlur () {
        // revert to last valid value
        setLast(toString(props.value, props.fractionDigits))
    }

    if (props.children) {
        return <>
            {props.children({
                value: last,
                onBlur: () => onBlur(),
                onInput: (e) => onChange((e.target as HTMLInputElement).value)
            })}
        </>
    }

    return <input className={classNames(props.className ,'input border', !!props.disabled ? 'bg-gray-200' : '',props.error ? 'border-error-300' : 'border-gray-300')} disabled={!!props.disabled} value={last} onBlur={() => onBlur()} onInput={(e) => onChange((e.target as HTMLInputElement).value)}/>
}

export default NumberNullable
