/**
 * This file was generated by Itecho.TSGen tool
 * Version and info generated in .version
 * Any changes to this file will be lost if regenerated 
 */

enum Originate {
    International = "International",
    Usa = "Usa"
}
export default Originate