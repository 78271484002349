import { useEffect, useReducer, useRef } from 'react';
const Vel = require('velocity-animate');

const Slider: React.FC<{
    show: boolean;
    duration?: number;
}> = (props) => {

    const el = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (props.show) {
            Vel(el, 'finish')
            Vel(el, 'slideDown', {
                duration: props?.duration ?? 200,
            })
        } else {
            Vel(el, 'finish')
            Vel(el, 'slideUp', {
                duration: props?.duration ?? 200,
            })   
        }
    }, [props.show]);

    return <div ref={el}>{props.children}</div>
}

export default Slider;