/* eslint-disable */
/**
 * This file was generated by Itecho.TSGen tool
 * Version and info generated in .version
 * Any changes to this file will be lost if regenerated 
 */

import { webRequest, fileRequest } from "../request";
import type BinDetails from "../interfaces/binDetails";
import type IdRequest from "../interfaces/idRequest";
import type PaginationRequestIdSearch from "../interfaces/paginationRequestIdSearch";
import type PaginationRequestSearch from "../interfaces/paginationRequestSearch";
import type PaginationResponse from "../interfaces/paginationResponse";
import type ScannedEntriesDisplay from "../interfaces/scannedEntriesDisplay";
import type ScannersDisplay from "../interfaces/scannersDisplay";
import type ScannerUpdateRequest from "../interfaces/scannerUpdateRequest";

export default {
    scanners: (request: PaginationRequestSearch) => webRequest<PaginationResponse<ScannersDisplay>>('/scanner/scanners', {
        method: 'post',
        body: request
    }),
    downloadScanners: (request: PaginationRequestSearch) => fileRequest('/scanner/downloadscanners', {
        queryParams: {
            ...request
        }
    }),
    entries: (request: PaginationRequestIdSearch) => webRequest<PaginationResponse<ScannedEntriesDisplay>>('/scanner/entries', {
        method: 'post',
        body: request
    }),
    scannedEntries: (request: PaginationRequestIdSearch) => webRequest<PaginationResponse<ScannedEntriesDisplay>>('/scanner/scannedentries', {
        method: 'post',
        body: request
    }),
    downloadScannedEntries: (request: PaginationRequestIdSearch) => fileRequest('/scanner/downloadscannedentries', {
        queryParams: {
            ...request
        }
    }),
    binDetails: (request: IdRequest) => webRequest<BinDetails>('/scanner/bindetails', {
        method: 'post',
        body: request
    }),
    update: (request: ScannerUpdateRequest) => webRequest<unknown>('/scanner/update', {
        method: 'post',
        body: request
    })
}