function leadingZero (f: string): string {
    if (f.length === 0) {
        return '00'
    }
    if (f.length === 1) {
        return '0' + f
    }
    return f
}

const monthHeadingFull = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
const monthHeadingShort = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
const dayHeadingFull = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
const dayHeadingShort = ['Sun', 'Mon', 'Tues', 'Wed', 'Thu', 'Fri', 'Sat']
const dayHeadingSingle = ['S', 'M', 'T', 'W', 'T', 'F', 'S']

/*
Formats a date according to spesific format
Follows PHP's date formatting convention
http://php.net/manual/en/function.date.php

Formatting tokens:
    Days
    d - Day of the month, 2 digitis with leading zeros (01-31)
    j - Day of the month without leading zeros (1-31)
    D - A textual representation of a day, three letters (Mon to Sun)
    l - Full text day (Monday to Sunday)
    w - Day of week (0 for Sunday - 6 for Saturday)
    z - Day of the year Month
    F - full text month (January to December)
    m - numeric with leading zero (01 to 12)
    M - short textual (Jan  to Dec)
    n - numeric without leading zero (1 to 12)
    t - number of days in the given month

    Year
    Y - 4 digit year  (2016)
    y - 2 digit year (16)

    Time
    a - ante meridiem or post medidiem lowercse
    A - same as a just upperase
    g - 12-hour format without leading zeros (1 - 12)
    G - 24-hour format without leading zeros (0 - 23)
    h - 12-hour with leading zero (01 - 12)
    H - 24-hour with leading zeros (01 - 23)
    i - Minutes with leading zeros (00 - 59)
    s - Seconds with leading zeros (00 - 59)
*/

function tokenValue (date: Date, token: string): string {
    switch (token) {
        case 'd':
            return leadingZero(date.getDate().toString())
        case 'j':
            return date.getDate().toString()
        case 'D':
            return dayHeadingShort[date.getDay()]!
        case 'l':
            return dayHeadingFull[date.getDay()]!
        case 'w':
            return date.getDay().toString()
        case 'z':
            return Math.ceil((date.getHours() - new Date(date.getFullYear(), 0, 0).getHours()) / (1000 * 60 * 60 * 24)).toString()
        case 'F':
            return monthHeadingFull[date.getMonth()]!
        case 'm':
            return leadingZero((date.getMonth() + 1).toString())
        case 'M':
            return monthHeadingShort[date.getMonth()]!
        case 'n':
            return (date.getMonth() + 1).toString()
        case 't':
            return new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate().toString()
        case 'Y':
            return date.getFullYear().toString()
        case 'y':
            return String(date.getFullYear()).substr(2, 2)
        case 'a':
            return date.getHours() >= 12 ? 'pm' : 'am'
        case 'A':
            return date.getHours() >= 12 ? 'PM' : 'AM'
        case 'g':
            return (date.getHours() % 12).toString()
        case 'G':
            return (date.getHours()).toString()
        case 'h':
            return leadingZero((date.getHours() % 12).toString())
        case 'H':
            return leadingZero((date.getHours()).toString())
        case 'i':
            return leadingZero((date.getMinutes()).toString())
        case 's':
            return leadingZero((date.getSeconds()).toString())
        default:
            return token
    }
}

// Vue.filter('datetime', (val, format) => {
//     const MS_PER_MINUTE = 60000;
//     // get logged in user offset.
//     let minutes = window.initial.timeOffset * -1 || 0;
    
//     if (typeof(val) == 'string') {
//         // try to parse date iso8601
//         val = new Date(val)
//     }
//     if (val instanceof Date) {
//         // Remove browser timezone. new Date() gives local zone of user.
//         let realMinutes = minutes - val.getTimezoneOffset();
//         let offsetDate = new Date(val - realMinutes * MS_PER_MINUTE)
//         return dateFormat(offsetDate, format || '%d %M %Y %H:%i:%s')
//     }
    
//     return ''
// })

function dateTimeOffset(date: Date | null, offset: number, format: string = '%d %M %Y %H:%i:%s'): string {
    const MS_PER_MINUTE = 60000;
    // get logged in user offset.
    let minutes = offset * -1 || 0;

    if (date === null) return ''

    // Remove browser timezone. new Date() gives local zone of user.
    let realMinutes = minutes - date.getTimezoneOffset();
    let offsetDate = new Date(date.getTime() - realMinutes * MS_PER_MINUTE)
    return dateFormat(offsetDate, format)
}


function dateFormat(date: Date | null, format: string = '%d %M %Y'): string {
    if (date === null) return ''

    if (!format || format.length === 0) {
        return ''
    }
    // normal string replacement
    let formatted = ''
    for (let i = 0; i < format.length; i++) {
        if (format.charAt(i) === '%') {
            formatted += tokenValue(date, format.charAt(++i))
            continue
        }
        formatted += format.charAt(i)
    }
    return formatted
}

function dateCompare (a: Date, b: Date): boolean {
    return (
        a.getFullYear() === b.getFullYear() &&
        a.getMonth() === b.getMonth() &&
        a.getDate() === b.getDate()
    )
}

export {monthHeadingFull}
export {monthHeadingShort}
export {dayHeadingFull}
export {dayHeadingShort}
export {dayHeadingSingle}
export {dateFormat}
export {dateCompare}
export {dateTimeOffset}
