import React, {useContext, useEffect, useRef, useState} from "react";
import ClientController from "../generated/controllers/clientController";
import ClientList from "../generated/interfaces/clientList";
import StationController from "../generated/controllers/stationController";
import PagedSearchTable, {PagedTableFunctions} from "../components/PagedSearchTable";
import Input from "../components/Input";
import CheckBox from "../components/CheckBox";
import {dateFormat, dateTimeOffset} from "../date";
import {AutoCompleteNullable} from "../components/AutoComplete";
import Dialog from "../components/Dialog";
import StationStatus from "./dashboard/StationStatus";
import StationStatusResult from "../generated/interfaces/stationStatusResult";
import Success from "../components/Success";
import AppContext from "./appContext";
import StationDisplay from "../generated/interfaces/stationDisplay";
import {first, last} from "../helper";

const ManageStations: React.FC = () => {
    const pagedTableRef = useRef<PagedTableFunctions<StationDisplay>>()
    const context = useContext(AppContext)
    const [entries, setEntries] = useState([])
    const [args, setArgs] = useState({
        sortBy: '',
            toDate: '',
            fromDate: ''
    })
    const [clients, setClients] = useState<ClientList[]>([])
    const [stationShow, setStationShow] = useState(false)
    const [station, setStation] = useState<StationStatusResult>()

    useEffect(() => {
        ClientController.all().then(resp => {
            setClients(resp)
        })
    }, [])

    function showStatus(id: number) {
        const hideLoader = context.showLoader()
        StationController.statusData({id}).then(resp => {
            setStation(resp)
            setStationShow(true)
        }).finally(() => hideLoader())
    }

    function downloadEntries(id: number) {
        StationController.rawEntries(id)
        context.showSnack(<Success title={"Starting download"}/>)
    }

    function updateRequest(row: StationDisplay) {
        return StationController.update(row)
    }

    function updateName(row: StationDisplay) {
        pagedTableRef.current?.updateRow(p => p.id == row.id, r => r.name = row.name)
        updateRequest(row)
    }

    function updateClient(event: number | null, row: StationDisplay) {
        row.clientId = event
        pagedTableRef.current?.updateRow(p => p.id === row.id, r => r.clientId = event)
        updateRequest(row)
    }

    function updateDebugger(event: boolean, row: StationDisplay) {
        row.debugger = event
        updateRequest(row).then(() => {
            pagedTableRef.current?.refresh()
        })
    }

    function updateAssigner(event: boolean, row: StationDisplay) {
        row.rfidAssigner = event
        updateRequest(row).then(() => {
            pagedTableRef.current?.refresh()
        })
    }


    return (
        <div>
            <PagedSearchTable
                componentRef={pagedTableRef}
                call={StationController.paged}
                columns={[
                    {
                        header: 'UID',
                        key: 'uid',
                        row: row => last(row.uid, 10)
                    },
                    {
                        header: 'Balena',
                        key: 'balena',
                        row: row => first(row.balenaDeviceUUID, 7)
                    },
                    {
                        header: 'Name',
                        key: 'name',
                        row: row => <Input onPaste={() => console.log(row.clientId)} value={row.name ?? ''} change={(v) => updateName({...row, name: v})} key={row.id}/>
                    },
                    {
                        header: 'Debugger',
                        key: 'debugger',
                        row: row => <CheckBox checked={row.debugger} onChange={(e) => updateDebugger(e, row)} />
                    },
                    {
                        header: 'RFID Assigner',
                        key: 'rfidAssigner',
                        row: row => <CheckBox checked={row.rfidAssigner} onChange={(e) => updateAssigner(e, row)} />
                    },
                    {
                        header: 'Last Point Date',
                        key: 'lastCommunicated',
                        row: row => dateTimeOffset(row.lastCommunication, context.initial.timeOffset)
                    },
                    {
                        header: 'Client',
                        key: 'client',
                        row: row => <AutoCompleteNullable options={clients}
                                                          value={row.clientId}
                                                          textFunc={z => `${z.name} (${z.originate})`}
                                                          valueFunc={t => t.id}
                                                          onChange={(e) => updateClient(e, row)} />
                    },
                    {
                        header: <div className="text-right">Actions</div>,
                        key: 'actions',
                        row: row => <div className="flex justify-end">
                            <div className="btn-sm btn-primary mr-1" onClick={() => downloadEntries(row.id)}>raw</div>
                            <div className="btn-sm btn-primary mr-1" onClick={() => showStatus(row.id)}>status</div>
                        </div>
                    }
                ]}
                />
            <Dialog title={'Station Status'}
                    show={stationShow}
                    setShow={setStationShow}
                    body={<div className='p-2'>
                        {
                            station !== undefined
                                ? <StationStatus station={station} key={station.id}/>
                                : <div className='text-center'>No status</div>
                        }
                    </div>} />
        </div>
    )
}

export default ManageStations;
