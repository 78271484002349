import React, {useContext, useState} from "react";
import PagedSearchTable from "../components/PagedSearchTable";
import ScannerController from "../generated/controllers/scannerController";
import ScannedEntriesDisplay from "../generated/interfaces/scannedEntriesDisplay";
import {dateTimeOffset} from "../date";
import BinDetails from "../generated/interfaces/binDetails";
import Dialog from "../components/Dialog";

import AppContext from "./appContext";
import Failed from "../components/Failed";
import {last} from "../helper";

const Scanned: React.FC<{
    scannerId: number;
}> = (props) => {
    const context = useContext(AppContext)

    const [binData, setBinData] = useState<BinDetails>()
    const [showBinData, setShowBinData] = useState(false)

    function getBinData(id: number) {
        ScannerController.binDetails({id: id}).then(resp => {
            setBinData(resp)
            binData ? setShowBinData(true)
                    : context.showSnack(<Failed title={"There is no data available"} />)
            })
        }

    function averageItemWeight(): number {
        const weight = (binData?.bin?.weight ?? 0) * 1000
        const length = binData?.points?.length
        if (length !== undefined) {
            return weight / length
        }
        return 0
    }

    return (
        <div>
            <PagedSearchTable<ScannedEntriesDisplay>
                call={(args) => ScannerController.scannedEntries({...args, id: props.scannerId})}
                downloadExcelCall={resp => ScannerController.downloadScannedEntries({...resp, id: props.scannerId})}
                columns={[
                    {
                        header: "UHF Tag",
                        key: 'binUHFTag',
                        row: (row) => last(row.binUHFTag, 6)

                    },
                    {
                        header: "TimeStamp",
                        key: 'timeStamp',
                        row: (row) => dateTimeOffset(row.timeStamp, context.initial.timeOffset)
                     },
                     {
                        header: "Start Time",
                        key: 'startTime',
                        row: (row) => dateTimeOffset(row.startTime, context.initial.timeOffset)
                     },
                     {
                        header: "End Time",
                        key: 'endTime',
                        row: (row) => dateTimeOffset(row.endTime, context.initial.timeOffset)
                     },
                     {
                        header: "Bags",
                        key: 'bags',
                        row: (row) => row.bags
                     },
                     {
                        header: "Fill Time",
                        key: 'fillTime',
                        row: (row) => row.fillTime
                     },
                     {
                        header: "Weight",
                        key: 'weight',
                        row: (row) => row.weight?.toFixed(2)
                     }]}/>

            <Dialog title="Bin Data"
                    show={showBinData}
                    setShow={setShowBinData}
                    body={
                        <div>
                            <p>Bin Weight: { binData?.bin?.weight ?? 0 } kg</p>
                            <p>Total Picks: { binData?.points?.length ?? 0 }</p>
                            <p>Average Item Weight: { averageItemWeight() } g</p>
                        </div>
                    }
            />
        </div>
    )
}

export default Scanned;

// <template lang='pug'>
// div
//     i-dynamic-paged-table(url="scanned-entries", :args="args", ref="entries")
//         template(slot="header")
//             th UHF Tag
//             th TimeStamp
//             th Start Time
//             th End Time
//             th Bags
//             th Fill Time
//             th Weight
//             th Actions
//         template(v-slo
//         t="{row, index}")
//             td {{ row.binUHFTag | last(6) }}
//             td {{ row.timeStamp | datetime }}
//             td {{ row.startTime | datetime }}
//             td {{ row.endTime | datetime }}
//             td {{ row.bags }}
//             td {{ row.fillTime}}
//             td {{ row.weight }}
//             td
//                 .btn-sm.btn-primary(@click="getBinData(row.id)") details
//     i-dialog(:show.sync="showBinData", heading="Bin Data", @close="showBinData = false")
//         p Bin Weight: {{ binData.bin.weight }} kg
//         p Total Picks: {{ binData.points.length }}
//         p Average Item Weight: {{ binData.bin.weight * 1000 / binData.points.length | round }} g
//
//         i-map.big(ref="map")
// </template>
//
// <script>
// import ajax from "../scripts/ajax";
//
// export default {
//     props:['scannerId'],
//     data () {
//         return {
//             args: {
//                 id: this.scannerId
//             },
//             binData: {
//                 bin: {
//
//                 },
//                 points: []
//             },
//             showBinData: false
//         }
//     },
//     methods: {
//         getBinData(id) {
//             ajax.post('/scanner/bindetails', {id})
//             .then(resp => {
//                 this.binData = resp
//                 this.showBinData = true
//                 this.$nextTick(() => {
//                     this.$refs.map.drawHeatmap(resp.points, true);
//                 })
//             })
//         }
//     }
// }
//
// </script>
// <style scoped>
// .big {
//     width: 800px;
//     height: 800px;
// }
// </style>
