import React, {
    Key,
    MutableRefObject,
    RefObject,
    useContext,
    useEffect,
    useImperativeHandle,
    useRef,
    useState
} from 'react'
import Pagination from './Pagination'
import {ExclamationIcon, SearchIcon} from '@heroicons/react/outline'
import {bind, classNames, onEnter, switcher} from '../wrapper'
import {arrayUpdatePartial} from "../immutableState";
import PaginationResponse from "../generated/interfaces/paginationResponse";
import PaginationRequestSearch from "../generated/interfaces/paginationRequestSearch";
import AppContext from "../pages/appContext";
import Success from "./Success";

export function useMountedPromise() {
    const mounted = useRef(false);

    function createPromise<T>(promise: Promise<T>): Promise<T> {
        return new Promise<T>((res, rej) => {
            promise.then(resp => {
                if (mounted.current)
                    res(resp);
            }).catch(err => {
                rej(err);
            })
        });
    }
    useEffect(() => {
        mounted.current = true;

        return () => {
            mounted.current = false;
        }
    }, []);

    return {
        createPromise,
        mounted
    }
}
export interface PagedTableFunctions<T> {
    refresh: () => void;
    updateData: (update: (prev: T[]) => T[]) => void;
    updateRow: (predicate: ((row: T) => boolean), setRow: (row: T) => void) => void;
    focus: () => void;
    getData: () => T[];
}

export interface TableColumn<TResponse> {
    header: React.ReactNode | (() => React.ReactNode);
    row: (item: TResponse, index: number) => React.ReactNode;
    key?: Key;

    // return colspan 0 if column should not be rendered.
    colspan?: (item: TResponse) => number;
    classNames?: (item: TResponse) => string;
}
interface PagedTableProps<TResponse, TRequest extends PaginationRequestSearch> {
    call: ((request: TRequest) => Promise<PaginationResponse<TResponse>>)
        | ((request: TRequest) => PaginationResponse<TResponse>)
    buildSearch?: (base: PaginationRequestSearch) => TRequest;
    topSlot?: React.ReactNode;
    summary?: (rows: TResponse[]) => React.ReactNode;

    componentRef?: MutableRefObject<PagedTableFunctions<TResponse> | undefined>;
    downloadExcelCall?: ((resp: PaginationRequestSearch) => void);

    keyExtractor?: (item: TResponse) => Key;
    columns: TableColumn<TResponse>[],
    rowClick?: (item: TResponse) => void;

    onChange?: (rows: TResponse[], paging: PagingData) => void;
}

export interface PagingData {
    page: number;
    rowsPerPage: number;
    search: string;
    all: boolean
}

enum TableStatus { loaded, loading, error}

const PagedSearchTable = <TResponse extends object, TRequest extends PaginationRequestSearch = PaginationRequestSearch>(
    {
        call,
        summary,
        keyExtractor,
        columns,
        rowClick,
        buildSearch,
        topSlot,
        componentRef,
        downloadExcelCall,
        onChange
    }: PagedTableProps<TResponse, TRequest>) => {
    const [data, setData] = useState<PaginationResponse<TResponse>>({
        items: [],
        total: 0
    })
    const context = useContext(AppContext)

    const [selectedRow, setSelectedRow] = useState<number | null>(null)

    const [paging, setPaging] = useState<PagingData>({
        page: 0,
        rowsPerPage: 20,
        search: '',
        all: false
    })

    const [search, setSearch] = useState('')
    const [tableState, setTableState] = useState<TableStatus>(TableStatus.loading)
    const [focusVal, setFocusVal] = useState(0)
    const {createPromise} = useMountedPromise();

    function pagingDate(page: number | null, rowsPerPage: number | null, search: string | null, all: boolean | null): PagingData {
        return {
            search: search ?? paging.search,
            rowsPerPage: rowsPerPage ?? paging.rowsPerPage,
            page: page ?? paging.page,
            all: all ?? paging.all
        } as PagingData
    }

    function updateData(resp: PaginationResponse<TResponse>, set: PagingData) {
        setData(resp);
        setTableState(TableStatus.loaded);
        onChange?.(resp.items, set);
    }

    function updatePage(set: PagingData) {
        setTableState(TableStatus.loading)
        setPaging(set)
        const request: PaginationRequestSearch = {
            sortBy: '',
            search: set.search,
            rowsPerPage: set.rowsPerPage,
            page: set.page,
            ascending: false,
            all: false
        }
        // provide for custom search request object that extends PaginationRequestSearch
        const result = call(buildSearch ? buildSearch(request) : request as TRequest)
        if (result instanceof Promise) {
            createPromise<PaginationResponse<TResponse>>(result).then(resp => {
                // resp is AxiosResponse<PaginationResponse> | PaginationResponse
                updateData(resp, set);
            }).catch(() => {
                setTableState(TableStatus.error)
            })
        } else {
            updateData(result, set);
        }
    }

    function doSearch() {
        updatePage(pagingDate(0, null, search, null))
    }

    useImperativeHandle(componentRef, () => ({
        refresh: doSearch,
        getData: () => data.items,
        updateData: (update: (prev: TResponse[]) => TResponse[]) => {
            const newItems = update(data.items)
            setData({...data, items: newItems})
        },
        updateRow: (predicate: ((row: TResponse) => boolean), setRow: (row: TResponse) => void) => {
            const row = data.items.find(predicate)
            if (row) {
                setRow(row)
                setData({...data, items: data.items})
            }
        },
        focus
    }))

    const input = useRef<HTMLInputElement>(null)
    const app = useContext(AppContext)

    // Rerun the useEffect to focus on input
    function focus() {
        setFocusVal(Math.random())
    }

    useEffect(() => {
        // fire and focus on focus function.
        input.current?.focus()
    }, [focusVal])

    useEffect(() => {
        // useEffect is fired after initial render and setRef
        updatePage(paging)
    }, [])

    function handleRowClick(row: TResponse, index: number) {
        rowClick?.(row)
        setSelectedRow(index)
    }

    function returnPaging() {
        const req: PaginationRequestSearch = {
            sortBy: '',
            search: search,
            rowsPerPage: paging.rowsPerPage,
            page: paging.page,
            ascending: false,
            all: paging.all
        }

        return buildSearch ? buildSearch(req) : req
    }

    return (
        <div className="w-full">

            <div className="rounded-md border my-1 bg-white relative">
                {topSlot}
                {
                    switcher(tableState, c => c
                        .case(s => s === TableStatus.loaded || s === TableStatus.loading, () =>
                            <>
                                {tableState === TableStatus.loading
                                    ? <div
                                        className="absolute inset-0 bg-overlay-200 flex justify-center items-center">
                                        <img src='/images/loader.gif' alt=''/>
                                    </div>
                                    : null}
                                <div className="flex w-full bg-white py-1 items-center">
                                    <div className="px-2 text-gray-400">
                                        <SearchIcon height={24}/>
                                    </div>
                                    <input ref={input} type="text" className="w-full outline-none"
                                           onKeyUp={onEnter(doSearch)} {...bind(search, setSearch)}/>
                                    <div className="btn bg-primary" onClick={doSearch}>search</div>
                                    {
                                        downloadExcelCall
                                            ? <div className='px-2 p-1 cursor-pointer hover:bg-gray-100 ' onClick={() => {
                                                downloadExcelCall(returnPaging())
                                                context.showSnack(<Success title={"Starting download"}/>)
                                            }}>
                                                <img className='w-[30px]' src='/images/csv.png' alt=""/>
                                            </div>
                                            : null
                                    }
                                </div>

                                <div className="w-full overflow-x-scroll">
                                    <table className="min-w-full divide-y divide-gray-300 w-full">
                                        <thead className="bg-gray-50">
                                        <tr>
                                            {columns.map((c, i) => (
                                                <th scope="col"
                                                    className="uppercase px-2 py-2 text-gray-500 text-left text-sm font-semibold "
                                                    key={i}>{typeof c.header === 'function' ? c.header() : c.header}</th>))}
                                        </tr>
                                        </thead>
                                        <tbody className="bg-white">

                                        {data.total == 0
                                            ?(
                                                <tr>
                                                   <td colSpan={columns.length}>
                                                        <div className='p-4'>
                                                            <div className='border w-full p-2 border-gray-200 border-dashed text-gray-500'>
                                                                No Results
                                                            </div>
                                                        </div>
                                                   </td>
                                                </tr>
                                            )
                                        : data.items.map((row, index) => (
                                                    <tr key={keyExtractor?.(row) ?? (paging.page * paging.rowsPerPage) + index}
                                                        className={classNames(selectedRow == index ? 'bg-[#314516]' : index % 2 === 0 ? 'hover:bg-gray-200' : 'bg-gray-50 hover:bg-gray-200', 'group cursor-pointer')}
                                                        onClick={() => handleRowClick(row, index)}>


                                                        {columns.map((c, i) => {
                                                                const colspan = c.colspan?.(row) ?? 1;
                                                                if (colspan == 0)
                                                                    return null;

                                                                return (
                                                                    <td colSpan={colspan}
                                                                        className={classNames('whitespace-nowrap px-1 py-2 text-sm', c.classNames?.(row), selectedRow == index ? 'text-white' : 'text-gray-500')}
                                                                        key={i}>{c.row(row, index)}</td>)
                                                            }
                                                        )}
                                                    </tr>
                                                ))
                                        }
                                        </tbody>
                                    </table>

                                </div>
                                <Pagination refresh={() => componentRef?.current?.refresh()}
                                            total={data.total}
                                            rowsPerPage={paging.rowsPerPage}
                                            page={paging.page}

                                            gotoPage={(page) => updatePage(pagingDate(page, null, null, null))}
                                            setRowsPerPage={rows => updatePage(pagingDate(0, rows, null, null))}/>
                                {summary?.(data.items)}
                            </>
                        )
                        .case(TableStatus.error, () => <div className="flex w-full m-4 justify-center items-center">
                            <div className="px-2"><ExclamationIcon className="w-10 h-10 text-red-400"/></div>
                            <div className="text-gray-600">Error loading table data</div>
                        </div>)
                    )
                }

            </div>
        </div>
    )
}

export default PagedSearchTable
