/* eslint-disable */
/**
 * This file was generated by Itecho.TSGen tool
 * Version and info generated in .version
 * Any changes to this file will be lost if regenerated 
 */

import { webRequest, fileRequest } from "../request";
import type ClientActiveRequest from "../interfaces/clientActiveRequest";
import type ClientDisplay from "../interfaces/clientDisplay";
import type ClientList from "../interfaces/clientList";
import type PaginationRequestSearch from "../interfaces/paginationRequestSearch";
import type PaginationResponse from "../interfaces/paginationResponse";

export default {
    paged: (request: PaginationRequestSearch) => webRequest<PaginationResponse<ClientDisplay>>('/client/paged', {
        method: 'post',
        body: request
    }),
    runner: () => webRequest<unknown>('/client/runner'),
    boundary: (request: ClientActiveRequest) => webRequest<unknown>('/client/boundary', {
        method: 'post',
        body: request
    }),
    all: () => webRequest<ClientList[]>('/client/all')
}