import React, {useContext, useState} from "react";
import UserController from "../../generated/controllers/userController";
import AppContext from "../appContext";

const Time: React.FC = () => {
    const context = useContext(AppContext);
    function updateTimeZone(event: React.ChangeEvent<HTMLSelectElement>) {
        UserController.updateTime({timeOffset: parseInt(event.target.value)}).then(() => {
            window.location.reload()
        });
    }

    return (
        <div>
            <div className="span text-center text-white">Time Zone</div>
            <select className="p-1 mb-4 border rounded" name="Time Zone" onChange={updateTimeZone}
                    value={context.initial.timeOffset}>
                <option value={-720}>GMT -12</option>
                <option value={-660}>GMT -11</option>
                <option value={-600}>GMT -10</option>
                <option value={-540}>GMT -9</option>
                <option value={-480}>GMT -8</option>
                <option value={-420}>GMT -7</option>
                <option value={-360}>GMT -6</option>
                <option value={-300}>GMT -5</option>
                <option value={-240}>GMT -4</option>
                <option value={-180}>GMT -3</option>
                <option value={-120}>GMT -2</option>
                <option value={-60}>GMT -1</option>
                <option value={0}>GMT</option>
                <option value={60}>GMT +1</option>
                <option value={120}>GMT +2</option>
                <option value={180}>GMT +3</option>
                <option value={240}>GMT +4</option>
                <option value={300}>GMT +5</option>
                <option value={360}>GMT +6</option>
                <option value={420}>GMT +7</option>
                <option value={480}>GMT +8</option>
                <option value={540}>GMT +9</option>
                <option value={600}>GMT +10</option>
                <option value={660}>GMT +11</option>
                <option value={720}>GMT +12</option>
            </select>
        </div>
    )
}

export default Time;
