import {useEffect} from "react";
import StationStatusResult from "./generated/interfaces/stationStatusResult";

export interface MessagingHub {
    StationStatus(status: StationStatusResult): void;
    ListenStation(userId: number, stationId: number): void;
}

const callbacks: Partial<MessagingHub>[] = [];

export default function useMessageHub(callback: Partial<MessagingHub>) {
    useEffect(() => {
        callbacks.push(callback);
        return () => {
            callbacks.splice(callbacks.indexOf(callback), 1);
        }
    }, [])
}
