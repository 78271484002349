/* eslint-disable */
/**
 * This file was generated by Itecho.TSGen tool
 * Version and info generated in .version
 * Any changes to this file will be lost if regenerated 
 */

import { webRequest, fileRequest } from "../request";
import type AddSeason from "../interfaces/addSeason";
import type IdRequest from "../interfaces/idRequest";
import type PaginationRequestIdSearch from "../interfaces/paginationRequestIdSearch";
import type PaginationResponse from "../interfaces/paginationResponse";
import type Season from "../interfaces/season";
import type SeasonDisplay from "../interfaces/seasonDisplay";

export default {
    paged: (request: PaginationRequestIdSearch) => webRequest<PaginationResponse<Season>>('/season/paged', {
        method: 'post',
        body: request
    }),
    farm: (request: IdRequest) => webRequest<SeasonDisplay[]>('/season/farm', {
        method: 'post',
        body: request
    }),
    upsert: (request: AddSeason) => webRequest<unknown>('/season/upsert', {
        method: 'post',
        body: request
    }),
    delete: (request: IdRequest) => webRequest<unknown>('/season/delete', {
        method: 'post',
        body: request
    })
}