import React, {useContext, useEffect} from "react";
import {PagedTableFunctions} from "../../components/PagedSearchTable";
import {dateFormat} from "../../date";
// import MicroScissorPicks from "../../generated/interfaces/microScissorPicks";
import ScissorController from "../../generated/controllers/scissorController";
import AppContext from "../appContext";
import {
    useNavigate,
    useSearchParams
} from "react-router-dom";
import Dialog from "../../components/Dialog";
import {EditRow, EditTable} from "../../components/Fields";
import Input from "../../components/Input";
import MicroScissorPicksTranspose from "../../generated/interfaces/microScissorPicksTranspose";
import MicroScissorPicksColumn from "../../generated/interfaces/microScissorPicksColumn";


interface DateSpan {
    date: Date;
    span: number;
}

function getDateSpans(dates: MicroScissorPicksColumn[]): DateSpan[] {
    const spans: DateSpan[] = [];
    let prev: Date | null = null;
    let span = 0;
    for (const date of dates) {
        if (prev == null) {
            prev = date.day;
            span++;
        }
        if (prev.getMonth() != date.day.getMonth()) {
            spans.push({
                span,
                date: prev ?? date.day
            })
            prev = date.day;
            span = 0;
        }

        span++;
    }

    spans.push({
        span,
        date: prev!
    })

    return spans;
}

const MicroStations: React.FC = () => {
    const context = useContext(AppContext);
    const [showMinimum, setShowMinimum] = React.useState<boolean>(false);
    const [minimum, setMinimum] = React.useState<number>(1000);
    const [tempMinimum, setTempMinimum] = React.useState<number>(1000);
    const navigate = useNavigate();
    const [params] = useSearchParams();

    // console.log('Raw params', params.get('farmId'));
    // console.log('Raw params', params.get('from'));
    // console.log('Raw params', params.get('to'));

    function backButton () {
        navigate({
            pathname: '/dashboard-microManagement',
        });
    }

    function setNewMinimum(minimum: number) {
        setMinimum(minimum)
        setShowMinimum(false)
    }

    const [microPicks, setMicroPicks] = React.useState<MicroScissorPicksTranspose>({
        columns: [],
        rows: [],
    })

    useEffect(() => {
        const hideLoader = context.showLoader()
        ScissorController.microPicks({
            from: new Date(params.get('from') ?? new Date()),
            to: new Date(params.get('to') ?? new Date()),
            farmId: parseInt(params.get('farmId')?? "0")
        }).then((res) => {
            setMicroPicks(res);
        }).finally(() =>  hideLoader() );

    }, [minimum])

    return (
        <div>
            <div className = 'btn btn-primary mx-2 mb-4 mt-1' onClick={() => backButton()}> Back</div>
            <div className = 'btn btn-primary mb-4 mt-1' onClick={() => {
                setShowMinimum(true);
                setTempMinimum(minimum);
            }}> Minimum Picks</div>
            {microPicks.rows.length > 0
            ?<table className="min-w-full divide-y divide-gray-300 w-full">
                <thead className="uppercase px-2 py-2 text-gray-500 text-left text-sm font-semibold ">
                    <tr>
                        <th colSpan={2}></th>
                        {getDateSpans(microPicks.columns).map((date, span ) => <th key={date.date.toString()} colSpan={date.span}>{dateFormat(date.date)}</th>)}
                    </tr>
                    <tr>

                        <th className="text-left">Farm Name</th>
                        <th className="text-left">Scissor Name</th>
                        {microPicks.columns.map((column, index) => <th className='p-4' key={column.day.toString()}>{column.day.getDate()}</th>)}
                    </tr>

                </thead>
                <tbody className='whitespace-nowrap px-1 py-2 text-sm text-gray-500'>
                {microPicks.rows.map((row, index1) =>
                    <tr key={index1}
                        className={( index1 % 2 === 0 ? 'hover:bg-gray-200' : 'bg-gray-50 hover:bg-gray-200')}>
                        <td>{row.farmName}</td>
                        <td className="pl-2">{row.scissorName}</td>
                            {row.counts.map((count, index) =>
                                count.count != 0
                                ? <td key={row.scissorName + index} className="text-center">

                                        <div className="text-base text-center wider font-bold">{count.count < minimum ?<div className='text-red-500'> {count.count}</div>: count.count}</div>

                                        <div className="text-center mx-1">
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                                 strokeWidth="1.5" stroke="currentColor" className="w-3 h-3 inline">
                                                <path strokeLinecap="round" strokeLinejoin="round"
                                                      d="M21 10.5h.375c.621 0 1.125.504 1.125 1.125v2.25c0 .621-.504 1.125-1.125 1.125H21M4.5 10.5h6.75V15H4.5v-4.5zM3.75 18h15A2.25 2.25 0 0021 15.75v-6a2.25 2.25 0 00-2.25-2.25h-15A2.25 2.25 0 001.5 9.75v6A2.25 2.25 0 003.75 18z"/>
                                            </svg>
                                            <span className="text-2xs m-1 text-gray-600 align-middle">{count.belowAverageMin?  <span className="text-red-600">{count.minBattery}</span>:count.minBattery}</span>
                                        </div>

                                        <div className="text-center mx-1">
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                                 strokeWidth="1.5" stroke="currentColor" className="w-3 h-3 inline">
                                                <path strokeLinecap="round" strokeLinejoin="round"
                                                      d="M21 10.5h.375c.621 0 1.125.504 1.125 1.125v2.25c0 .621-.504 1.125-1.125 1.125H21M4.5 10.5H18V15H4.5v-4.5zM3.75 18h15A2.25 2.25 0 0021 15.75v-6a2.25 2.25 0 00-2.25-2.25h-15A2.25 2.25 0 001.5 9.75v6A2.25 2.25 0 003.75 18z"/>
                                            </svg>
                                            <span className="text-2xs m-1 text-gray-600 align-middle">{count.belowAverageMax?  <span className="text-red-600">{count.maxBattery}</span>:count.maxBattery}</span>
                                        </div>

                                  </td>
                                :<td key={row.scissorName + index}>-</td>)}
                    </tr>)}
                </tbody>

            </table>
            : null}



            <Dialog title="Minimum Picks per Day" setShow={setShowMinimum} show={showMinimum} body={
                <EditTable
                    discard={() => { setShowMinimum(false)}}
                    save={() => setNewMinimum(tempMinimum)}
                    saveWord={'set'}>

                    <div className="flex w-full bg-white py-1 items-center">
                        {EditRow ('Minimum Picks: ',<Input value={tempMinimum? tempMinimum.toString(): ""} change={v => setTempMinimum(parseInt(v))}/>)}
                    </div>
                </EditTable>}
            />
        </div>

    )

}

export default MicroStations;
