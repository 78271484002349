import {type RequestOptions} from './requestOptions';
import PaginationRequestSearch from "./interfaces/paginationRequestSearch";

// override PaginationRequestSearch with the new type on sortBy
// to ensure type safety 
export type PaginationRequestSearchEx<T> = PaginationRequestSearch & { sortBy: keyof T | null };
function queryEncode(key: string, value: any) {
    if (value instanceof Date) {
        // We don't need time. We need dates. Ignore time for now as we only have a datepicker.
        value = value.getFullYear() + "-" + (value.getMonth() + 1) + "-" + value.getDate() + " 00:00:00";
    }
    return encodeURIComponent(key) + '=' + encodeURIComponent(value);
}

function keyValue(key: string, value: unknown): string {
    if (Array.isArray(value)) {
        return value.map(v => queryEncode(key, v)).join("&")
    }
    return queryEncode(key, value);
}

// Date format in ISO 8601 format
const reISO = /^\d{4}-\d\d-\d\dT\d\d:\d\d:\d\d(\.\d+)?(([+-]\d\d:\d\d)|Z)?$/i

function parseDate(key: any, value: any): any {
    if (typeof value === 'string') {
        let a = reISO.exec(value);
        if (a)
            return new Date(value);
    }

    return value;
}

function transformDates(data: any): any {
    if (data == '' || data === null || data === undefined)
        return data

    try {
        // data may be invalid or not even be JSON
        return JSON.parse(data, parseDate);
    } catch {
        return data
    }
}

function transformRequest(data: any): any {
    return JSON.stringify(data, (key, value) => {

        // JSON.stringify already converted date to ISO 8601 format
        // check if this string conforms to ISO 8601 format and parse it
        // then adjust the timezone to UTC for the server to make sense of it
        if (typeof value === 'string' && reISO.exec(value)) {
            const d = new Date(value);
            d.setMinutes(d.getMinutes() - d.getTimezoneOffset());
            return d.toISOString();
        }

        // Infinity and NaN serialize as null
        // Now serialize as 0
        if (typeof value === 'number' && (isNaN(value) || !isFinite(value)))
            return 0;

        return value;
    });
}

function toFormData(obj: any): FormData {
    const formData = new FormData()
    for (const k in obj) {
        const v = obj[k];
        // files
        if (v instanceof File) {
            formData.append(k, v)
            continue;
        }
        // objects need to be serialized
        if (typeof v === 'object') {
            formData.append(k, JSON.stringify(v))
            continue;
        }
        // Primitive types
        if (v !== undefined)
            formData.append(k, v)
    }
    return formData;
}

function buildPath(...parts: string[]): string {
    // remove leading and trailing slashes
    const path = parts
        .map(part => part.replace(/^\/+|\/+$/g, ''))
        .join('/');

    // but keep the original leading slash if any
    return parts[0]?.startsWith('/') ? '/' + path : path;
}

function buildUrl(url: string, queryParams?: RequestOptions["queryParams"]): string {
    if (!queryParams)
        return url;

    return url + "?" + Object.keys(queryParams).map((key) => keyValue(key, queryParams[key])).join('&');
}

const methodText: Record<NonNullable<RequestOptions["method"]>, string> = {
    delete: "DELETE",
    get: "GET",
    patch: "PATCH",
    post: "POST",
    put: "PUT"
};

function makeRequest<T>(url: string, options: RequestOptions | undefined, body: BodyInit | null, contentType: string) {
    return fetch(url, {
        method: methodText[options?.method ?? 'get'],
        body,
        headers: {
            "Content-Type": contentType,
            ...options?.headers
        },

    }).then(r => {
        if (r.status >= 400 && r.status < 600) {
            // reject the promise
            throw new Error(r.statusText);
        }
        return r.text();
    }).then(t => transformDates(t) as T);
}

export function webRequest<T>(url: string, options?: RequestOptions): Promise<T> {
    // build the url with query params if any
    const queryUrl = buildUrl(buildPath("/api",  url), options?.queryParams);
    if (options?.binding == 'form') {
        return makeRequest<T>(queryUrl, options, toFormData(options.body), 'application/x-www-form-urlencoded');
    }
    return makeRequest<T>(queryUrl, options, transformRequest(options?.body), 'application/json');
}

export function fileRequest(url: string, options?: RequestOptions): void {
    window.location.href = buildUrl(buildPath("/api",  url), options?.queryParams);
}
